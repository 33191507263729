import { Button, Flex, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import Table from "../../../components/Table/Table.tsx";
import { SortDirection } from "../../../components/Table/types.ts";
import {
  CollectionDocumentOrderBy,
  CollectionDocumentType,
  OrderBy,
  RolePermission,
  UpdateCollectionDocumentInput,
} from "../../../graphql/generated";
import { useCollectionDocuments } from "../../../hooks/api/collectionDocument/useCollectionDocuments.ts";
import { useCreateCollectionDocument } from "../../../hooks/api/collectionDocument/useCreateCollectionDocument.ts";
import { useUpdateCollectionDocument } from "../../../hooks/api/collectionDocument/useUpdateCollectionDocument.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import { CollectionDocument } from "../../../types/collectionDocument.ts";
import { getApiOrderBy } from "../../../utils/api.ts";
import { getPagesCount } from "../../../utils/pagination";
import { toHumanReadable } from "../../../utils/string.ts";
import { hasPermission } from "../../../utils/user.ts";
import { useStyles } from "./CollectionDocumentsTab.styles.ts";
import { generateDocButton } from "./constants.ts";
import EditCollectionDocumentModal from "./EditCollectionDocumentModal/EditCollectionDocumentModal.tsx";
import { useCollectionDocumentData } from "./hooks/useCollectionDocumentData.tsx";

const CollectionDocumentsTab = () => {
  const { classes } = useStyles();

  const { customerId } = useParams() as { customerId: string };

  const [selectedCollectionDocument, setSelectedCollectionDocument] =
    useState<CollectionDocument | null>();

  const [orderBy, setOrderBy] = useState<CollectionDocumentOrderBy>({
    createdAt: OrderBy.Asc,
  });

  const [page, setPage] = useState<number>(1);

  const [
    { data: collectionDocumentsData, fetching },
    refreshCollectionDocument,
  ] = useCollectionDocuments({
    filter: {
      customerId: {
        equals: Number(customerId),
      },
    },
    orderBy,
    page,
  });

  const { user } = useCurrentUserContext();

  const canAdd = hasPermission(user, RolePermission.CanAddCollectionDocument);

  const collectionDocuments =
    collectionDocumentsData?.collectionDocuments?.data;

  const pageCount = getPagesCount(
    collectionDocumentsData?.collectionDocuments?.total
  );

  const [, createCollectionDocument] = useMutationNotificationWrapper(
    useCreateCollectionDocument(),
    {
      success: {
        message: "A document has been generated successfully.",
      },
    }
  );

  const [, updateCollectionDocument] = useMutationNotificationWrapper(
    useUpdateCollectionDocument(),
    {
      success: {
        message: "A document information has been updated successfully.",
      },
    }
  );

  const [
    editCollectionDocumentModalOpened,
    {
      open: editCollectionDocumentModalOpen,
      close: editCollectionDocumentModalClose,
    },
  ] = useDisclosure(false);

  const handleOpenEditCollectionDocumentModal = (id: number) => {
    if (!collectionDocuments) {
      return;
    }
    const collection = collectionDocuments.find(
      (item: CollectionDocument) => item.id === id
    );
    setSelectedCollectionDocument(collection);
    editCollectionDocumentModalOpen();
  };

  const handleCloseEditCollectionModal = () => {
    setSelectedCollectionDocument(null);
    editCollectionDocumentModalClose();
  };

  const handleCreateCollectionDocument = (
    documentType: CollectionDocumentType
  ) => {
    const input = {
      customerId: Number(customerId),
      documentType,
    };
    createCollectionDocument({ input }).then((result) => {
      if (!result.error) {
        refreshCollectionDocument();
      }
    });
  };

  const [columns, rows] = useCollectionDocumentData({
    data: collectionDocuments,
    onEdit: handleOpenEditCollectionDocumentModal,
  });

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      if (sort.columnAccessor === "collectorAssigned") {
        const direction = {
          [SortDirection.asc]: OrderBy.Asc,
          [SortDirection.desc]: OrderBy.Desc,
        };

        setOrderBy({ collectorAssigned: { name: direction[sort.direction] } });
        return;
      }
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleUpdateCollectionDocument = (
    value: UpdateCollectionDocumentInput
  ) => {
    selectedCollectionDocument &&
      updateCollectionDocument({
        id: selectedCollectionDocument?.id,
        input: value,
      }).then((result) => {
        if (!result.error) {
          refreshCollectionDocument();
        }
      });
    handleCloseEditCollectionModal();
  };

  return (
    <>
      <Flex direction="column" gap={{ base: 20, sm: 40 }}>
        {canAdd && (
          <SimpleGrid
            cols={4}
            breakpoints={[
              { maxWidth: "96rem", cols: 2 },
              { maxWidth: "54rem", cols: 1 },
            ]}
            spacing={16}
            w="100%"
          >
            {generateDocButton.map((item, index) => (
              <Button
                key={index}
                uppercase
                variant="filledGolden"
                className={classes.button}
                onClick={() => handleCreateCollectionDocument(item)}
              >
                {`Generate ${toHumanReadable(item)}`}
              </Button>
            ))}
          </SimpleGrid>
        )}

        <Table
          columns={columns}
          rows={rows}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.asc,
          }}
          loading={fetching}
          onSortChange={handleOnSortChange}
        />
      </Flex>
      {selectedCollectionDocument && (
        <EditCollectionDocumentModal
          value={selectedCollectionDocument}
          opened={editCollectionDocumentModalOpened}
          onClose={handleCloseEditCollectionModal}
          onSubmit={handleUpdateCollectionDocument}
        />
      )}
    </>
  );
};

export default CollectionDocumentsTab;
