import { Button, Flex, Modal, ScrollArea, Textarea } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { ReactNode, useCallback } from "react";

interface Props {
  title?: string | undefined;
  message?: string | ReactNode;
  buttonTitle?: string;
  input?: {
    label?: string;
    placeholder?: string;
    validationError?: string;
  };
  opened: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

const ModalWithNotes = ({
  title,
  message,
  buttonTitle = "handover",
  input,
  opened,
  onClose,
  onSubmit,
}: Props) => {
  const form = useForm({
    initialValues: {
      note: undefined,
    },
    validate: {
      note: isNotEmpty(input?.validationError ?? "Note is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.values.note) {
      return;
    }
    onSubmit(form.values.note);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <Modal
      opened={opened}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      title={title}
      scrollAreaComponent={ScrollArea.Autosize}
      size="lg"
      onClose={handleClose}
    >
      <Flex direction="column" gap={20}>
        {message}
        <Textarea
          label={input?.label ?? "Notes"}
          placeholder={input?.placeholder ?? "Enter Notes"}
          autosize
          size="m"
          minRows={3}
          maxRows={3}
          {...form.getInputProps("note")}
        />
        <Flex gap={20} justify="flex-end">
          <Button variant="outlineBlue" uppercase onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="filledBlue"
            disabled={!form.isValid()}
            uppercase
            onClick={handleSubmit}
          >
            {buttonTitle}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalWithNotes;
