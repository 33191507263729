import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikNegativeEvent } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";

interface Props {
  data: ClikNegativeEvent[] | null | undefined;
}

export const useCRNegativeEventCardInfo = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  if (!data) {
    return null;
  }

  return data?.map((event, index) => ({
    label: `Negative Event ${index + 1}`,
    variant: "bronze",
    items: [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikNegativeEvent",
              fieldName: "eventCode",
            }}
            position="right"
          >
            Event Code
          </DatabaseMetadataPopover>
        ),
        content: event?.eventCode,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikNegativeEvent",
              fieldName: "eventDetail",
            }}
            position="right"
          >
            Event Detail
          </DatabaseMetadataPopover>
        ),
        content: event?.eventDetail,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikNegativeEvent",
              fieldName: "eventDate",
            }}
            position="right"
          >
            Event Date
          </DatabaseMetadataPopover>
        ),
        content: event?.eventDate && toLocalDate(event.eventDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikNegativeEvent",
              fieldName: "eventExpiryDate",
            }}
            position="right"
          >
            Event Expiry Date
          </DatabaseMetadataPopover>
        ),
        content: event?.eventExpiryDate && toLocalDate(event.eventExpiryDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikNegativeEvent",
              fieldName: "providerType",
            }}
            position="right"
          >
            Provider Type
          </DatabaseMetadataPopover>
        ),
        content: event?.providerType,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikNegativeEvent",
              fieldName: "provider",
            }}
            position="right"
          >
            Provider
          </DatabaseMetadataPopover>
        ),
        content: event?.provider,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikNegativeEvent",
              fieldName: "referenceDate",
            }}
            position="right"
          >
            Reference Date
          </DatabaseMetadataPopover>
        ),
        content: event?.referenceDate && toLocalDate(event.referenceDate),
      },
    ],
  }));
};
