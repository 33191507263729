import { ActionIcon, Popover } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import SvgVisibility from "../../../components/Icons/Visibility.tsx";
import Table from "../../../components/Table/Table.tsx";
import { TableRow } from "../../../components/Table/types.ts";
import { CustomerChangeLog } from "../../../types/customer.ts";
import { toLocalDateTime } from "../../../utils/date.ts";
import { toHumanReadable } from "../../../utils/string.ts";
import { getUserDisplayName } from "../../../utils/user.ts";

interface Props {
  data: CustomerChangeLog[] | undefined;
  loading: boolean;
}

export const CustomerStatusLog = ({ data, loading }: Props) => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "createdAt",
      title: "Date",
      width: 150,
    },
    {
      accessor: "action",
      title: "Status Change",
      width: 150,
    },
    {
      accessor: "reason",
      title: "Reason",
      width: 300,
    },
    {
      accessor: "user",
      title: "User",
      width: 200,
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => ({
      id: item.id,
      createdAt: toLocalDateTime(item.createdAt),
      action: toHumanReadable(item.action),
      reason: item.reason,
      user: getUserDisplayName(item.user),
    }));
  }, [data]);

  return (
    <Popover
      position="bottom"
      withArrow
      width="auto"
      arrowPosition="side"
      shadow="xs"
      styles={{ dropdown: { maxHeight: "75%", overflow: "scroll" } }}
    >
      <Popover.Target>
        <ActionIcon size="m" variant="outlineGolden">
          <SvgVisibility />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Table
          minWidth="350px"
          columns={columns}
          rows={rows}
          loading={loading}
          verticalAlignment="top"
        />
      </Popover.Dropdown>
    </Popover>
  );
};
