import { SelectItem } from "@mantine/core/lib/Select/types";

import {
  DpdBucket,
  DpdBucketAccumulative,
  GetInvoicesQuery,
  InvoicePayment as GqlGeneratedInvoicePayment,
  PaymentMethod,
  PaymentStatus,
  PaymentType,
} from "../../graphql/generated.ts";
import { toHumanReadable } from "../../utils/string.ts";

export type Invoice = GetInvoicesQuery["invoices"]["data"][0];

export type InvoicePayment = GqlGeneratedInvoicePayment;

export enum IsRestructured {
  yes = "YES",
  no = "NO",
}

export const dpdBucketOptions: SelectItem[] = [
  { value: DpdBucket.Current, label: "Current" },
  { value: DpdBucket.OneSeven, label: "1-7" },
  { value: DpdBucket.EightFifteen, label: "8-15" },
  { value: DpdBucket.SixteenThirty, label: "16-30" },
  { value: DpdBucket.ThirtyoneSixty, label: "31-60" },
  { value: DpdBucket.SixtyoneNinety, label: "61-90" },
  { value: DpdBucket.NinetyPlus, label: "90+" },
];

export const dpdBucketAccumulativeOptions: SelectItem[] = [
  { value: DpdBucketAccumulative.SevenPlus, label: "DPD 7+" },
  { value: DpdBucketAccumulative.FifteenPlus, label: "DPD 15+" },
  { value: DpdBucketAccumulative.ThirtyPlus, label: "DPD 30+" },
  { value: DpdBucketAccumulative.SixtyPlus, label: "DPD 60+" },
  { value: DpdBucketAccumulative.NinetyPlus, label: "DPD 90+" },
];

export const toHumanReadablePaymentType = (
  paymentType: PaymentType | null | undefined
): string | null => {
  if (!paymentType) {
    return null;
  }
  if (paymentType === PaymentType.CodException) {
    return "COD Exception";
  }
  return toHumanReadable(paymentType, true);
};

export const paymentTypeOptions: SelectItem[] = Object.values(PaymentType).map(
  (value) => ({
    value,
    label: toHumanReadablePaymentType(value) as string,
  })
);

export const paymentMethodOptions: SelectItem[] = Object.values(
  PaymentMethod
).map((value) => ({
  value,
  label: toHumanReadable(value, true),
}));

export const paymentStatusOptions: SelectItem[] = Object.values(
  PaymentStatus
).map((value) => ({
  value,
  label: toHumanReadable(value),
}));

export const isRestructuredOptions: SelectItem[] = Object.values(
  IsRestructured
).map((value) => ({
  value,
  label: toHumanReadable(value),
}));
