import { pickBy } from "lodash";
import isEqual from "lodash/isEqual";

import { InvoiceFilter } from "../../graphql/generated";
import { IsRestructured } from "../../types/invoice/invoice.ts";
import { getDateTimeRangeFilter } from "../../utils/filter.ts";
import { FiltersValue } from "./ActivityManagementToolbar/types.ts";

const getInvoiceRestructuredApiFilter = (value: Array<string> | undefined) => {
  if (!value?.length) {
    return null;
  }
  if (isEqual(value, [IsRestructured.yes])) {
    return { equals: true };
  }
  if (isEqual(value, [IsRestructured.no])) {
    return { equals: false };
  }
  return null;
};

export const getInvoiceApiFilter = (value: FiltersValue): InvoiceFilter => {
  return pickBy({
    dpdBucket: value.dpdBucket?.length ? { in: value.dpdBucket } : null,
    customerId: value.companyName?.length
      ? { in: value.companyName?.map((c) => Number(c)) }
      : null,
    paymentStatus: value.paymentStatus?.length
      ? { in: value.paymentStatus }
      : null,
    paymentType: value.paymentType?.length ? { in: value.paymentType } : null,
    paymentMethod: value.paymentMethod?.length
      ? { in: value.paymentMethod }
      : null,
    invoiceDueDate: getDateTimeRangeFilter(value.invoiceDueDate),
    isRestructured: getInvoiceRestructuredApiFilter(value.isRestructured),
    collectorAssignedId: value.collector?.length
      ? { in: value.collector?.map((c) => Number(c)) }
      : null,
  });
};
