import { Flex, Select, SimpleGrid } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";

import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle";
import RestructureDetails from "../../../components/TableRestructure/RestructureDetails/RestructureDetails";
import TableRestructure from "../../../components/TableRestructure/TableRestructure";
import { getRestructureApiFilter } from "../../../components/TableRestructure/utils";
import { RolePermission } from "../../../graphql/generated";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import { Restructure } from "../../../types/restructure";
import { hasPermission } from "../../../utils/user.ts";
import { approvalStatusOptions } from "./constants";
import { useStyles } from "./RestructureReview.styles";
import { RestructureFilters } from "./types";
import { restructureFilter } from "./utils";

export default function RestructureReview() {
  const { classes } = useStyles();

  const { user } = useCurrentUserContext();

  const canAddRestructureDetails = hasPermission(
    user,
    RolePermission.CanAddRestructureDetails
  );

  const canEditRestructureDetails = hasPermission(
    user,
    RolePermission.CanEditRestructureDetails
  );

  const canEditRestructure = hasPermission(
    user,
    RolePermission.CanEditReviewApproval
  );

  const form = useForm<RestructureFilters>();

  const [selectedRow, setSelectedRow] = useState<Restructure | null>();

  const onFilter = restructureFilter(form);

  if (selectedRow) {
    return (
      <RestructureDetails
        restructure={selectedRow}
        canAdd={canAddRestructureDetails}
        canEdit={canEditRestructureDetails}
        onBreadcrumbsClick={() => setSelectedRow(null)}
      />
    );
  }

  return (
    <Flex direction="column" gap={{ base: 16, sm: 24 }}>
      <SectionTitle>Restructure Requests</SectionTitle>
      <SimpleGrid
        cols={3}
        spacing={20}
        verticalSpacing={8}
        className={classes.grid}
      >
        <Select
          size="m"
          searchable
          clearable
          label="Approval Status"
          placeholder="Select Approval Status"
          nothingFound="No results found"
          data={approvalStatusOptions}
          {...form.getInputProps("approvalStatus")}
          onChange={onFilter("approvalStatus")}
        />
      </SimpleGrid>

      <TableRestructure
        filter={getRestructureApiFilter(form.values)}
        canEdit={canEditRestructure}
        onSelectRow={setSelectedRow}
      />
    </Flex>
  );
}
