import { Badge, Button, Flex, Select, Text, Tooltip } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import ActionIcon from "../../../../components/ActionIcon/ActionIcon.tsx";
import { Variant } from "../../../../components/ActionIcon/types.ts";
import CPExceptionTableCard from "../../../../components/CPExceptionTableCard/CPExceptionTableCard.tsx";
import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgEdit from "../../../../components/Icons/Edit.tsx";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip.tsx";
import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { boolSelectorOptions } from "../../../../constants.ts";
import {
  CollateralStatus,
  PaymentMethod,
  PaymentStatus,
  PaymentType,
  TransactionHistoryOrderStatus,
  TransactionHistoryStatus,
  UpdateTransactionHistoryInput,
} from "../../../../graphql/generated.ts";
import {
  orderStatusToHumanReadable,
  TransactionHistory,
} from "../../../../types/transactionHistory/transactionHistory.ts";
import { booleanToString, stringToBoolean } from "../../../../utils/boolean.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { ModalType } from "../types.ts";

interface Props {
  data: TransactionHistory[] | null | undefined;
  onApprove: (id: number) => void;
  onReject: (id: number) => void;
  onOpenEditModal: (type: ModalType, value: TransactionHistory) => void;
  onInlineEdit: (id: number, input: UpdateTransactionHistoryInput) => void;
  onChangeCollateral: (value: TransactionHistory) => void;
}

export const useTransactionHistoryTableData = ({
  data,
  onApprove,
  onReject,
  onOpenEditModal,
  onInlineEdit,
  onChangeCollateral,
}: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "orderDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "orderDate",
          }}
        >
          Order Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "quotationNo",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "quotationNo",
          }}
        >
          Quotation No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "saleOrderNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "saleOrderNumber",
          }}
        >
          SO No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "invoiceNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceNumber",
          }}
        >
          Invoice No
        </DatabaseMetadataPopover>
      ),
      width: 300,
    },
    {
      accessor: "salesName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "salesName",
          }}
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "salesTeam",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "salesTeam",
          }}
        >
          Sales Team
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "limitUsed",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "limitName",
          }}
        >
          Limit Used
        </DatabaseMetadataPopover>
      ),
      width: 300,
    },
    {
      accessor: "paymentType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "paymentType",
          }}
        >
          Payment Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "paymentMethod",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "paymentMethod",
          }}
        >
          Payment Method
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "orderStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "orderStatus",
          }}
        >
          Order Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "amountTotal",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "totalOrderAmount",
          }}
        >
          Total Order Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "totalInvoiced",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "totalInvoice",
          }}
        >
          Total Invoice
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "outstandingPrincipal",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "totalAmountDue",
          }}
        >
          OSP
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "lateCharge",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "lateCharge",
          }}
        >
          Late Charge
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "totalAr",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "totalAccountReceivable",
          }}
        >
          Total AR
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "totalPaid",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "totalPaid",
          }}
        >
          Total Paid Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "dpd",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "dpd",
          }}
        >
          DPD
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "paymentStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentStatus",
          }}
        >
          Payment Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "invoiceDueDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceDueDate",
          }}
        >
          Invoice Due Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "hasException",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "hasException",
          }}
        >
          <Flex align="center">
            <Text>Has Exceptions</Text>
            <InfoTooltip tooltipText="Only COD payment type can be changed." />
          </Flex>
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "isRestructured",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "isRestructured",
          }}
        >
          Is Restructured
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "conditionPrecedents",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "conditionPrecedents",
          }}
        >
          <Flex align="center">
            <Text>Condition Precedents</Text>
            <InfoTooltip tooltipText="You can add an condition precedent only if a limit exists in the transaction history record." />
          </Flex>
        </DatabaseMetadataPopover>
      ),
      sortable: false,
      width: 300,
    },
    {
      accessor: "exceptions",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "exceptions",
          }}
        >
          <Flex align="center">
            <Text>Exceptions</Text>
            <InfoTooltip tooltipText="You can add an exception only if a limit exists in the transaction history record." />
          </Flex>
        </DatabaseMetadataPopover>
      ),
      sortable: false,
      width: 300,
    },
    {
      accessor: "approval",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "TransactionHistory",
            fieldName: "status",
          }}
        >
          <Flex align="center">
            <Text>Approval</Text>
            <InfoTooltip tooltipText="Only TOP payment type can be approved or rejected" />
          </Flex>
        </DatabaseMetadataPopover>
      ),
      width: 400,
    },
  ];

  const handleOpenEditModal = useCallback(
    (type: ModalType, value: TransactionHistory) => {
      onOpenEditModal(type, value);
    },
    [onOpenEditModal]
  );

  const handleInlineEdit = useCallback(
    (id: number, input: UpdateTransactionHistoryInput) => {
      onInlineEdit(id, input);
    },
    [onInlineEdit]
  );

  const getExceptionColumn = useCallback(
    (item: TransactionHistory) => {
      if (item.paymentType === PaymentType.Cod) {
        return (
          <Select
            data={boolSelectorOptions}
            value={item?.hasException?.toString()}
            withinPortal
            zIndex={100}
            size="s"
            onChange={(value) =>
              handleInlineEdit(item.id, {
                hasException: stringToBoolean(value, true),
              })
            }
          />
        );
      }
      return item.hasException ? "True" : "False";
    },
    [handleInlineEdit]
  );

  const getApprovalDisabledTooltipText = useCallback(
    (item: TransactionHistory) => {
      if (item.paymentType !== PaymentType.Top) {
        return "Only TOP payment type can be approved or rejected.";
      }
      if (item.orderStatus !== TransactionHistoryOrderStatus.PoSubmission) {
        return "Status is not PO Submission.";
      }
      if (!item.collateral) {
        return "Needs confirmation on Collateral Status.";
      }
      if (item.collateral?.status === CollateralStatus.Reject) {
        return "Collateral is Rejected.";
      }
      return "";
    },
    []
  );

  const renderApproval = useCallback(
    (item: TransactionHistory) => {
      if (item.status === TransactionHistoryStatus.Approved) {
        return (
          <Badge variant="active" w="80%" size="sm">
            {item.approvedBy
              ? `Approved By: ${item.approvedBy?.name}`
              : "Approved"}
          </Badge>
        );
      }
      if (item.status === TransactionHistoryStatus.Rejected) {
        return (
          <Badge variant="updated" w="80%" size="sm">
            Rejected By: {item.rejectedBy?.name}
          </Badge>
        );
      }
      const tooltipText = getApprovalDisabledTooltipText(item);
      const disabled = !!tooltipText;
      return tooltipText ? (
        <Tooltip label={tooltipText} withinPortal multiline>
          <Flex gap={10}>
            <Button
              variant="filledBlue"
              disabled={disabled}
              uppercase
              onClick={() => onApprove(item.id)}
            >
              Approve
            </Button>
            <Button
              variant="outlineBlue"
              disabled={disabled}
              uppercase
              onClick={() => onReject(item.id)}
            >
              Reject
            </Button>
          </Flex>
        </Tooltip>
      ) : (
        <Flex gap={10}>
          <Button
            variant="filledBlue"
            disabled={disabled}
            uppercase
            onClick={() => onApprove(item.id)}
          >
            Approve
          </Button>
          <Button
            variant="outlineBlue"
            disabled={disabled}
            uppercase
            onClick={() => onReject(item.id)}
          >
            Reject
          </Button>
        </Flex>
      );
    },
    [getApprovalDisabledTooltipText, onApprove, onReject]
  );

  const renderPaymentStatus = useCallback((item: TransactionHistory) => {
    const paymentStatus = item.invoice?.paymentStatus;
    if (!paymentStatus) {
      return null;
    }
    let backgroundColor = "red";
    if ([PaymentStatus.InPayment, PaymentStatus.Paid].includes(paymentStatus)) {
      backgroundColor = "green";
    }
    if (paymentStatus === PaymentStatus.Partial) {
      backgroundColor = "blue";
    }
    return (
      <Badge color={backgroundColor} size="sm" radius="xs">
        {toHumanReadable(paymentStatus)}
      </Badge>
    );
  }, []);

  const renderTransactionHistoryUsed = useCallback(
    (item: TransactionHistory) => {
      if (!item.limit?.limitName) {
        return null;
      }
      return item.limit.limitName.replace("Top Up", "General");
    },
    []
  );

  const renderPaymentMethod = useCallback(
    (item: TransactionHistory) => {
      if (item.paymentMethod === PaymentMethod.Transfer) {
        return toHumanReadable(item.paymentMethod, true);
      }
      let iconVariant: Variant = "primary";
      if (!item.collateral) {
        iconVariant = "disabled";
      }
      if (item.collateral?.status === CollateralStatus.Confirm) {
        iconVariant = "success";
      }
      if (item.collateral?.status === CollateralStatus.Reject) {
        iconVariant = "error";
      }
      return (
        <Flex align="start" justify="space-between" mih="100%">
          <Text>{toHumanReadable(item.paymentMethod, true)}</Text>
          <ActionIcon
            variant={iconVariant}
            disabled={!item.collateral}
            onClick={() => onChangeCollateral(item)}
          >
            <SvgEdit />
          </ActionIcon>
        </Flex>
      );
    },
    [onChangeCollateral]
  );

  const getRow = useCallback(
    (item: TransactionHistory): TableRow => {
      const conditionPrecedents = item.conditionPrecedents;
      const exceptions = item.exceptions;
      return {
        id: item.id,
        orderDate: toLocalDate(item.orderDate),
        quotationNo: item.quotationNo,
        saleOrderNumber: item.saleOrderNumber,
        invoiceNumber: item.invoice?.invoiceNumber,
        salesName: item.salesName,
        salesTeam: item.salesTeam,
        limitUsed: renderTransactionHistoryUsed(item),
        paymentType: toHumanReadable(item.paymentType, true),
        paymentMethod: renderPaymentMethod(item),
        orderStatus: orderStatusToHumanReadable(item.orderStatus),
        amountTotal: toCurrencyPrice(item.totalOrderAmount),
        totalInvoiced: toCurrencyPrice(item.invoice?.totalInvoice),
        outstandingPrincipal: toCurrencyPrice(item.invoice?.totalAmountDue),
        lateCharge: toCurrencyPrice(item.invoice?.lateCharge),
        totalAr: toCurrencyPrice(item.invoice?.totalAccountReceivable),
        totalPaid: toCurrencyPrice(item.invoice?.totalPaid),
        dpd: item.invoice?.dpd,
        paymentStatus: renderPaymentStatus(item),
        invoiceDueDate: toLocalDate(item.invoice?.invoiceDueDate),
        hasException: getExceptionColumn(item),
        isRestructured: booleanToString(item?.invoice?.isRestructured),
        conditionPrecedents: (
          <CPExceptionTableCard
            data={conditionPrecedents}
            isEdit={!!conditionPrecedents?.length}
            onEdit={() =>
              handleOpenEditModal(ModalType.ConditionPrecedents, item)
            }
          />
        ),
        exceptions: (
          <CPExceptionTableCard
            data={exceptions}
            isEdit={!!exceptions?.length}
            onEdit={() => handleOpenEditModal(ModalType.Exceptions, item)}
          />
        ),
        approval: renderApproval(item),
      };
    },
    [
      renderTransactionHistoryUsed,
      renderPaymentMethod,
      renderPaymentStatus,
      getExceptionColumn,
      renderApproval,
      handleOpenEditModal,
    ]
  );

  const rows = useMemo(() => {
    return data?.map(getRow);
  }, [data, getRow]);

  return [columns, rows];
};
