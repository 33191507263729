import { Flex } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import InvoicesTable from "../../../components/Invoice/InvoicesTable/InvoicesTable.tsx";
import { InvoiceFilter } from "../../../graphql/generated";
import { Invoice } from "../../../types/invoice/invoice.ts";
import InvoiceDetailTab from "../InvoiceDetailTab/InvoiceDetailTab.tsx";
import InvoicesFilterToolbar from "../InvoicesFilterToolbar/InvoicesFilterToolbar.tsx";
import { FiltersValue } from "../InvoicesFilterToolbar/types.ts";
import { useStyles } from "./InvoicesTab.styles.ts";
import { getInvoiceApiFilter } from "./utils.ts";

const InvoicesTab = () => {
  const { classes } = useStyles();
  const { customerId } = useParams() as { customerId: string };

  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>();

  const customerFilter = useMemo((): InvoiceFilter => {
    return { customerId: { equals: Number(customerId) } };
  }, [customerId]);
  const [filter, setFilter] = useState<InvoiceFilter>(customerFilter);

  const handleFilterChange = useCallback(
    (value: FiltersValue) => {
      setFilter({
        ...getInvoiceApiFilter(value),
        ...customerFilter,
      });
    },
    [customerFilter]
  );

  return (
    <>
      {selectedInvoice ? (
        <InvoiceDetailTab
          data={selectedInvoice}
          onBreadcrumbsClick={() => setSelectedInvoice(null)}
        />
      ) : (
        <Flex
          direction="column"
          align="flex-start"
          gap={{ base: 14, sm: 20 }}
          className={classes.wrapper}
        >
          <InvoicesFilterToolbar onValueChange={handleFilterChange} />
          <InvoicesTable filter={filter} onSelectRow={setSelectedInvoice} />
        </Flex>
      )}
    </>
  );
};

export default InvoicesTab;
