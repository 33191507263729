import { Button, Flex } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import {
  CreateLateFeeInput,
  LateFeeOrderBy,
  OrderBy,
  RolePermission,
} from "../../graphql/generated.ts";
import { useCreateLateFee } from "../../hooks/api/invoice/lateFee/useCreateLateFee.ts";
import { useLateFees } from "../../hooks/api/invoice/lateFee/useLateFees.ts";
import { useInvoices } from "../../hooks/api/invoice/useInvoices.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";
import { LateFee } from "../../types/invoice/lateFee.ts";
import { getPagesCount } from "../../utils/pagination.ts";
import { hasPermission } from "../../utils/user.ts";
import SvgPlus from "../Icons/Plus.tsx";
import Table from "../Table/Table.tsx";
import { SortDirection } from "../Table/types.ts";
import { useLateFeeTableData } from "./hooks/useLateFeeTableData.tsx";
import LateFeeModal from "./LateFeeModal.tsx";
import { getLateFeeApiSort } from "./utils.ts";

interface Props {
  customerId: number | null | undefined;
}

const LateFeeTab = ({ customerId }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<LateFeeOrderBy>({
    createdAt: OrderBy.Desc,
  });

  const [{ data, fetching }, refreshLateFees] = useLateFees({
    filter: { invoice: { customerId: { equals: customerId } } },
    orderBy,
    page,
    pause: Number.isNaN(customerId),
  });
  const lateFees: LateFee[] | undefined | null = data?.lateFees?.data;
  const pageCount = getPagesCount(data?.lateFees?.total);

  const [, createLateFee] = useMutationNotificationWrapper(useCreateLateFee(), {
    success: {
      message: "Late Fee has been updated successfully.",
    },
  });

  const [{ data: invoicesData, fetching: fetchingInvoices }, refreshInvoices] =
    useInvoices({
      filter: { customerId: { equals: customerId }, lateCharge: { gt: 0 } },
      pause: Number.isNaN(customerId),
    });
  const invoices = invoicesData?.invoices?.data;

  const canChangeLateFee = !fetchingInvoices && !!invoices?.length;

  const { user } = useCurrentUserContext();
  const hasEditAccess = hasPermission(user, RolePermission.CanEditLateFee);

  const [
    createModalOpened,
    { open: createModalOpen, close: createModalClose },
  ] = useDisclosure(false);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getLateFeeApiSort(sort));
    },
    [setOrderBy]
  );

  const [columns, rows] = useLateFeeTableData({
    data: lateFees,
  });

  const handleCreateLateFee = useCallback(
    (value: CreateLateFeeInput) => {
      createLateFee({ input: value }).then((result) => {
        if (!result.error) {
          refreshLateFees();
          refreshInvoices();
        }
      });
    },
    [createLateFee, refreshInvoices, refreshLateFees]
  );

  return (
    <>
      <Flex direction="column" gap={20}>
        {hasEditAccess && (
          <Button
            uppercase
            variant="filledGolden"
            leftIcon={<SvgPlus />}
            loading={fetchingInvoices}
            disabled={!canChangeLateFee}
            title="The Customer doesn't have any invoices with late charges."
            onClick={createModalOpen}
          >
            Add
          </Button>
        )}
        <Table
          columns={columns}
          rows={rows}
          verticalAlignment="top"
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.asc,
          }}
          loading={fetching}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          onSortChange={handleOnSortChange}
        />
      </Flex>
      {createModalOpened && (
        <LateFeeModal
          opened={true}
          invoices={invoices}
          onClose={createModalClose}
          onSubmit={handleCreateLateFee}
        />
      )}
    </>
  );
};

export default LateFeeTab;
