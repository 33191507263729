import { Text } from "@mantine/core";
import { isEmpty } from "lodash";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import ActionIcon from "../../../../../components/ActionIcon/ActionIcon.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgEdit from "../../../../../components/Icons/Edit";
import Spoiler from "../../../../../components/Spoiler/Spoiler.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import TableFilesDisplay from "../../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { CollectionActivity } from "../../../../../types/collectionActivity.ts";
import { formatLogDetails } from "../../../../../utils/collectionActivity.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toLocalNumber } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../../utils/user.ts";
import { useStyles } from "../CollectionActivityHistory.styles.ts";

interface Props {
  data: CollectionActivity[] | null | undefined;
  onEdit: (id: number) => void;
}

export function useCollectionActivityHistoryData({
  data,
  onEdit,
}: Props): TableData {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "createdAt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "createdAt",
          }}
        >
          Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "dpd",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "dpd",
          }}
        >
          DPD
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "100px",
    },
    {
      accessor: "collectorAssigned",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "collectorAssigned",
          }}
        >
          Collector Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
    {
      accessor: "category",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "category",
          }}
        >
          Collection Activity Category
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "detailCollectionActivity",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "detailCollectionActivity",
          }}
        >
          Detail Collection Activity
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "250px",
    },
    {
      accessor: "resultCategory",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "resultCategory",
          }}
        >
          Result Category
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
    {
      accessor: "resultDescription",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "resultDescription",
          }}
        >
          Result Description
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "300px",
    },
    {
      accessor: "promiseToPayAmount",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "promiseToPayAmount",
          }}
        >
          Promise To Pay Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "promiseToPayDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "promiseToPayDate",
          }}
        >
          Promise To Pay Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "attachment",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "attachment",
          }}
        >
          Attachment
        </DatabaseMetadataPopover>
      ),
      sortable: false,
      width: "135px",
    },
    {
      accessor: "logDetails",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CollectionActivity",
            fieldName: "logDetails",
          }}
        >
          Log Details
        </DatabaseMetadataPopover>
      ),
      width: "300px",
    },
    {
      accessor: "actions",
      title: "Actions",
      width: "100px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        createdAt: item.createdAt && toLocalDate(item.createdAt),
        dpd: toLocalNumber(item.invoice.dpd),
        collectorAssigned: item.collectorAssigned && (
          <Text className={classes.collectorAssignedWrapper}>
            {getUserDisplayName(item.collectorAssigned)}
          </Text>
        ),
        category: item.category && toHumanReadable(item.category),
        detailCollectionActivity: item.detailCollectionActivity,
        resultCategory:
          item.resultCategory && toHumanReadable(item.resultCategory),
        resultDescription: item.resultDescription && (
          <Spoiler text={item.resultDescription} />
        ),
        promiseToPayAmount: toLocalNumber(item.promiseToPayAmount),
        promiseToPayDate: toLocalDate(item.promiseToPayDate),
        attachment: item.attachment ? (
          <TableFilesDisplay files={[item.attachment]} />
        ) : null,
        logDetails: !isEmpty(item?.logDetails) ? (
          <Spoiler text={formatLogDetails(item.logDetails)} />
        ) : null,
        actions: (
          <ActionIcon onClick={() => onEdit(item.id)}>
            <SvgEdit />
          </ActionIcon>
        ),
      };
    });
  }, [data, classes, onEdit]);

  return [columns, rows];
}
