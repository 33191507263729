import { Badge, Button, Flex } from "@mantine/core";
import { useMemo } from "react";

import SvgCheck from "../../../../components/Icons/Check.tsx";
import SvgClose from "../../../../components/Icons/Close.tsx";
import SvgLeftArrow from "../../../../components/Icons/LeftArrow.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import NoteList from "../../../../components/NoteList/NoteList.tsx";
import {
  CuAnalysisResult,
  LimitRequestStatus,
  LimitStatus,
  RolePermission,
  UserRole,
} from "../../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider.tsx";
import { CreditUnderwriting } from "../../../../types/creditUnderwriting.ts";
import { LimitRequestDetail } from "../../../../types/limitRequest.ts";
import { hasPermission } from "../../../../utils/user.ts";

interface Props {
  limitRequest: LimitRequestDetail | null | undefined;
  creditUnderwriting: CreditUnderwriting | undefined | null;
  onAddData: () => void;
  onHandoverToRR: () => void;
  onHandoverToCU: () => void;
  onHandoverToCUManager: () => void;
  onRejectByCUManager: () => void;
}

export default function useCreditUnderwritingCardFooter({
  limitRequest,
  creditUnderwriting,
  onAddData,
  onHandoverToRR,
  onHandoverToCU,
  onHandoverToCUManager,
  onRejectByCUManager,
}: Props) {
  const { user } = useCurrentUserContext();

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditBuyerInfoLimitInfoCreditUnderwriting
  );
  const disabled =
    !hasEditAccess ||
    creditUnderwriting?.analysisResult === CuAnalysisResult.CancelSubmission;

  const cuManagerContent = useMemo(() => {
    if (limitRequest?.requestStatus === LimitRequestStatus.OngoingCu) {
      return (
        <Badge size="m" variant="active" w="100%">
          <Flex>
            <SvgCheck />
            Handovered to CU Officer
          </Flex>
        </Badge>
      );
    }
    return (
      <Flex w="100%" justify="center" direction="column" gap={10}>
        <Flex w="100%" justify="center" gap={20}>
          <Button
            variant="outlineGolden"
            leftIcon={<SvgLeftArrow />}
            w="50%"
            uppercase
            disabled={disabled}
            onClick={onHandoverToCU}
          >
            Decline
          </Button>
          <Button
            variant="outlineGolden"
            leftIcon={<SvgClose />}
            w="50%"
            uppercase
            disabled={disabled}
            onClick={onRejectByCUManager}
          >
            Reject
          </Button>
        </Flex>
        <Flex w="100%" justify="center">
          <Button
            variant="filledGolden"
            leftIcon={<SvgCheck />}
            w="100%"
            uppercase
            disabled={disabled}
            onClick={onHandoverToRR}
          >
            Handover to RR
          </Button>
        </Flex>
      </Flex>
    );
  }, [
    limitRequest?.requestStatus,
    disabled,
    onHandoverToCU,
    onRejectByCUManager,
    onHandoverToRR,
  ]);

  const cuOfficerContent = useMemo(() => {
    if (
      limitRequest?.requestStatus === LimitRequestStatus.OngoingCuManagerReview
    ) {
      return (
        <Badge size="m" variant="active" w="100%">
          <Flex>
            <SvgCheck />
            Handovered to CU Manager
          </Flex>
        </Badge>
      );
    }
    return (
      <Button
        variant="filledGolden"
        leftIcon={<SvgCheck />}
        w="100%"
        uppercase
        disabled={disabled}
        onClick={onHandoverToCUManager}
      >
        Handover to CU Manager
      </Button>
    );
  }, [limitRequest?.requestStatus, disabled, onHandoverToCUManager]);

  const content = useMemo(() => {
    if (!creditUnderwriting) {
      return (
        <Button
          variant="filledGolden"
          leftIcon={<SvgPlus />}
          w="30%"
          uppercase
          disabled={!hasEditAccess}
          onClick={onAddData}
        >
          Add Data
        </Button>
      );
    }

    let buttons =
      user?.role === UserRole.CuManager ? cuManagerContent : cuOfficerContent;
    if (
      limitRequest?.requestStatus !== LimitRequestStatus.OngoingCu &&
      limitRequest?.requestStatus !== LimitRequestStatus.OngoingCuManagerReview
    ) {
      buttons = (
        <Badge size="m" variant="active" w="100%">
          <Flex>
            <SvgCheck />
            Handovered to RR
          </Flex>
        </Badge>
      );
    }
    if (
      limitRequest?.requestStatus === LimitRequestStatus.Rejected &&
      limitRequest.limitDetail?.status === LimitStatus.RejectedByCu
    ) {
      buttons = (
        <Badge size="m" variant="updated" w="100%">
          <Flex>
            <SvgClose />
            Rejected by CU Manager
          </Flex>
        </Badge>
      );
    }
    if (limitRequest?.limitDetail?.status === LimitStatus.CancelSubmission) {
      buttons = (
        <Badge size="m" variant="updated" w="100%">
          <Flex>
            <SvgClose />
            Cancel Submission
          </Flex>
        </Badge>
      );
    }

    return (
      <Flex gap={12} direction="column" w="30%">
        <Flex gap={20} w="100%" justify="center">
          {buttons}
        </Flex>
        <NoteList notes={creditUnderwriting?.rejectNotes} />
      </Flex>
    );
  }, [
    limitRequest,
    creditUnderwriting,
    hasEditAccess,
    user?.role,
    cuManagerContent,
    cuOfficerContent,
    onAddData,
  ]);

  return (
    <Flex
      gap={20}
      w="100%"
      justify="flex-end"
      align="flex-start"
      direction="row"
    >
      {content}
    </Flex>
  );
}
