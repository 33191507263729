import { useDisclosure } from "@mantine/hooks";
import { useCallback, useMemo } from "react";

import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal.tsx";
import {
  CreateOfferLetterInput,
  LimitRequestStatus,
  RolePermission,
  UpdateOfferLetterInput,
} from "../../../graphql/generated.ts";
import { useActivateLimit } from "../../../hooks/api/limitRequest/useActivateLimit.ts";
import { useRejectLimitRequest } from "../../../hooks/api/limitRequest/useRejectLimitRequest.ts";
import { useCreateOfferLetter } from "../../../hooks/api/offerLetter/useCreateOfferLetter.ts";
import { useGetOfferingLetter } from "../../../hooks/api/offerLetter/useGetOfferingLetter.ts";
import { useUpdateOfferLetter } from "../../../hooks/api/offerLetter/useUpdateOfferLetter.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import { LimitRequestDetail } from "../../../types/limitRequest.ts";
import { hasPermission } from "../../../utils/user.ts";
import LimitRequestDetailCardControl from "../LimitRequestDetailCardControl/LimitRequestDetailCardControl.tsx";
import { useOfferingLetterCardData } from "./hooks/useOfferingLetterCardData.tsx";
import { useOfferingLetterCardFooter } from "./hooks/useOfferingLetterCardFooter.tsx";
import OfferingLetterModal from "./OfferingLetterModal.tsx";

interface Props {
  limitRequest:
    | Pick<LimitRequestDetail, "id" | "requestStatus" | "offerLetter">
    | null
    | undefined;
  onRefreshLimitRequest: () => void;
}

const OfferingLetterCard = ({ limitRequest, onRefreshLimitRequest }: Props) => {
  const [{ data: offeringLetterResponse, fetching }, refresh] =
    useGetOfferingLetter({
      id: limitRequest?.offerLetter?.id,
    });

  const offeringLetter = offeringLetterResponse?.offerLetter;

  const { user } = useCurrentUserContext();

  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const [
    showRejectConfirmationModal,
    { open: openRejectConfirmationModal, close: closeRejectConfirmationModal },
  ] = useDisclosure(false);

  const [, updateOfferLetter] = useMutationNotificationWrapper(
    useUpdateOfferLetter(),
    {
      success: {
        message: "Offer Letter data was successfully updated.",
      },
    }
  );

  const [, createOfferLetter] = useMutationNotificationWrapper(
    useCreateOfferLetter(),
    {
      success: {
        message: "Offer Letter data was successfully added.",
      },
    }
  );

  const [, activateLimit] = useMutationNotificationWrapper(useActivateLimit(), {
    success: {
      message: "Limit Request was successfully activated.",
    },
  });

  const [, rejectLimit] = useMutationNotificationWrapper(
    useRejectLimitRequest(),
    {
      success: {
        message: "Limit Request was successfully rejected.",
      },
    }
  );

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditBuyerInfoLimitInfoOfferingLetter
  );

  const handleActivateLimit = useCallback(() => {
    if (!limitRequest) {
      return;
    }
    activateLimit({ id: limitRequest.id }).then((result) => {
      if (!result.error) {
        onRefreshLimitRequest();
      }
    });
  }, [activateLimit, limitRequest, onRefreshLimitRequest]);

  const handleRejectLimit = useCallback(() => {
    if (!limitRequest) {
      return;
    }
    rejectLimit({ id: limitRequest.id })
      .then((result) => {
        if (!result.error) {
          onRefreshLimitRequest();
        }
      })
      .finally(closeRejectConfirmationModal);
  }, [
    closeRejectConfirmationModal,
    limitRequest,
    onRefreshLimitRequest,
    rejectLimit,
  ]);

  const offeringLetterInfo = useOfferingLetterCardData({ offeringLetter });

  const cardControl = useMemo(() => {
    if (!offeringLetter) {
      return null;
    }
    const canEdit =
      hasEditAccess &&
      offeringLetter &&
      limitRequest?.requestStatus &&
      [LimitRequestStatus.Ol, LimitRequestStatus.Approved].includes(
        limitRequest.requestStatus
      );
    return (
      <LimitRequestDetailCardControl
        updatedBy={offeringLetter?.updatedBy}
        edit={!!canEdit}
        onEdit={openModal}
      />
    );
  }, [offeringLetter, hasEditAccess, limitRequest, openModal]);

  const cardFooterContent = useOfferingLetterCardFooter({
    offeringLetter,
    canEdit: hasEditAccess,
    limitRequest,
    onActivateLimit: handleActivateLimit,
    onRejectLimit: openRejectConfirmationModal,
    onAddData: openModal,
  });

  const handleOfferLetterSubmit = (input: UpdateOfferLetterInput) => {
    if (!limitRequest) {
      return;
    }
    if (limitRequest?.offerLetter?.id) {
      updateOfferLetter({ id: limitRequest?.offerLetter?.id, input }).then(
        (result) => {
          if (!result.error) {
            refresh();
          }
        }
      );
    } else {
      createOfferLetter({
        input: {
          ...input,
          limitRequestId: limitRequest.id,
        } as CreateOfferLetterInput,
      })
        .then((result) => {
          if (!result.error) {
            onRefreshLimitRequest();
          }
        })
        .then(() => refresh());
    }
    closeModal();
  };

  return (
    <>
      <MultiFieldCard
        label="Offering Letter"
        variant="blue"
        loading={fetching}
        items={offeringLetterInfo}
        cardControl={cardControl}
        cardFooterContent={cardFooterContent}
        disabled={
          !offeringLetter &&
          limitRequest?.requestStatus !== LimitRequestStatus.Ol
        }
      />
      {modalOpened && (
        <OfferingLetterModal
          value={offeringLetter}
          limitRequest={limitRequest}
          opened={true}
          onClose={closeModal}
          onSubmit={handleOfferLetterSubmit}
        />
      )}
      {showRejectConfirmationModal && (
        <ConfirmationModal
          opened={true}
          message={
            "Are you sure you want to Reject this limit? This process is Irreversible."
          }
          onConfirm={handleRejectLimit}
          onClose={closeRejectConfirmationModal}
        />
      )}
    </>
  );
};

export default OfferingLetterCard;
