import { Flex } from "@mantine/core";

import { Invoice } from "../../../types/invoice/invoice.ts";
import CollectionActivityHistory from "./CollectionActivityHistory/CollectionActivityHistory.tsx";
import InvoiceBreadcrumbs from "./InvoiceBreadcrumbs/InvoiceBreadcrumbs.tsx";
import InvoiceDetailsCards from "./InvoiceDetailsCards/InvoiceDetailsCards.tsx";
import PaymentHistory from "./PaymentHistory/PaymentHistory.tsx";

interface InvoiceDetailsProps {
  data: Invoice;
  onBreadcrumbsClick?: () => void;
}

const InvoiceDetailTab = ({
  data,
  onBreadcrumbsClick,
}: InvoiceDetailsProps) => {
  return (
    <>
      <Flex direction="column" gap={20}>
        <InvoiceBreadcrumbs
          invoice={data}
          onBreadcrumbsClick={onBreadcrumbsClick}
        />
        <InvoiceDetailsCards invoice={data} />
        <CollectionActivityHistory invoiceId={data.id} />
        <PaymentHistory invoiceId={data.id} />
      </Flex>
    </>
  );
};

export default InvoiceDetailTab;
