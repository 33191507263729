import { TabProps } from "@mantine/core";

interface Tab extends TabProps {
  label: string;
}

export enum TabValue {
  OdooContactData = "odooContactData",
  Invoices = "invoices",
  CollectionActivity = "collectionActivity",
  CollectionDocument = "collectionDocument",
  Restructure = "restructure",
  Waive = "waive",
  LateFee = "lateFee",
}

export const tabs: Tab[] = [
  {
    value: TabValue.OdooContactData,
    label: "Odoo Contact Data",
  },
  {
    value: TabValue.Invoices,
    label: "Invoices",
  },
  {
    value: TabValue.CollectionActivity,
    label: "Collection activity",
  },
  {
    value: TabValue.CollectionDocument,
    label: "Collection document",
  },
  {
    value: TabValue.Restructure,
    label: "Restructure",
  },
  {
    value: TabValue.Waive,
    label: "Waive",
  },
  {
    value: TabValue.LateFee,
    label: "Late Fee",
  },
];
