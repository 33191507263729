import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import {
  TableData,
  TableRow,
} from "../../../../../../components/Table/types.ts";
import { ClikGrantedContract } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import { toHumanReadable } from "../../../../../../utils/string.ts";
import ClikResultContractLinkedSubjects from "../../ClikResultContractLinkedSubjects.tsx";

interface Props {
  data: ClikGrantedContract[] | null | undefined;
}

export function useCRGrantedContracts({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "referenceNo",
      title: "No.",
      width: "100px",
    },
    {
      accessor: "cbContractCode",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "cbContractCode",
          }}
        >
          CB Contract Code
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerContractNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "providerContractNumber",
          }}
        >
          Provider Contract Number
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "contractType",
          }}
        >
          Contract Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractPhase",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "contractPhase",
          }}
        >
          Contract Phase
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "role",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "role",
          }}
        >
          Role
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "startDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "startDate",
          }}
        >
          Start Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "dueDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "dueDate",
          }}
        >
          Due Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
    {
      accessor: "providerType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "providerType",
          }}
        >
          Provider Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerCode",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "providerCode",
          }}
        >
          Provider
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "referenceDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "referenceDate",
          }}
        >
          Last Update Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "linkedSubject",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "linkedSubjects",
          }}
        >
          Linked Subjects List
        </DatabaseMetadataPopover>
      ),
      sortable: false,
      width: "350px",
    },
    {
      accessor: "note",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikGrantedContract",
            fieldName: "note",
          }}
        >
          Note
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
  ];
  const rows = useMemo(() => {
    return data?.map((item, index) => {
      return {
        id: item.id,
        referenceNo: index + 1,
        cbContractCode: item.commonData.cbContractCode,
        providerContractNumber: item.commonData.providerContractNumber,
        contractType:
          item.commonData.contractType &&
          toHumanReadable(item.commonData.contractType),
        contractPhase: item.commonData.contractPhase,
        role: item.commonData.role,
        startDate:
          item.commonData.startDate && toLocalDate(item.commonData.startDate),
        dueDate:
          item.commonData.dueDate && toLocalDate(item.commonData.dueDate),
        providerType: item.commonData.providerType,
        providerCode: item.commonData.providerCode,
        referenceDate:
          item.commonData.referenceDate &&
          toLocalDate(item.commonData.referenceDate),
        linkedSubject: (
          <ClikResultContractLinkedSubjects data={item.linkedSubjects} />
        ),
        note: item.note,
      };
    });
  }, [data]);

  return [columns, rows];
}
