import { useLocalStorage } from "@mantine/hooks";
import { createContext, ReactNode, useContext } from "react";

import { UserRole } from "../graphql/generated.ts";
import { useDatabaseMetadata } from "../hooks/api/databaseMetadata.ts";
import { DatabaseMetadata } from "../types/databaseMetadata.ts";
import { useCurrentUserContext } from "./CurrentUserProvider.tsx";

interface XRayContextProps {
  metadata: DatabaseMetadata | null | undefined;
  metadataLoading: boolean;
  xrayModeEnabled: boolean;
  setXrayModeEnabled: (newValue: boolean) => void;
  hasXrayModeAccess: boolean;
}

const XRayContext = createContext<XRayContextProps | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export const XRayProvider = ({ children }: Props) => {
  const [xrayModeEnabled, setXrayModeEnabled] = useLocalStorage({
    key: "xrayMode",
    defaultValue: false,
  });
  const [{ data: metadataQuery, fetching: metadataLoading }] =
    useDatabaseMetadata();
  const metadata = metadataQuery?.metadata;

  const { user } = useCurrentUserContext();
  const hasXrayModeAccess = user
    ? [UserRole.SuperAdmin, UserRole.Admin].includes(user.role)
    : false;

  return (
    <XRayContext.Provider
      value={{
        metadata,
        metadataLoading,
        xrayModeEnabled,
        setXrayModeEnabled,
        hasXrayModeAccess,
      }}
    >
      {children}
    </XRayContext.Provider>
  );
};

export const useXRayContext = () => {
  const context = useContext(XRayContext);
  if (!context) {
    throw new Error("useXRayContext must be used within a XRayProvider");
  }
  return context;
};
