import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import { RestructureApprovalStatus } from "../../../graphql/generated";
import { RestructureDetail } from "../../../types/restructure";
import { toLocalDate } from "../../../utils/date.ts";
import { toCurrencyPrice } from "../../../utils/number";
import { toHumanReadable } from "../../../utils/string.ts";
import ActionIcon from "../../ActionIcon/ActionIcon";
import { DatabaseMetadataPopover } from "../../DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgEdit from "../../Icons/Edit";
import DisableButton from "../../Table/DisableButton.tsx";
import { TableData, TableRow } from "../../Table/types.ts";

interface Props {
  data: RestructureDetail[] | null | undefined;
  restructureStatus: RestructureApprovalStatus;
  showActionsColumn: boolean;
  onEdit?: (id: number) => void;
}

export function useRestructureDetailsTableData({
  data,
  restructureStatus,
  showActionsColumn,
  onEdit,
}: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "invoiceNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceNumber",
          }}
        >
          Inv No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "dueDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "RestructureDetail",
            fieldName: "dueDate",
          }}
        >
          Due Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "outstandingPrincipal",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "RestructureDetail",
            fieldName: "outstandingPrincipal",
          }}
        >
          OSP
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 140,
    },
    {
      accessor: "fee",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "RestructureDetail",
            fieldName: "fee",
          }}
        >
          Fee
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "amountToBePaid",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "RestructureDetail",
            fieldName: "amountToBePaid",
          }}
        >
          Amount To Be Paid
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "actions",
      title: "Actions",
      hidden: !showActionsColumn,
    },
  ];

  const handleEdit = useCallback(
    (id: number) => {
      onEdit && onEdit(id);
    },
    [onEdit]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        invoiceNumber: item.invoice?.invoiceNumber,
        dueDate: toLocalDate(item.dueDate),
        outstandingPrincipal: toCurrencyPrice(item.outstandingPrincipal),
        fee: toCurrencyPrice(item.fee),
        amountToBePaid: toCurrencyPrice(item.amountToBePaid),
        actions:
          restructureStatus !== RestructureApprovalStatus.Pending ? (
            <DisableButton
              text={`Editing is not allowed, restructure status is ${toHumanReadable(
                restructureStatus
              )}`}
            />
          ) : (
            <ActionIcon onClick={() => handleEdit(item.id)}>
              <SvgEdit />
            </ActionIcon>
          ),
      };
    });
  }, [data, restructureStatus, handleEdit]);

  return [columns, rows];
}
