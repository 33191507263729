import { Button, Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import Plus from "../../components/Icons/Plus.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Table from "../../components/Table/Table.tsx";
import { SortDirection } from "../../components/Table/types.ts";
import Title from "../../components/Title/Title.tsx";
import { AppRoute } from "../../constants.ts";
import {
  EmployeeCandidateRequestOrderBy,
  OrderBy,
  RolePermission,
  UpdateEmployeeCandidateRequestInput,
} from "../../graphql/generated.ts";
import { useGetEmployeeCandidateRequests } from "../../hooks/api/employeeCandidateRequest/useGetEmployeeCandidateRequests.ts";
import { useUpdateEmployeeCandidateRequest } from "../../hooks/api/employeeCandidateRequest/useUpdateEmployeeCandidateRequest.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";
import { getApiOrderBy } from "../../utils/api.ts";
import { getPagesCount } from "../../utils/pagination.ts";
import { hasPermission } from "../../utils/user.ts";
import { useEmployeeCandidateRequestTableData } from "./hooks/useEmployeeCandidateRequestTableData.tsx";

const EmployeeCandidatePage = () => {
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<EmployeeCandidateRequestOrderBy>({
    createdAt: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);

  const [{ data: employeeCandidateData, fetching }, refreshData] =
    useGetEmployeeCandidateRequests({
      orderBy,
      page,
    });

  const { user } = useCurrentUserContext();

  const [, updateEmployeeCandidate] = useMutationNotificationWrapper(
    useUpdateEmployeeCandidateRequest(),
    {
      success: {
        message: "Employee Candidate Request has been successfully updated.",
      },
    }
  );

  const handleEditEmployeeCandidate = useCallback(
    (id: number, value: UpdateEmployeeCandidateRequestInput) => {
      updateEmployeeCandidate({ id: id, input: value }).then(refreshData);
    },
    [refreshData, updateEmployeeCandidate]
  );

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditEmployeeCandidateClik
  );

  const hasClikResultViewAccess = hasPermission(
    user,
    RolePermission.CanViewClikResult
  );

  const [columns, rows] = useEmployeeCandidateRequestTableData({
    data: employeeCandidateData?.employeeCandidateRequests?.data,
    hasEditAccess,
    hasClikResultViewAccess,
    onEditEmployeeCandidate: handleEditEmployeeCandidate,
  });

  const pageCount = getPagesCount(
    employeeCandidateData?.employeeCandidateRequests?.total
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handleAddLimitRequest = useCallback(() => {
    navigate(AppRoute.EmployeeCandidateCreate);
  }, [navigate]);

  return (
    <Layout>
      <Flex direction="column" align="flex-start" gap={{ base: 16, sm: 24 }}>
        <Flex direction="column" gap={16}>
          <Title>Employee Candidate</Title>
          {hasEditAccess && (
            <Button
              variant="filledGolden"
              leftIcon={<Plus />}
              onClick={handleAddLimitRequest}
              uppercase
            >
              add new request
            </Button>
          )}
        </Flex>
        <Table
          columns={columns}
          rows={rows}
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.desc,
          }}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          loading={fetching}
          onSortChange={handleOnSortChange}
        />
      </Flex>
    </Layout>
  );
};

export default EmployeeCandidatePage;
