import { createStyles, MantineTheme, rem } from "@mantine/core";

import { Variant, Variants } from "./types";

export interface ActionIconStylesProps {
  size?: number;
  variant?: Variant;
  spaces?: boolean;
}

export const useStyles = createStyles(
  (
    theme: MantineTheme,
    { size, variant = "primary", spaces = true }: ActionIconStylesProps
  ) => {
    const variants: Variants = {
      primary: {
        base: { color: theme.colors.dusk[0] },
        hover: { background: theme.colors.actionIcon[0] },
        active: { background: theme.colors.actionIcon[1] },
      },
      error: {
        base: { color: theme.colors.error[0] },
        hover: { background: theme.colors.actionIcon[2] },
        active: { background: theme.colors.actionIcon[3] },
      },
      success: {
        base: { color: theme.colors.greenIce[3] },
        hover: { background: theme.colors.greenIce[0] },
        active: { background: theme.colors.greenIce[0] },
      },
      ghost: {
        base: { color: "inherit", background: "transparent" },
        hover: { background: "transparent" },
        active: { background: "transparent" },
      },
      disabled: {
        base: { color: "#ced4da", background: "#f1f3f5" },
        hover: { color: "#ced4da", background: "#f1f3f5" },
        active: { color: "#ced4da", background: "#f1f3f5" },
      },
    };

    const currentVariant = variants[variant];

    return {
      wrapper: {
        display: "inline-block",
        borderRadius: rem(4),
        color: currentVariant?.base?.color || theme.colors.black[0],
        background: currentVariant?.base?.background || theme.colors.white[0],
      },
      button: {
        cursor: "pointer",
        border: "none",
        padding: 0,
        margin: 0,
        display: "block",
        borderRadius: "inherit",
        color: "inherit",
        background: "inherit",
        "&:disabled": {
          color: theme.colors.grayIce[0],
        },
        "&:hover": {
          color: currentVariant?.hover?.color,
          background: currentVariant?.hover?.background,
        },
        "&:active, &:active > div": {
          color: currentVariant?.active?.color,
          background: currentVariant?.active?.background,
        },
      },
      icon: {
        width: rem(size),
        height: rem(size),
        borderRadius: "inherit",
        border: "1px solid currentColor",
        borderColor: variant === "ghost" ? "transparent" : undefined,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: ".2s ease",
        padding: spaces ? rem(4) : undefined,
        svg: {
          width: "auto",
          height: "100%",
          display: "block",
        },
      },
    };
  }
);
