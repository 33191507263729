import isNil from "lodash/isNil";
import { useQuery } from "urql";

import {
  CustomerChangeLogFilter,
  CustomerChangeLogOrderBy,
  GetCustomerChangeLogDocument,
  GetCustomerChangeLogQueryVariables,
} from "../../../graphql/generated";
import { ApiHookProps } from "../types.ts";

interface Props
  extends ApiHookProps<CustomerChangeLogFilter, CustomerChangeLogOrderBy> {
  id: number | undefined;
}

export const useCustomerChangeLog = ({ id, filter, orderBy }: Props) => {
  const variables: GetCustomerChangeLogQueryVariables = {
    id: Number(id),
    filter,
    orderBy,
  };
  return useQuery({
    query: GetCustomerChangeLogDocument,
    variables,
    pause: isNil(id),
  });
};
