import { Select, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import React, { useCallback, useMemo } from "react";

import {
  Invoice,
  toHumanReadablePaymentType,
} from "../../../../types/invoice/invoice.ts";
import { UserOption } from "../../../../types/user.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { getDpdBucketDisplayName } from "../../../../utils/invoice.ts";
import { toCurrencyPrice, toLocalNumber } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { getUserDisplayName, getUserOptions } from "../../../../utils/user.ts";
import { DatabaseMetadataPopover } from "../../../DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../Table/types.ts";
import { useStyles } from "../InvoicesTables.styles.ts";

interface Props {
  data: Invoice[] | null | undefined;
  collectors?: UserOption[] | null | undefined;
  canEditCollectorAssigned?: boolean;
  onChange: (id: number, value: string) => void;
}

export function useInvoicesTableData({
  data,
  collectors,
  canEditCollectorAssigned = false,
  onChange,
}: Props): TableData {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "invoiceNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceNumber",
          }}
        >
          Inv No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "250px",
    },
    {
      accessor: "customerName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Customer", fieldName: "name" }}
        >
          Company Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "350px",
    },
    {
      accessor: "totalInvoice",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "totalInvoice" }}
        >
          Total Inv Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "invoiceDueDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceDueDate",
          }}
        >
          Inv Due Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "dpd",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "dpd" }}
        >
          DPD
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "100px",
    },
    {
      accessor: "dpdBucket",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "dpdBucket" }}
        >
          DPD Bucket
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "totalPaid",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "totalPaid" }}
        >
          Total Paid Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "totalAmountDue",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "totalAmountDue",
          }}
        >
          OSP Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "lateCharge",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "lateCharge",
          }}
        >
          Total Late Charge
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "totalAccountReceivable",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "totalAccountReceivable",
          }}
        >
          Total AR
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "125px",
    },
    {
      accessor: "lastPaymentDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "InvoicePayment",
            fieldName: "paidAt",
          }}
        >
          Last Payment Date
        </DatabaseMetadataPopover>
      ),
    },
    {
      accessor: "lastPaymentAmount",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "InvoicePayment",
            fieldName: "amount",
          }}
        >
          Last Paid Amount
        </DatabaseMetadataPopover>
      ),
    },
    {
      accessor: "paymentStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentStatus",
          }}
        >
          Payment Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "paymentMethod",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentMethod",
          }}
        >
          Payment Method
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "paymentType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentType",
          }}
        >
          Payment Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitUsed",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "limitUsed",
          }}
        >
          Limit Used
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "300px",
    },
    {
      accessor: "hasException",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "hasException",
          }}
        >
          Has Exception
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "salesName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "salesName" }}
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "180px",
    },
    {
      accessor: "salesTeamName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "salesTeamName",
          }}
        >
          Sales Team
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "180px",
    },
    {
      accessor: "collectorAssigned",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "collectorAssigned",
          }}
        >
          Collector Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
    {
      accessor: "isRestructured",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "isRestructured",
          }}
        >
          Is Restructured
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
  ];

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    //  stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();
  };

  const handleOnChange = useCallback(
    (id: number, value: string) => {
      onChange(id, value);
    },
    [onChange]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        invoiceNumber: item.invoiceNumber,
        customerName: item.customer.name,
        totalInvoice: toCurrencyPrice(item.totalInvoice),
        invoiceDueDate: toLocalDate(item.invoiceDueDate),
        dpd: toLocalNumber(item.dpd),
        dpdBucket: getDpdBucketDisplayName(item.dpdBucket),
        totalPaid: toCurrencyPrice(item.totalPaid),
        totalAmountDue: toCurrencyPrice(item.totalAmountDue),
        lateCharge: toCurrencyPrice(item.lateCharge),
        totalAccountReceivable: toCurrencyPrice(item.totalAccountReceivable),
        lastPaymentDate: toLocalDate(item.lastPayment?.paidAt),
        lastPaymentAmount: toCurrencyPrice(item.lastPayment?.amount),
        paymentStatus: toHumanReadable(item.paymentStatus),
        paymentMethod: toHumanReadable(item.paymentMethod, true),
        paymentType: toHumanReadablePaymentType(item.paymentType),
        limitUsed: item.limitUsed,
        hasException: item.hasException ? "Yes" : "No",
        salesName: item.salesName,
        salesTeamName: item.salesTeamName,
        isRestructured: item.isRestructured ? "Yes" : "No",
        collectorAssigned: canEditCollectorAssigned ? (
          <Select
            key={item.id}
            data={getUserOptions(collectors)}
            value={
              item.collectorAssigned ? String(item.collectorAssigned.id) : null
            }
            placeholder="Select a collector"
            size="s"
            withinPortal
            zIndex={100}
            onChange={(value: string) => handleOnChange(item.id, value)}
            onClick={(event) => handleOnClick(event)}
          />
        ) : (
          <Text className={classes.collectorAssignedWrapper}>
            {getUserDisplayName(item.collectorAssigned)}
          </Text>
        ),
      };
    });
  }, [data, collectors, canEditCollectorAssigned, classes, handleOnChange]);

  return [columns, rows];
}
