import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import { ClikFootprint } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";

interface Props {
  data: ClikFootprint[] | null | undefined;
}

export function useCRFootprint({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "enquiryDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikFootprint",
            fieldName: "enquiryDate",
          }}
        >
          Enquiry Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "purposeCode",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikFootprint",
            fieldName: "purposeCode",
          }}
        >
          Purpose
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "300px",
    },
    {
      accessor: "instituteDesc",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikFootprint",
            fieldName: "instituteDesc",
          }}
        >
          Institute
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
    {
      accessor: "enquiryType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikFootprint",
            fieldName: "enquiryType",
          }}
        >
          Enquiry Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => ({
      id: item.id,
      enquiryDate: item.enquiryDate && toLocalDate(item.enquiryDate),
      purposeCode: item.purposeCode,
      instituteDesc: item.instituteDesc,
      enquiryType: item.enquiryType,
    }));
  }, [data]);

  return [columns, rows];
}
