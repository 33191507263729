import { useMemo } from "react";
import { Link } from "react-router-dom";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikDataLimitRequest } from "../../../../types/clikData.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface Props {
  limitRequest: ClikDataLimitRequest | null | undefined;
}

export const useLimitRequestCompanyInfoCards = ({ limitRequest }: Props) => {
  const companyInfo = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "Customer",
              fieldName: "name",
            }}
            position="right"
          >
            Company Clik Result Name
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.company.name,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "Customer",
              fieldName: "salesName",
            }}
            position="right"
          >
            Sales Name
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.company.salesName,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "Customer",
              fieldName: "salesTeamName",
            }}
            position="right"
          >
            Sales Team
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.company.salesTeamName,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "bussinessIdentifyNumber",
            }}
            position="right"
          >
            Business Identity Number
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.bussinessIdentifyNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "phone",
            }}
            position="right"
          >
            Phone Number
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.phone && (
          <Link to={"tel:" + limitRequest?.phone} target="_blank">
            {limitRequest.phone}
          </Link>
        ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "npwpCompany",
            }}
            position="right"
          >
            NPWP company
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.npwpCompany?.url && (
          <Link to={limitRequest.npwpCompany.url} target="_blank">
            Download
          </Link>
        ),
      },
    ];
  }, [limitRequest]);

  const companyAddress = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "npwpAddress",
            }}
            position="right"
          >
            NPWP address
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.npwpAddress,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "subDistrict",
            }}
            position="right"
          >
            Sub-district
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.subDistrict,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "district",
            }}
            position="right"
          >
            District
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.district,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "city",
            }}
            position="right"
          >
            City
          </DatabaseMetadataPopover>
        ),
        content: toHumanReadable(limitRequest?.city.name),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "postalCode",
            }}
            position="right"
          >
            Postal Code
          </DatabaseMetadataPopover>
        ),
        content: limitRequest?.postalCode,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "LimitRequest",
              fieldName: "country",
            }}
            position="right"
          >
            Country
          </DatabaseMetadataPopover>
        ),
        content:
          limitRequest?.country?.name &&
          toHumanReadable(limitRequest.country.name),
      },
    ];
  }, [limitRequest]);

  return [companyInfo, companyAddress];
};
