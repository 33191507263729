import { Button, Select, SimpleGrid } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { DatesRangeValue } from "@mantine/dates/lib/types/DatePickerValue";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SvgCalendar from "../../../../components/Icons/Calendar.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import SvgWarning from "../../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../components/Table/types.ts";
import {
  CreatePksInput,
  OrderBy,
  PksFilter,
  PksOrderBy,
  RolePermission,
  UpdatePksInput,
} from "../../../../graphql/generated.ts";
import { useCreatePks } from "../../../../hooks/api/pks/useCreatePks.ts";
import { usePksList } from "../../../../hooks/api/pks/usePksList.ts";
import { useUpdatePks } from "../../../../hooks/api/pks/useUpdatePks.ts";
import { useMutationNotificationWrapper } from "../../../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider.tsx";
import { Pks, pksStatusOptions } from "../../../../types/pks.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { getPagesCount } from "../../../../utils/pagination.ts";
import { hasPermission } from "../../../../utils/user.ts";
import { usePksTableData } from "./hooks/usePksTableData.tsx";
import PksModal, { PksModalSubmitValue } from "./PksModal/PksModal.tsx";
import { getPksApiFilter } from "./utils.ts";

interface Props {
  customerId: number | null | undefined;
}

export default function PksTable({ customerId }: Props) {
  const [filter, setFilter] = useState<PksFilter>({});
  const [orderBy, setOrderBy] = useState<PksOrderBy>({
    pksDate: OrderBy.Asc,
  });
  const [page, setPage] = useState<number>(1);
  const [{ data, fetching, error }, refresh] = usePksList({
    filter: { ...filter, customerId: { equals: customerId } },
    orderBy,
    page,
  });

  const { user: currentUser } = useCurrentUserContext();

  const pksList: Pks[] | undefined | null = data?.pksList?.data;
  const pageCount = getPagesCount(data?.pksList?.total);

  const [, createPks] = useMutationNotificationWrapper(useCreatePks(), {
    success: {
      message: "PKS has been added successfully.",
    },
  });

  const [, updatePks] = useMutationNotificationWrapper(useUpdatePks(), {
    success: {
      message: "PKS has been updated successfully.",
    },
  });

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditBuyerInfoLimitInfoPksLists
  );

  const handlePksModalOpen = (pks: Pks) => {
    setSelectedPks(pks);
    pksModalOpen();
  };

  const handleUpdate = useCallback(
    (id: number, input: UpdatePksInput) => {
      updatePks({
        id,
        input,
      }).then((result) => {
        if (!result.error) {
          refresh();
        }
      });
    },
    [refresh, updatePks]
  );

  const [columns, rows] = usePksTableData({
    data: pksList,
    canEdit: hasEditAccess,
    onEdit: handleUpdate,
    onOpenEditModal: handlePksModalOpen,
  });

  const [selectedPks, setSelectedPks] = useState<Pks | null>();

  const [pksModalOpened, { open: pksModalOpen, close: pksModalClose }] =
    useDisclosure(false);

  const handlePksModalClose = () => {
    setSelectedPks(null);
    pksModalClose();
  };

  const handleCreate = useCallback(
    (input: CreatePksInput) => {
      createPks({
        input: {
          ...input,
          customerId,
        } as CreatePksInput,
      }).then((result) => {
        if (!result.error) {
          refresh();
        }
      });
    },
    [createPks, customerId, refresh]
  );

  const handlePksModalSubmit = (input: PksModalSubmitValue) => {
    if (selectedPks) {
      handleUpdate(selectedPks.id, input as UpdatePksInput);
    } else {
      handleCreate({
        ...input,
        customerId,
      } as CreatePksInput);
    }
    handlePksModalClose();
  };

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback(
    (key: string, value: string | DatesRangeValue | null) => {
      setFilter(getPksApiFilter({ [key]: value }));
    },
    []
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  if (!customerId) {
    return;
  }

  if (error) {
    notifications.clean();
    notifications.show({
      message: "Something went wrong while trying to fetch PKS Lists data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <>
      <SectionTitle variant="bronze">PKS Lists</SectionTitle>
      {hasEditAccess && (
        <Button
          uppercase
          variant="filledGolden"
          leftIcon={<SvgPlus />}
          onClick={pksModalOpen}
        >
          Add
        </Button>
      )}
      <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
        <Select
          size="m"
          clearable
          label="PKS Status"
          placeholder="Select PKS Status"
          data={pksStatusOptions}
          onChange={(value: string) => handleFilterChange("pksStatus", value)}
        />
        <DatePickerInput
          type="range"
          label="PKS Due Date Period"
          placeholder="Select PKS Due Date Period"
          icon={<SvgCalendar />}
          firstDayOfWeek={0}
          size="m"
          clearable
          onChange={(value: DatesRangeValue) =>
            handleFilterChange("pksDueDatePeriod", value)
          }
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onSortChange={handleSortChange}
      />

      {pksModalOpened && (
        <PksModal
          value={selectedPks}
          opened={pksModalOpened}
          onClose={handlePksModalClose}
          onSubmit={handlePksModalSubmit}
        />
      )}
    </>
  );
}
