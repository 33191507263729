import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { LateFee } from "../../../types/invoice/lateFee.ts";
import { toLocalDate } from "../../../utils/date.ts";
import { toCurrencyPrice } from "../../../utils/number.ts";
import { getUserDisplayName } from "../../../utils/user.ts";
import { DatabaseMetadataPopover } from "../../DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../Table/types.ts";
import TableFilesDisplay from "../../TableFilesDisplay/TableFilesDisplay.tsx";

interface Props {
  data: LateFee[] | null | undefined;
}

export const useLateFeeTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "createdAt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LateFee",
            fieldName: "createdAt",
          }}
        >
          Change Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "invoiceNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceNumber",
          }}
        >
          Invoice Number
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "paymentType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentType",
          }}
        >
          Payment Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "paymentMethod",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentMethod",
          }}
        >
          Payment Method
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "previousAmount",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LateFee",
            fieldName: "previousAmount",
          }}
        >
          Previous Late Fee
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "lateFeeChanges",
      title: "Late Fee Changes",
      width: 200,
    },
    {
      accessor: "amount",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LateFee",
            fieldName: "amount",
          }}
        >
          New Late Fee
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "createdBy",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LateFee",
            fieldName: "createdBy",
          }}
        >
          Changed By
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "documents",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LateFee",
            fieldName: "documents",
          }}
        >
          Documents
        </DatabaseMetadataPopover>
      ),
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => ({
      id: item.id,
      createdAt: toLocalDate(item.createdAt),
      invoiceNumber: item.invoice.invoiceNumber,
      paymentType: item.invoice.paymentType,
      paymentMethod: item.invoice.paymentMethod,
      previousAmount: toCurrencyPrice(item.previousAmount),
      lateFeeChanges: toCurrencyPrice(item.previousAmount - item.amount),
      amount: toCurrencyPrice(item.amount),
      createdBy: getUserDisplayName(item.createdBy),
      documents: <TableFilesDisplay files={item.documents ?? []} />,
    }));
  }, [data]);

  return [columns, rows];
};
