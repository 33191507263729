import { Center, Flex, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import Logo from "../../components/Icons/Logo.tsx";
import { AppRoute } from "../../constants";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";

export default function LoginAccessDeniedPage() {
  const { user } = useCurrentUserContext();
  const navigate = useNavigate();

  if (user) {
    navigate(AppRoute.Home);
  }

  return (
    <Center mx="auto" h="100vh">
      <Flex direction="column" gap={60} align="center" maw={395}>
        <Logo />
        <Text align="center">
          Sorry, you don’t have access to this service. Please request access to
          your manager.
        </Text>
      </Flex>
    </Center>
  );
}
