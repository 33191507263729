import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikContractHistorySummary } from "../../../../../types/clikResult.ts";
import { toPrice } from "../../../../../utils/number.ts";

interface Props {
  data: ClikContractHistorySummary | null | undefined;
}

export const useCRContractSummaryCardInfo = ({
  data,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    if (!data) {
      return [];
    }

    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractHistorySummary",
              fieldName: "contractsNumber",
            }}
            position="right"
          >
            Contracts number
          </DatabaseMetadataPopover>
        ),
        content: data.contractsNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractHistorySummary",
              fieldName: "reportingProvidersNumber",
            }}
            position="right"
          >
            Reporting Providers Number
          </DatabaseMetadataPopover>
        ),
        content: data.reportingProvidersNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractHistorySummary",
              fieldName: "totalDebitBalance",
            }}
            position="right"
          >
            Total Debit Balance
          </DatabaseMetadataPopover>
        ),
        content: data.totalDebitBalance && toPrice(data.totalDebitBalance),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractHistorySummary",
              fieldName: "totalOverdue",
            }}
            position="right"
          >
            Total Overdue
          </DatabaseMetadataPopover>
        ),
        content: data.totalOverdue && toPrice(data.totalOverdue),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractHistorySummary",
              fieldName: "totalCreditLimit",
            }}
            position="right"
          >
            Total Credit Limit
          </DatabaseMetadataPopover>
        ),
        content: data.totalCreditLimit && toPrice(data.totalCreditLimit),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractHistorySummary",
              fieldName: "totalPotentialExposure",
            }}
            position="right"
          >
            Total Potential Exposure
          </DatabaseMetadataPopover>
        ),
        content:
          data.totalPotentialExposure && toPrice(data.totalPotentialExposure),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractHistorySummary",
              fieldName: "currencyDesc",
            }}
            position="right"
          >
            Currency
          </DatabaseMetadataPopover>
        ),
        content: data.currencyDesc,
      },
    ];
  }, [data]);
};
