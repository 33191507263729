import { MantineTheme, rem } from "@mantine/core";

import { ThemeComponent } from "./helpers";

const filledGoldenActionIcon = (theme: MantineTheme) => ({
  root: {
    background: `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)`,
    color: theme.colors.white[0],
    "&:hover": {
      background: theme.colors.golden[0],
    },
    "&:focus": {
      background: `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)`,
      color: theme.colors.white[0],
    },
    "&:focus-within": {
      border: "none",
      background: `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)`,
      color: theme.colors.white[0],
    },
    "&:disabled": {
      color: theme.colors.white[0],
      background: theme.colors.grayIce[0],
    },
  },
});

const outlineGoldenActionIcon = (theme: MantineTheme) => ({
  root: {
    color: theme.colors.golden[0],
    background: "transparent",
    border: `1px solid ${theme.colors.golden[0]}`,
    "&:hover": {
      color: theme.colors.golden[0],
      border: `1px solid ${theme.colors.golden[0]}`,
      opacity: 0.5,
    },
    "&:focus": {
      color: theme.colors.golden[0],
      border: `1px solid ${theme.colors.golden[0]}`,
    },
    "&:disabled": {
      color: theme.colors.grayIce[0],
      border: `1px solid ${theme.colors.grayIce[0]}`,
      background: theme.colors.white[1],
    },
  },
});

export default function actionIconTheme(): ThemeComponent {
  return {
    variants: {
      filledGolden: filledGoldenActionIcon,
      outlineGolden: outlineGoldenActionIcon,
    },
    sizes: {
      m: () => ({
        root: {
          height: rem(44),
          width: rem(44),
          borderRadius: rem(4),
          flexShrink: 0,
        },
      }),
    },
  };
}
