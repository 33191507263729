import { useQuery } from "urql";

import {
  GetLateFeesDocument,
  LateFeeFilter,
  LateFeeOrderBy,
} from "../../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../../utils/api.ts";
import { ApiHookProps } from "../../types.ts";

export const useLateFees = ({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<LateFeeFilter, LateFeeOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetLateFeesDocument, variables, pause });
};
