import { Flex, SimpleGrid } from "@mantine/core";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SingleFieldCard from "../../../../components/Cards/SingleFieldCard/SingleFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikResult } from "../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import ClikResultAccountReceivables from "../ClikResultAccountReceivables/ClikResultAccountReceivables.tsx";
import ClikResultAdditionalInformation from "../ClikResultAdditionalInformation/ClikResultAdditionalInformation.tsx";
import ClikResultAddresses from "../ClikResultAddresses/ClikResultAddresses.tsx";
import ClikResultCbScore from "../ClikResultCbScore/ClikResultCbScore.tsx";
import ClikResultCompanyShareholder from "../ClikResultCompanyShareholder/ClikResultCompanyShareholder.tsx";
import ClikResultContacts from "../ClikResultContacts/ClikResultContacts.tsx";
import ClikResultBondContracts from "../ClikResultContractDetails/ClikResultBondContracts/ClikResultBondContracts.tsx";
import ClikResultCreditContracts from "../ClikResultContractDetails/ClikResultCreditContracts/ClikResultCreditContracts.tsx";
import ClikResultGuaranteeContracts from "../ClikResultContractDetails/ClikResultGuaranteeContracts/ClikResultGuaranteeContracts.tsx";
import ClikResultLetterOfCreditContracts from "../ClikResultContractDetails/ClikResultLetterOfCreditContracts/ClikResultLetterOfCreditContracts.tsx";
import ClikResultOtherFacilitiesContracts from "../ClikResultContractDetails/ClikResultOtherContracts/ClikResultOtherContracts.tsx";
import ClikResultContractSummary from "../ClikResultContractSummary/ClikResultContractSummary.tsx";
import ClikResultFootprint from "../ClikResultFootprint/ClikResultFootprint.tsx";
import ClikResultNegativeEvent from "../ClikResultNegativeEvent/ClikResultNegativeEvent.tsx";
import { getCRSubjectMatched } from "../utils.ts";
import { useCompanyResultInfoCards } from "./hooks/useCRCompanyClikResultInfoCards.tsx";

interface ClikResultCompanyProps {
  clikResult: ClikResult;
}

const ClikResultCompany = ({ clikResult }: ClikResultCompanyProps) => {
  const { subject, establishmentData, applicationSummary } =
    useCompanyResultInfoCards({
      clikResult,
    });

  return (
    <Flex gap={{ base: 20, sm: 16 }} direction="column">
      <Flex direction="column" gap={{ base: 20, sm: 16 }}>
        <SimpleGrid
          cols={3}
          breakpoints={[{ maxWidth: "lg", cols: 1 }]}
          spacing={20}
          verticalSpacing={16}
        >
          <SingleFieldCard
            label={
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikResult",
                  fieldName: "createdAt",
                }}
                position="right"
              >
                Request Date
              </DatabaseMetadataPopover>
            }
            value={clikResult.createdAt && toLocalDate(clikResult.createdAt)}
            variant="blue"
          />
          <SingleFieldCard
            label={
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikResult",
                  fieldName: "isSubjectMatched",
                }}
                position="right"
              >
                Status
              </DatabaseMetadataPopover>
            }
            value={getCRSubjectMatched(clikResult.isSubjectMatched)}
            variant="blue"
          />
          <SingleFieldCard
            label={
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikCompanyMatchedSubject",
                  fieldName: "businessIdentificationNumber",
                }}
                position="right"
              >
                Business Identity Number
              </DatabaseMetadataPopover>
            }
            value={clikResult.companySubject?.businessIdentificationNumber}
            variant="blue"
          />
        </SimpleGrid>
        <SimpleGrid
          cols={3}
          breakpoints={[{ maxWidth: "lg", cols: 1 }]}
          spacing={20}
          verticalSpacing={16}
        >
          <MultiFieldCard label="Subject Data" items={subject} variant="blue" />
          <MultiFieldCard
            label="Establishment Data"
            items={establishmentData}
            variant="blue"
          />
          <MultiFieldCard
            label="Application Summary"
            items={applicationSummary}
            variant="blue"
          />
        </SimpleGrid>
      </Flex>
      <ClikResultAddresses clikResultId={clikResult.id} />
      <ClikResultContacts clikResultId={clikResult.id} />
      <ClikResultCompanyShareholder companyId={clikResult.companySubject?.id} />
      <ClikResultCbScore clikResult={clikResult} />
      <ClikResultContractSummary clikResultId={clikResult.id} />
      <ClikResultCreditContracts clikResultId={clikResult.id} />
      <ClikResultBondContracts clikResultId={clikResult.id} />
      <ClikResultGuaranteeContracts clikResultId={clikResult.id} />
      <ClikResultLetterOfCreditContracts clikResultId={clikResult.id} />
      <ClikResultOtherFacilitiesContracts clikResultId={clikResult.id} />
      <ClikResultAccountReceivables clikResultId={clikResult.id} />
      <ClikResultAdditionalInformation
        additionalInfo={clikResult.companySubject?.additionalInfo}
      />
      <ClikResultNegativeEvent clikResultId={clikResult.id} />
      <ClikResultFootprint footprintsStatistic={clikResult} />
    </Flex>
  );
};

export default ClikResultCompany;
