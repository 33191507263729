import { useMemo } from "react";
import { Link } from "react-router-dom";

import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikContact } from "../../../../../types/clikContact.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { getCRCurrentFlagState } from "../../utils.ts";

interface Props {
  data: ClikContact[] | null | undefined;
}

export const useCRContactsCardsInfo = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  return useMemo(() => {
    if (!data) {
      return null;
    }

    return data?.map((item) => ({
      label: `${getCRCurrentFlagState(item.current)} - Contact`,
      variant: item.current ? "bronze" : "blue",
      items: [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContact",
                fieldName: "phoneNumber",
              }}
              position="right"
            >
              Phone Number
            </DatabaseMetadataPopover>
          ),
          content: item.phoneNumber && (
            <Link to={`tel:${item.phoneNumber}`}>{item.phoneNumber}</Link>
          ),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContact",
                fieldName: "cellphoneNumber",
              }}
              position="right"
            >
              Cell Phone Number
            </DatabaseMetadataPopover>
          ),
          content: item.cellphoneNumber && (
            <Link to={`tel:${item.cellphoneNumber}`}>
              {item.cellphoneNumber}
            </Link>
          ),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContact",
                fieldName: "emailAddress",
              }}
              position="right"
            >
              Email Address
            </DatabaseMetadataPopover>
          ),
          content: item.emailAddress && (
            <Link to={`mailto:${item.emailAddress}`}>
              {item.emailAddress.toLowerCase()}
            </Link>
          ),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContact",
                fieldName: "lastUpdatedDate",
              }}
              position="right"
            >
              Last Update Date
            </DatabaseMetadataPopover>
          ),
          content: item.lastUpdatedDate && toLocalDate(item.lastUpdatedDate),
        },
      ],
    }));
  }, [data]);
};
