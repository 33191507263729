import {
  Button,
  Flex,
  Input,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import { CreateWaiveInput, FileResult } from "../../../graphql/generated";
import { Invoice } from "../../../types/invoice/invoice.ts";
import { Waive } from "../../../types/waive.ts";
import { parseStringToNumber, priceFormatter } from "../../../utils/number.ts";
import Dropzone from "../../Dropzone/Dropzone";
import { invoiceIdOptions } from "../utils";

export interface WaiveAddModalProps {
  opened: boolean;
  invoices: Invoice[];
  onClose: () => void;
  onSubmit: (input: CreateWaiveInput) => void;
}

export default function WaiveAddModal({
  opened = false,
  invoices,
  onClose,
  onSubmit,
}: WaiveAddModalProps) {
  const form = useForm<Partial<Waive>>({
    validate: {
      invoiceId: isNotEmpty("Field is required"),
      amount: isNotEmpty("Field is required"),
      requestDocument: isNotEmpty('"Field is required"'),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    const input = {
      ...form.values,
      invoiceId: Number(form.values.invoiceId),
      requestDocument: form.values.requestDocument?.filePath,
    } as CreateWaiveInput;
    onSubmit(input);
    handleClose();
  }, [onSubmit, form.values, handleClose]);

  const handleFileUploaded = (files: FileResult[]) => {
    form.setValues({ requestDocument: files[0] });
  };

  const handleRemove = () => {
    form.setValues({ requestDocument: undefined });
  };

  return (
    <>
      <Modal
        title="Add Waive Request"
        opened={opened}
        scrollAreaComponent={ScrollArea.Autosize}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Select
            searchable
            required
            size="m"
            label="Invoice Number"
            placeholder="Select Invoice Number"
            data={invoiceIdOptions(invoices)}
            {...form.getInputProps("invoiceId")}
          />

          <NumberInput
            required
            precision={2}
            parser={parseStringToNumber}
            formatter={priceFormatter}
            hideControls
            label="Waive Amount"
            placeholder="Enter Waive Amount"
            size="m"
            {...form.getInputProps("amount")}
          />

          <Flex direction="column" gap={2}>
            <Input.Label required>Request Document</Input.Label>
            <Dropzone
              maxFiles={1}
              value={
                form.values.requestDocument
                  ? [form.values.requestDocument]
                  : null
              }
              onUploadSuccess={handleFileUploaded}
              onRemove={handleRemove}
            />
          </Flex>

          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              Add
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
