import { Flex } from "@mantine/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import { CustomerType } from "../../../../graphql/generated";
import { Customer } from "../../../../types/customer.ts";
import { getDisplayedAddress } from "../../../../utils/address.ts";
import { useStyles } from "./CompanyInfo.styles.ts";
import { addressTypesLabels } from "./constants.ts";

interface CompanyInfoProps {
  customer: Customer;
  loading?: boolean;
}

const CompanyInfo = ({ customer, loading }: CompanyInfoProps) => {
  const { classes } = useStyles();

  const customerAddress = getDisplayedAddress(customer);

  const info = [
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "website",
          }}
          position="right"
        >
          Website
        </DatabaseMetadataPopover>
      ),
      content: customer.website && (
        <Link to={customer.website} target="_blank">
          {customer.website}
        </Link>
      ),
    },
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesName",
          }}
          position="right"
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      content: customer.salesName,
    },
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesTeamName",
          }}
          position="right"
        >
          Sales Team
        </DatabaseMetadataPopover>
      ),
      content: customer.salesTeamName,
    },
  ];

  const picAddresses = useMemo(() => {
    const pics = customer?.pic ?? [];
    return pics
      .filter((item) => item.type && item.type !== CustomerType.Contact)
      .map((item) => {
        const address = getDisplayedAddress(item);
        return {
          label: addressTypesLabels[item.type as CustomerType],
          content: address,
        };
      });
  }, [customer?.pic]);

  const contactAddress = [
    {
      label: "Address",
      content: customerAddress,
    },
  ];

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle>Company Info</SectionTitle>
      <Flex gap={20} className={classes.companyInfoWrapper}>
        <MultiFieldCard
          label="Info"
          variant="blue"
          items={info}
          loading={loading}
        />
        <MultiFieldCard
          label="Address"
          variant="blue"
          items={[...contactAddress, ...picAddresses]}
          loading={loading}
        />
      </Flex>
    </Flex>
  );
};

export default CompanyInfo;
