import { Select } from "@mantine/core";
import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import {
  CustomerProblemCategory,
  RolePermission,
  UpdateCustomerInput,
} from "../../../../graphql/generated";
import { useCurrentUserContext } from "../../../../providers/CurrentUserProvider.tsx";
import { Customer } from "../../../../types/customer.ts";
import { CustomerCollectionSummary } from "../../../../types/customerCollectionSummary.ts";
import { UserOption } from "../../../../types/user.ts";
import { toLocalNumber } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import {
  getUserDisplayName,
  getUserOptions,
  hasPermission,
} from "../../../../utils/user.ts";

interface Props {
  customer: Partial<Customer>;
  collectionSummary: Partial<CustomerCollectionSummary>;
  collectors: UserOption[];
  onChange: (value: Partial<UpdateCustomerInput>) => void;
}

export const useCollectionSummaryInfo = ({
  collectionSummary,
  customer,
  collectors,
  onChange,
}: Props) => {
  const { user } = useCurrentUserContext();

  const canEditCollectorAssigned = hasPermission(
    user,
    RolePermission.CanAssignCustomerCollector
  );

  const canEditProblemCategory = hasPermission(
    user,
    RolePermission.CanEditCustomer
  );

  const problemsCategories = Object.values(CustomerProblemCategory).map(
    (item) => ({
      value: item,
      label: toHumanReadable(item),
    })
  );

  return useMemo(() => {
    return [
      {
        label: "OSP Accumulated",
        content:
          collectionSummary.ospAccumulated &&
          toLocalNumber(collectionSummary.ospAccumulated),
      },
      {
        label: "Late Charge Accumulated",
        content:
          collectionSummary.lateChargeAccumulated &&
          toLocalNumber(collectionSummary.lateChargeAccumulated),
      },
      {
        label: "AR Accumulated",
        content:
          collectionSummary.arAccumulated &&
          toLocalNumber(collectionSummary.arAccumulated),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "Customer",
              fieldName: "collectorAssigned",
            }}
            position="right"
          >
            Collector Assigned
          </DatabaseMetadataPopover>
        ),
        isColumn: true,
        content: canEditCollectorAssigned ? (
          <Select
            data={getUserOptions(collectors)}
            value={
              customer.collectorAssigned
                ? String(customer.collectorAssigned.id)
                : null
            }
            placeholder="Select Collector"
            size="s"
            withinPortal
            zIndex={100}
            w="100%"
            onChange={(value) =>
              onChange({ collectorAssignedId: Number(value) })
            }
          />
        ) : (
          getUserDisplayName(customer.collectorAssigned)
        ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "Customer",
              fieldName: "problemCategory",
            }}
            position="right"
          >
            Problem Category
          </DatabaseMetadataPopover>
        ),
        isColumn: !!customer.problemCategory || !!canEditProblemCategory,
        content: canEditProblemCategory ? (
          <Select
            data={problemsCategories}
            value={customer.problemCategory}
            placeholder="Select Problem Category"
            size="s"
            withinPortal
            zIndex={100}
            w="100%"
            onChange={(value: CustomerProblemCategory) =>
              onChange({ problemCategory: value })
            }
          />
        ) : customer.problemCategory ? (
          toHumanReadable(customer.problemCategory)
        ) : null,
      },
      {
        label: "is Restructured",
        content: collectionSummary.isRestructured ? "YES" : "NO",
      },
    ];
  }, [
    collectionSummary,
    customer,
    collectors,
    problemsCategories,
    canEditProblemCategory,
    canEditCollectorAssigned,
    onChange,
  ]);
};
