import { Button } from "@mantine/core";
import { isEmpty } from "lodash";
import { DataTableColumn } from "mantine-datatable";
import React, { useCallback, useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgSnowflake from "../../../../../components/Icons/Snowflake.tsx";
import TableNoteList from "../../../../../components/NoteList/TableNoteList.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import {
  LimitStatus,
  RolePermission,
} from "../../../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../../../providers/CurrentUserProvider.tsx";
import { Limit } from "../../../../../types/limit.ts";
import { toCurrencyPrice, toPercent } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";
import { hasPermission } from "../../../../../utils/user.ts";

interface Props {
  data: Limit[] | null | undefined;
  onFreezeModalOpen: (value: Limit) => void;
}

export function useLimitTableData({
  data,
  onFreezeModalOpen,
}: Props): TableData {
  const { user: currentUser } = useCurrentUserContext();

  const canEditLimitRequest = hasPermission(
    currentUser,
    RolePermission.CanEditLimitRequest
  );

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "limitType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "limitType",
          }}
        >
          Limit Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "limitName",
          }}
        >
          Limit Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "status",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "status",
          }}
          position="bottom"
        >
          Limit Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "totalLimit",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "totalLimit",
          }}
        >
          Total Limit
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "totalPaid",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "totalPaid",
          }}
        >
          Limit Utilized
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "remaining",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "remaining",
          }}
        >
          Limit Remaining
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "tenorApproved",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "tenor",
          }}
        >
          Tenor Approved
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "pricingApproved",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitDetail",
            fieldName: "pricingApproved",
          }}
        >
          Pricing Approved
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "freezeStatus",
      title: "Freeze Status",
    },
    {
      accessor: "freezeNotes",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Limit",
            fieldName: "freezeNotes",
          }}
        >
          Notes
        </DatabaseMetadataPopover>
      ),
      width: 350,
    },
  ];

  const handleOpenFreezeModal = useCallback(
    (event: React.MouseEvent<HTMLElement>, input: Limit) => {
      //  stop event propagation (won't trigger parent's onClick)
      event.stopPropagation();
      onFreezeModalOpen(input);
    },
    [onFreezeModalOpen]
  );

  const renderFreezeStatusButton = useCallback(
    (item: Limit) => {
      if (item.status === LimitStatus.Freeze) {
        return (
          <Button
            variant="outlineBlue"
            size="s"
            uppercase
            disabled={!canEditLimitRequest || !item.canUnfreeze}
            onClick={(e) => handleOpenFreezeModal(e, item)}
          >
            Unfreeze
          </Button>
        );
      }
      return (
        <Button
          variant="filledBlue"
          leftIcon={<SvgSnowflake />}
          size="s"
          uppercase
          disabled={!canEditLimitRequest || item.status !== LimitStatus.Active}
          onClick={(e) => handleOpenFreezeModal(e, item)}
        >
          Freeze
        </Button>
      );
    },
    [canEditLimitRequest, handleOpenFreezeModal]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      const row: TableRow = {
        id: item.id,
        status: toHumanReadable(item.status),
        limitType: toHumanReadable(item.limitType),
        limitName: item.limitName,
        totalLimit: toCurrencyPrice(item.totalLimit),
        totalPaid: toCurrencyPrice(item.totalPaid),
        remaining: toCurrencyPrice(item.remaining),
        tenorApproved: item.tenor,
        pricingApproved: toPercent(
          item.limitDetail?.pricingApproved ?? 0,
          "percentage"
        ),
        freezeStatus: renderFreezeStatusButton(item),
        freezeNotes: !isEmpty(item.freezeNotes) && (
          <TableNoteList notes={item.freezeNotes} />
        ),
      };
      return row;
    });
  }, [data, renderFreezeStatusButton]);

  return [columns, rows];
}
