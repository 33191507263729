import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  root: {
    zIndex: 1000,
  },
  itemLabel: {
    fontSize: rem(14),
    lineHeight: 1.5,
    textTransform: "capitalize",
  },
  itemContent: {
    fontSize: rem(14),
    lineHeight: 1.3,
  },
}));
