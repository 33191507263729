import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../../components/Table/types";
import { LimitRequest } from "../../../../types/limitRequest.ts";
import { toLocalDate } from "../../../../utils/date";
import { toLocalNumber } from "../../../../utils/number";
import { toHumanReadable } from "../../../../utils/string";
import { getUserDisplayName } from "../../../../utils/user.ts";

interface Props {
  data: LimitRequest[] | null | undefined;
}

export const useOngoingCUTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "cuAssignedDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "cuAssignedDate",
          }}
        >
          CU Assigned Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitName",
          }}
        >
          Limit Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "createdAt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "createdAt",
          }}
        >
          Limit Request Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "companyName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "name",
          }}
        >
          Company Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "salesName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesName",
          }}
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesTeamName",
          }}
        >
          Sales Team
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },

    {
      accessor: "limitRequestAmount",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitRequestAmount",
          }}
        >
          Limit Request
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "tenorRequest",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "tenorRequest",
          }}
        >
          Tenor Request
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitRequestType",
          }}
        >
          Limit Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "cuAssignedId",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "cuAssignedId",
          }}
        >
          CU Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "requestStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "requestStatus",
          }}
        >
          CU Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        cuAssignedDate: toLocalDate(item.cuAssignedDate),
        limitName: item.limitName,
        createdAt: toLocalDate(item.createdAt),
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeamName: item.company.salesTeamName,
        limitRequestAmount: toLocalNumber(item.limitRequestAmount),
        tenorRequest: toLocalNumber(item.tenorRequest),
        limitType:
          item?.limitRequestType && toHumanReadable(item.limitRequestType),
        cuAssignedId: item?.cuAssigned && getUserDisplayName(item.cuAssigned),
        requestStatus: toHumanReadable(item.requestStatus),
      };
    });
  }, [data]);

  return [columns, rows];
};
