import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikResult } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";

interface Props {
  clikResult: ClikResult;
}

export interface CRIndividualInfo {
  subject: MultiFieldCardItem[];
  indentificationDocuments: MultiFieldCardItem[];
  applicationSummary: MultiFieldCardItem[];
}

export const useCRIndividualResultInfoCards = ({
  clikResult,
}: Props): CRIndividualInfo => {
  const subject = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "cbSubjectCode",
            }}
            position="right"
          >
            CB Subject Code
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.individualSubject?.cbSubjectCode,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "providerSubjectNumber",
            }}
            position="right"
          >
            Provider Subject No
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.individualSubject?.providerSubjectNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "lastUpdatedDate",
            }}
            position="right"
          >
            Last Update Date
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.individualSubject?.lastUpdatedDate &&
          toLocalDate(clikResult.individualSubject.lastUpdatedDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "resident",
            }}
            position="right"
          >
            Resident
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.individualSubject?.resident &&
          toHumanReadable(clikResult.individualSubject.resident),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "nameAsId",
            }}
            position="right"
          >
            Name As Id
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.individualSubject?.nameAsId,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "fullName",
            }}
            position="right"
          >
            Full Name
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.individualSubject?.fullName,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "mothersName",
            }}
            position="right"
          >
            Mother&apos;s Name
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.individualSubject?.mothersName,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "gender",
            }}
            position="right"
          >
            Gender
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.individualSubject?.gender &&
          toHumanReadable(clikResult.individualSubject.gender),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "dateOfBirth",
            }}
            position="right"
          >
            Date of Birth
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.individualSubject?.dateOfBirth &&
          toLocalDate(clikResult.individualSubject.dateOfBirth),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "placeOfBirth",
            }}
            position="right"
          >
            Place of Birth
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.individualSubject?.placeOfBirth,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "maritalStatus",
            }}
            position="right"
          >
            Marital Status
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.individualSubject?.maritalStatus,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "educationStatus",
            }}
            position="right"
          >
            Educational Status
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.individualSubject?.educationStatus,
      },
    ];
  }, [clikResult]);

  const indentificationDocuments = useMemo(() => {
    const documentsList =
      clikResult?.individualSubject?.identities.map((item) => ({
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIdentification",
              fieldName: "number",
            }}
            position="right"
          >
            {item.type}
          </DatabaseMetadataPopover>
        ),
        content: item.number,
      })) ?? [];

    return [
      ...documentsList,
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikIndividualMatchedSubject",
              fieldName: "npwpId",
            }}
            position="right"
          >
            NPWP/Tax Registration Number
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.individualSubject?.npwpId,
      },
    ];
  }, [clikResult]);

  const applicationSummary = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "cbContractCode",
            }}
            position="right"
          >
            CB Contract Code
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.cbContractCode,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "contractType",
            }}
            position="right"
          >
            Contract Type
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.contractType &&
          toHumanReadable(clikResult.applicationSummary.contractType),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "contractPhase",
            }}
            position="right"
          >
            Contract Phase
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.contractPhase &&
          toHumanReadable(clikResult.applicationSummary.contractPhase),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "requestDate",
            }}
            position="right"
          >
            Request Date
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.requestDate &&
          toLocalDate(clikResult.applicationSummary.requestDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "amount",
            }}
            position="right"
          >
            Application Amount
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.amount &&
          toCurrencyPrice(clikResult.applicationSummary.amount),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "dueDate",
            }}
            position="right"
          >
            Due Date
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.dueDate &&
          toLocalDate(clikResult.applicationSummary.dueDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "role",
            }}
            position="right"
          >
            Role
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.role,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "companyRole",
            }}
            position="right"
          >
            Company Role
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.companyRole,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "accountNumber",
            }}
            position="right"
          >
            Account Number
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.accountNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "originalAgreementNumber",
            }}
            position="right"
          >
            Original Agreement Number
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.originalAgreementNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "originalAgreementDate",
            }}
            position="right"
          >
            Original Agreement Date
          </DatabaseMetadataPopover>
        ),
        content:
          clikResult?.applicationSummary?.originalAgreementDate &&
          toLocalDate(clikResult.applicationSummary.originalAgreementDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikApplicationSummary",
              fieldName: "currency",
            }}
            position="right"
          >
            Currency
          </DatabaseMetadataPopover>
        ),
        content: clikResult?.applicationSummary?.currency,
      },
    ];
  }, [clikResult]);

  return { subject, indentificationDocuments, applicationSummary };
};
