import { useMemo } from "react";
import { Link } from "react-router-dom";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import TableFilesDisplay from "../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { OfferLetter } from "../../../../types/offerLetter.ts";
import { booleanToString } from "../../../../utils/boolean.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface Props {
  offeringLetter: OfferLetter | null | undefined;
}

export const useOfferingLetterCardData = ({ offeringLetter }: Props) => {
  return useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "OfferLetter",
              fieldName: "olNo",
            }}
            position="right"
          >
            OL No
          </DatabaseMetadataPopover>
        ),
        content: offeringLetter?.olNo,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "OfferLetter",
              fieldName: "olSignedDate",
            }}
            position="right"
          >
            OL signing date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(offeringLetter?.olSignedDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "OfferLetter",
              fieldName: "olSigned",
            }}
            position="right"
          >
            Signed OL
          </DatabaseMetadataPopover>
        ),
        content: offeringLetter?.olSigned && (
          <Link
            to={offeringLetter.olSigned.url}
            target="_blank"
            rel="noreferrer"
          >
            Download
          </Link>
        ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "OfferLetter",
              fieldName: "pksSigned",
            }}
            position="right"
          >
            Signed PKS
          </DatabaseMetadataPopover>
        ),
        content:
          offeringLetter?.pksSigned?.length &&
          (offeringLetter.pksSigned.length > 1 ? (
            <TableFilesDisplay files={offeringLetter.pksSigned} />
          ) : (
            <Link
              to={offeringLetter.pksSigned[0].url}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </Link>
          )),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "OfferLetter",
              fieldName: "pgSigned",
            }}
            position="right"
          >
            Signed PG
          </DatabaseMetadataPopover>
        ),
        content:
          offeringLetter?.pgSigned?.length &&
          (offeringLetter.pgSigned.length > 1 ? (
            <TableFilesDisplay files={offeringLetter.pgSigned} />
          ) : (
            <Link
              to={offeringLetter.pgSigned[0].url}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </Link>
          )),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "OfferLetter",
              fieldName: "olExpiredDate",
            }}
            position="right"
          >
            OL expired date
          </DatabaseMetadataPopover>
        ),
        content: toLocalDate(offeringLetter?.olExpiredDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "OfferLetter",
              fieldName: "olStatus",
            }}
            position="right"
          >
            OL Status
          </DatabaseMetadataPopover>
        ),
        content: toHumanReadable(offeringLetter?.olStatus),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "OfferLetter",
              fieldName: "giroRegis",
            }}
            position="right"
          >
            GIRO Regis
          </DatabaseMetadataPopover>
        ),
        content: booleanToString(offeringLetter?.giroRegis),
      },
    ];
  }, [offeringLetter]);
};
