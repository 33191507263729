import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import {
  ClikContractMaximum,
  ContractType,
} from "../../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";

interface Props {
  data: ClikContractMaximum | null | undefined;
  type: ContractType;
}

export const useCRMaximumDetailsOfContract = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "maxOverduePaymentsAmount",
              }}
              position="right"
            >
              Max Overdue Payments Amount
            </DatabaseMetadataPopover>
          ),
          content:
            data?.maxOverduePaymentsAmount &&
            toCurrencyPrice(data.maxOverduePaymentsAmount),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "maxOverduePaymentsNumber",
              }}
              position="right"
            >
              Max Overdue Payments Number
            </DatabaseMetadataPopover>
          ),
          content: data?.maxOverduePaymentsNumber,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "maxOverduePaymentsNumberDate",
              }}
              position="right"
            >
              Max Overdue Payments Number Date
            </DatabaseMetadataPopover>
          ),
          content:
            data?.maxOverduePaymentsNumberDate &&
            toLocalDate(data?.maxOverduePaymentsNumberDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "maxDaysPastDue",
              }}
              position="right"
            >
              Max Days Past Due
            </DatabaseMetadataPopover>
          ),
          content: data?.maxDaysPastDue,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "maxDaysPastDueDate",
              }}
              position="right"
            >
              Max Days Past Due Date
            </DatabaseMetadataPopover>
          ),
          content:
            data?.maxDaysPastDueDate && toLocalDate(data?.maxDaysPastDueDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "worstStatus",
              }}
              position="right"
            >
              Worst Status
            </DatabaseMetadataPopover>
          ),
          content: data?.worstStatus,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "worstStatusDate",
              }}
              position="right"
            >
              Worst Status Date
            </DatabaseMetadataPopover>
          ),
          content: data?.worstStatusDate && toLocalDate(data.worstStatusDate),
        },
      ];
    }

    if (type === ContractType.Bond || type === ContractType.OtherFacilities) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "maxOverduePaymentsAmount",
              }}
              position="right"
            >
              Max Overdue Payments Amount
            </DatabaseMetadataPopover>
          ),
          content:
            data?.maxOverduePaymentsAmount &&
            toCurrencyPrice(data.maxOverduePaymentsAmount),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "maxDaysPastDue",
              }}
              position="right"
            >
              Max Days Past Due
            </DatabaseMetadataPopover>
          ),
          content: data?.maxDaysPastDue,
        },
        {
          label: "Max Days Past Due Date",
          content: null,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "worstStatus",
              }}
              position="right"
            >
              Worst Status
            </DatabaseMetadataPopover>
          ),
          content: data?.worstStatus,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "worstStatusDate",
              }}
              position="right"
            >
              Worst Status Date
            </DatabaseMetadataPopover>
          ),
          content: data?.worstStatusDate && toLocalDate(data.worstStatusDate),
        },
      ];
    }

    if (
      type === ContractType.Guarantee ||
      type === ContractType.LetterOfCredit
    ) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "worstStatus",
              }}
              position="right"
            >
              Worst Status
            </DatabaseMetadataPopover>
          ),
          content: data?.worstStatus,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractMaximum",
                fieldName: "worstStatusDate",
              }}
              position="right"
            >
              Worst Status Date
            </DatabaseMetadataPopover>
          ),
          content: data?.worstStatusDate && toLocalDate(data.worstStatusDate),
        },
      ];
    }

    return [];
  }, [data, type]);
};
