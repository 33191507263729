import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikContractCollateral } from "../../../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../../../utils/date.ts";
import {
  toCurrencyPrice,
  toLocalNumber,
} from "../../../../../../../utils/number.ts";

interface Props {
  data: ClikContractCollateral;
}

interface CRCollateral {
  grantedCollateral: MultiFieldCardItem[];
  mainCollateralValue: MultiFieldCardItem[];
}

export const useCRCollateral = ({ data }: Props): CRCollateral => {
  const grantedCollateral = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "collateralNumber",
            }}
            position="right"
          >
            Collateral Number
          </DatabaseMetadataPopover>
        ),
        content: data?.collateralNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "collateralStatus",
            }}
            position="right"
          >
            Collateral Status
          </DatabaseMetadataPopover>
        ),
        content: data?.collateralStatus,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "collateralRating",
            }}
            position="right"
          >
            Rating
          </DatabaseMetadataPopover>
        ),
        content: data?.collateralRating,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "ratingInstitution",
            }}
            position="right"
          >
            Rating Institution
          </DatabaseMetadataPopover>
        ),
        content: data?.ratingInstitution,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "bindType",
            }}
            position="right"
          >
            Bind Type
          </DatabaseMetadataPopover>
        ),
        content: data?.bindType,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "bindDate",
            }}
            position="right"
          >
            Bind Date
          </DatabaseMetadataPopover>
        ),
        content: data?.bindDate && toLocalDate(data.bindDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "collateralOwnerName",
            }}
            position="right"
          >
            Collateral Owner Name
          </DatabaseMetadataPopover>
        ),
        content: data?.collateralOwnerName,
      },

      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "proofOfOwnership",
            }}
            position="right"
          >
            Proof of Ownership
          </DatabaseMetadataPopover>
        ),
        content: data?.proofOfOwnership,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "commonCollateralStatus",
            }}
            position="right"
          >
            Common Collateral Status
          </DatabaseMetadataPopover>
        ),
        content: data?.commonCollateralStatus,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "commonCollateralPercentage",
            }}
            position="right"
          >
            Common Collateral Percentage
          </DatabaseMetadataPopover>
        ),
        content:
          data?.commonCollateralPercentage &&
          `${toLocalNumber(data.commonCollateralPercentage)}%`,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "jointAccountCreditStatus",
            }}
            position="right"
          >
            Joint Account Credit Status
          </DatabaseMetadataPopover>
        ),
        content: data?.jointAccountCreditStatus,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "insured",
            }}
            position="right"
          >
            Insured
          </DatabaseMetadataPopover>
        ),
        content: data?.insured,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "information",
            }}
            position="right"
          >
            Information
          </DatabaseMetadataPopover>
        ),
        content: data?.information,
      },
    ];
  }, [data]);

  const mainCollateralValue = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "collateralValueNJOP",
            }}
            position="right"
          >
            Collateral Value NJO
          </DatabaseMetadataPopover>
        ),
        content:
          data?.collateralValueNJOP &&
          toCurrencyPrice(data.collateralValueNJOP),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "collateralValueReporter",
            }}
            position="right"
          >
            Collateral Value Reporter
          </DatabaseMetadataPopover>
        ),
        content:
          data?.collateralValueReporter &&
          toCurrencyPrice(data.collateralValueReporter),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "collateralAssessmentDateReporter",
            }}
            position="right"
          >
            Assessment Date Reporter
          </DatabaseMetadataPopover>
        ),
        content:
          data?.collateralAssessmentDateReporter &&
          toLocalDate(data.collateralAssessmentDateReporter),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "collateralValueIndependentAssessors",
            }}
            position="right"
          >
            Collateral Value Indipendent Assersors
          </DatabaseMetadataPopover>
        ),
        content: data?.collateralValueIndependentAssessors,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "independentAssersorsName",
            }}
            position="right"
          >
            Independent Assessors Name
          </DatabaseMetadataPopover>
        ),
        content: data?.independentAssersorsName,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "assessmentDateIndependentAssersors",
            }}
            position="right"
          >
            Assessment Date Indipendent Assersors
          </DatabaseMetadataPopover>
        ),
        content: data?.assessmentDateIndependentAssersors,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "providerTypeCode",
            }}
            position="right"
          >
            Provider Type
          </DatabaseMetadataPopover>
        ),
        content: data?.providerTypeCode,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "providerCode",
            }}
            position="right"
          >
            Provider
          </DatabaseMetadataPopover>
        ),
        content: data?.providerCode,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "referenceDate",
            }}
            position="right"
          >
            Last Update Date
          </DatabaseMetadataPopover>
        ),
        content: data?.referenceDate && toLocalDate(data.referenceDate),
      },
    ];
  }, [data]);

  return { grantedCollateral, mainCollateralValue };
};
