import { ReactElement } from "react";

import { RolePermission } from "../../graphql/generated.ts";
import Page404 from "../../pages/404/Page404.tsx";
import { useCurrentUserContext } from "../../providers/CurrentUserProvider.tsx";
import { hasPermission } from "../../utils/user.ts";
import Preloader from "../Preloader/Preloader.tsx";

interface Props {
  children: ReactElement;
  permission: RolePermission;
}

export const HasAccessGuard = ({ children, permission }: Props) => {
  const { fetching, user } = useCurrentUserContext();

  const hasAccess = hasPermission(user, permission);

  if (fetching) {
    return <Preloader loading={true} />;
  }

  if (!hasAccess) {
    return <Page404 />;
  }

  return children;
};
