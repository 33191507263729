import { Badge, Select } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import React, { useCallback, useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import TableFilesDisplay from "../../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import {
  LimitRequestType,
  LimitStatus,
  LimitTopProductCategory,
  RolePermission,
  UpdateLimitRequestInlineInput,
} from "../../../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../../../providers/CurrentUserProvider.tsx";
import {
  LimitRequest,
  topProductCategoryOptions,
} from "../../../../../types/limitRequest.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toCurrencyPrice, toPercent } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";
import { hasPermission } from "../../../../../utils/user.ts";

interface Props {
  data: LimitRequest[] | null | undefined;
  onEdit: (id: number, value: UpdateLimitRequestInlineInput) => void;
}

export function useLimitRequestTableData({ data, onEdit }: Props): TableData {
  const { user: currentUser } = useCurrentUserContext();

  const hasEditLimitRequest = hasPermission(
    currentUser,
    RolePermission.CanEditLimitRequest
  );

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "limitName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitName",
          }}
        >
          Limit Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "createdAt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "createdAt",
          }}
        >
          Limit Request Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitRequestType",
          }}
        >
          Limit Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 160,
    },
    {
      accessor: "topProductCategory",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "topProductCategory",
          }}
        >
          TOP Product Category
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitRequestAmount",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitRequestAmount",
          }}
        >
          Limit Request Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "tenorRequest",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "tenorRequest",
          }}
        >
          Tenor Request
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "requestStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "requestStatus",
          }}
        >
          Request Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitApproved",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitApproved",
          }}
        >
          Limit Approved
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "tenorApproved",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "tenorApproved",
          }}
        >
          Tenor Approved
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "pricingApproved",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "pricingApproved",
          }}
        >
          Pricing Approved
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitActiveDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitActiveDate",
          }}
        >
          Limit Active/Reject Date
        </DatabaseMetadataPopover>
      ),
    },
    {
      accessor: "limitExpiryDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitExpiryDate",
          }}
        >
          Limit Expiry Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitDetail",
            fieldName: "status",
          }}
        >
          Limit Status
        </DatabaseMetadataPopover>
      ),
    },
    {
      accessor: "olNo",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "OfferLetter",
            fieldName: "olNo",
          }}
        >
          OL Number
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "olExpiredDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "OfferLetter",
            fieldName: "olExpiredDate",
          }}
        >
          OL Expired Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "olFile",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "OfferLetter",
            fieldName: "olSigned",
          }}
        >
          OL File
        </DatabaseMetadataPopover>
      ),
    },
    {
      accessor: "pgFile",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "OfferLetter",
            fieldName: "pgSigned",
          }}
        >
          PG File
        </DatabaseMetadataPopover>
      ),
    },
    {
      accessor: "pksFile",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "OfferLetter",
            fieldName: "pksSigned",
          }}
        >
          PKS File
        </DatabaseMetadataPopover>
      ),
    },
  ];

  const renderLimitStatus = useCallback((item: LimitRequest) => {
    if (!item.limitDetail?.status) {
      return null;
    }

    let backgroundColor = "yellow";
    if (item.limitDetail.status === LimitStatus.Active) {
      backgroundColor = "green";
    } else if (
      [
        LimitStatus.Reject,
        LimitStatus.Inactive,
        LimitStatus.Expired,
        LimitStatus.Freeze,
      ].includes(item.limitDetail.status)
    ) {
      backgroundColor = "red";
    } else if (item.limitDetail.status === LimitStatus.NewRequest) {
      backgroundColor = "grey";
    }
    return (
      <Badge color={backgroundColor} size="sm" radius="xs">
        {toHumanReadable(item.limitDetail.status)}
      </Badge>
    );
  }, []);

  const renderTopUpCategory = useCallback(
    (item: LimitRequest) => {
      if (item.limitRequestType === LimitRequestType.NewProjectLimit) {
        return toHumanReadable(LimitTopProductCategory.ProjectLimit);
      }
      if (item.limitRequestType === LimitRequestType.TopUpLimit) {
        return toHumanReadable(LimitTopProductCategory.TopUpLimit);
      }
      return (
        <Select
          data={topProductCategoryOptions}
          value={item.topProductCategory}
          withinPortal
          zIndex={100}
          size="s"
          disabled={!hasEditLimitRequest}
          onChange={(value: LimitTopProductCategory) =>
            onEdit(item.id, { topProductCategory: value })
          }
          onClick={(event) => handleOnClick(event)}
        />
      );
    },
    [hasEditLimitRequest, onEdit]
  );

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    //  stop event propagation (won't trigger parent's onClick)
    event.stopPropagation();
  };

  const rows = useMemo(() => {
    return data?.map((item) => {
      const row: TableRow = {
        id: item.id,
        limitName: item.limitName,
        createdAt: toLocalDate(item.createdAt),
        limitRequestAmount: toCurrencyPrice(item.limitRequestAmount),
        tenorRequest: item.tenorRequest,
        limitType: toHumanReadable(item.limitRequestType),
        topProductCategory: renderTopUpCategory(item),
        requestStatus: toHumanReadable(item.requestStatus),
        limitApproved: toCurrencyPrice(item.limitApproved),
        tenorApproved: item.tenorApproved,
        pricingApproved: toPercent(item.pricingApproved ?? 0, "percentage"),
        limitActiveDate: toLocalDate(item.limitActiveDate),
        limitExpiryDate: toLocalDate(item.limitExpiryDate),
        limitStatus: renderLimitStatus(item),
        olNo: item.offerLetter?.olNo,
        olExpiredDate: toLocalDate(item.offerLetter?.olExpiredDate),
        olFile: item.offerLetter?.olSigned && (
          <TableFilesDisplay files={[item.offerLetter.olSigned]} />
        ),
        pgFile: item.offerLetter?.pgSigned && (
          <TableFilesDisplay files={item.offerLetter.pgSigned} />
        ),
        pksFile: item.offerLetter?.pksSigned && (
          <TableFilesDisplay files={item.offerLetter.pksSigned} />
        ),
      };
      return row;
    });
  }, [data, renderLimitStatus, renderTopUpCategory]);

  return [columns, rows];
}
