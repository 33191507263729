import { CombinedError } from "@urql/core";
import { createContext, ReactNode, useContext } from "react";
import { UseQueryExecute } from "urql";

import { useCurrentUser } from "../hooks/api/user/useCurrentUser.ts";
import { User } from "../types/user.ts";

interface ContextProps {
  user: User | undefined | null;
  fetching: boolean;
  error: CombinedError | undefined | null;
  fetchCurrentUser: UseQueryExecute | null;
}

export const CurrentUserContext = createContext<ContextProps>({
  user: null,
  fetching: false,
  error: null,
  fetchCurrentUser: null,
});

interface Props {
  children: ReactNode;
}

export const CurrentUserProvider = ({ children }: Props) => {
  const [currentUserData, fetchCurrentUser] = useCurrentUser();
  return (
    <CurrentUserContext.Provider
      value={{
        user: currentUserData.data?.me,
        fetching: currentUserData.fetching,
        error: currentUserData.error,
        fetchCurrentUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUserContext = () => useContext(CurrentUserContext);
