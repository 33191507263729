import {
  ActionIcon,
  Burger,
  Divider,
  Flex,
  Header as MantineHeader,
  MediaQuery,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Clock from "react-live-clock";

import { useXRayContext } from "../../../providers/XRayProvider.tsx";
import { User } from "../../../types/user.ts";
import { DATE_FORMAT, TIMEZONE } from "../../../utils/date.ts";
import { toHumanReadable } from "../../../utils/string.ts";
import SvgCrossedEye from "../../Icons/CrossedEye.tsx";
import HeaderLogo from "../../Icons/HeaderLogo.tsx";
import SvgVisibility from "../../Icons/Visibility.tsx";
import MobileMenu from "../MobileMenu/MobileMenu.tsx";
import { useStyles } from "./Header.styles.ts";

interface HeaderProps {
  user: User;
}

const Header = ({ user }: HeaderProps) => {
  const { classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const { xrayModeEnabled, setXrayModeEnabled, hasXrayModeAccess } =
    useXRayContext();

  return (
    <>
      <MantineHeader height={60} zIndex={1000}>
        <Flex
          justify="space-between"
          align="center"
          className={classes.wrapper}
        >
          <HeaderLogo />
          <MediaQuery largerThan="52em" styles={{ display: "none" }}>
            <Burger opened={opened} onClick={toggle} color="white" />
          </MediaQuery>
          <MediaQuery smallerThan="52em" styles={{ display: "none" }}>
            <Flex gap={12} align="center" className={classes.info}>
              <Text>Welcome, {user.name}!</Text>
              <Divider orientation="vertical" className={classes.divider} />
              <Text className={classes.role}>{toHumanReadable(user.role)}</Text>
              {hasXrayModeAccess && (
                <>
                  <Divider orientation="vertical" className={classes.divider} />
                  <Tooltip
                    label={
                      xrayModeEnabled
                        ? "Disable X-Ray feature"
                        : "Enable X-Ray feature"
                    }
                  >
                    <ActionIcon
                      size="md"
                      variant="outlineGolden"
                      onClick={() => {
                        setXrayModeEnabled(!xrayModeEnabled);
                      }}
                    >
                      {xrayModeEnabled ? <SvgCrossedEye /> : <SvgVisibility />}
                    </ActionIcon>
                  </Tooltip>
                </>
              )}
              <Divider orientation="vertical" className={classes.divider} />
              <Clock format={DATE_FORMAT} ticking={true} timezone={TIMEZONE} />
            </Flex>
          </MediaQuery>
        </Flex>
      </MantineHeader>
      {opened && <MobileMenu onClose={toggle} />}
    </>
  );
};

export default Header;
