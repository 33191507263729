import {
  Flex,
  MediaQuery,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Tabs,
  Text,
} from "@mantine/core";
import { useLayoutEffect, useRef, useState } from "react";

import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import { QueryDashboardCustomersByLongestDpdArgs } from "../../../graphql/generated";
import { useDashboardCustomersByLongestDpd } from "../../../hooks/api/dashboard/useDashboardCustomersByLongestDpd.ts";
import { dpdBucketOptions } from "../../../types/invoice/invoice.ts";
import { noDataText } from "./constants.ts";
import { useStyles } from "./CustomersLongestDPD.styles.ts";

interface Props {
  filter: QueryDashboardCustomersByLongestDpdArgs["salesTeamName"];
}

const CustomersLongestDPD = ({ filter }: Props) => {
  const { classes } = useStyles();

  const [width, setWidth] = useState(0);

  const panelRef = useRef<HTMLDivElement>(null);

  const [{ data, fetching }] = useDashboardCustomersByLongestDpd({
    salesTeamName: filter,
  });

  useLayoutEffect(() => {
    panelRef?.current?.offsetWidth &&
      setWidth(panelRef.current.offsetWidth - 270);
  }, []);

  const getCustomersColumnsCount = (customers: Array<string>) =>
    Math.ceil(customers.length / 10);

  const renderCustomerList = (customers: string[]) => {
    if (customers.length) {
      return (
        <ScrollArea type="auto" className={classes.scrollArea} w={width}>
          <SimpleGrid
            className={classes.customersWrapper}
            cols={getCustomersColumnsCount(customers)}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            spacing={12}
            verticalSpacing={16}
          >
            {customers.map((customer, index) => (
              <Flex key={index} className={classes.customer}>
                {customer}
              </Flex>
            ))}
          </SimpleGrid>
        </ScrollArea>
      );
    }
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        className={classes.empty}
      >
        <Text>{noDataText}</Text>
      </Flex>
    );
  };

  return (
    <Flex direction="column" gap={12} ref={panelRef}>
      <SectionTitle variant="bronze">
        List of Customers based on Longest DPD Bucket
      </SectionTitle>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Skeleton visible={fetching} mih={394}>
          <Tabs
            defaultValue={dpdBucketOptions[0].value}
            variant="outline"
            orientation="vertical"
          >
            <Tabs.List className={classes.tabList}>
              {data?.dashboardCustomersByLongestDpd.map((item, index) => (
                <Tabs.Tab
                  key={index}
                  className={classes.tab}
                  value={dpdBucketOptions[index].value}
                >
                  <Flex justify="space-between" w="100%">
                    <Text>{dpdBucketOptions[index].label}</Text>
                    <Text>{item.customers.length}</Text>
                  </Flex>
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {data?.dashboardCustomersByLongestDpd.map((item, index) => {
              return (
                <Tabs.Panel
                  key={index}
                  className={classes.panel}
                  value={item.dpd}
                >
                  {renderCustomerList(item.customers)}
                </Tabs.Panel>
              );
            })}
          </Tabs>
        </Skeleton>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Skeleton visible={fetching}>
          <Tabs defaultValue={dpdBucketOptions[0].value}>
            <Tabs.List className={classes.tabList}>
              {data?.dashboardCustomersByLongestDpd.map((item, index) => (
                <Tabs.Tab key={index} value={dpdBucketOptions[index].value}>
                  <Flex justify="space-between" w="100%">
                    <Text>{dpdBucketOptions[index].label}</Text>
                    <Text>{item.customers.length}</Text>
                  </Flex>
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {data?.dashboardCustomersByLongestDpd.map((item, index) => (
              <Tabs.Panel
                key={index}
                value={item.dpd}
                className={classes.panel}
              >
                {renderCustomerList(item.customers)}
              </Tabs.Panel>
            ))}
          </Tabs>
        </Skeleton>
      </MediaQuery>
    </Flex>
  );
};

export default CustomersLongestDPD;
