import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikContractCommonData } from "../../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";

interface Props {
  data: ClikContractCommonData | null | undefined;
  information?: string | null;
}

interface CRContractDetailInfo {
  main: MultiFieldCardItem[];
}

export const useCRContractDetailsCardsInfo = ({
  data,
  information,
}: Props): CRContractDetailInfo => {
  const main = useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "providerType",
            }}
            position="right"
          >
            Provider Type
          </DatabaseMetadataPopover>
        ),
        content: data?.providerType,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "providerCode",
            }}
            position="right"
          >
            Provider
          </DatabaseMetadataPopover>
        ),
        content: data?.providerCode,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "cbContractCode",
            }}
            position="right"
          >
            CB Contract Code
          </DatabaseMetadataPopover>
        ),
        content: data?.cbContractCode,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "providerContractNumber",
            }}
            position="right"
          >
            Provider Contract No.
          </DatabaseMetadataPopover>
        ),
        content: data?.providerContractNumber,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "role",
            }}
            position="right"
          >
            Role
          </DatabaseMetadataPopover>
        ),
        content: data?.role,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "referenceDate",
            }}
            position="right"
          >
            Last Update Date
          </DatabaseMetadataPopover>
        ),
        content: data?.referenceDate && toLocalDate(data?.referenceDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "startDate",
            }}
            position="right"
          >
            Start Date
          </DatabaseMetadataPopover>
        ),
        content: data?.startDate && toLocalDate(data.startDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "dueDate",
            }}
            position="right"
          >
            Due Date
          </DatabaseMetadataPopover>
        ),
        content: data?.dueDate && toLocalDate(data.dueDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "pastDueStaus",
            }}
            position="right"
          >
            Past Due Status
          </DatabaseMetadataPopover>
        ),
        content: data?.pastDueStaus,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "contractStatus",
            }}
            position="right"
          >
            Contract Status
          </DatabaseMetadataPopover>
        ),
        content: data?.contractStatus,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "contractStatusDate",
            }}
            position="right"
          >
            Contract Status Date
          </DatabaseMetadataPopover>
        ),
        content:
          data?.contractStatusDate && toLocalDate(data.contractStatusDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCommonData",
              fieldName: "information",
            }}
            position="right"
          >
            Information
          </DatabaseMetadataPopover>
        ),
        content: information,
      },
    ];
  }, [data, information]);

  return { main };
};
