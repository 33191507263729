import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
}
export const useCRRestructuringDetailsOfContract = ({
  data,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikGrantedContractDetails",
              fieldName: "restructuringPlan",
            }}
            position="right"
          >
            Restructuring Plan
          </DatabaseMetadataPopover>
        ),
        content: data?.restructuringPlan && toLocalDate(data.restructuringPlan),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikGrantedContractDetails",
              fieldName: "initialRestructuringDate",
            }}
            position="right"
          >
            Initial Restructuring Date
          </DatabaseMetadataPopover>
        ),
        content:
          data?.initialRestructuringDate &&
          toLocalDate(data.initialRestructuringDate),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikGrantedContractDetails",
              fieldName: "finalRestructuringDate",
            }}
            position="right"
          >
            Final Restructuring Date
          </DatabaseMetadataPopover>
        ),
        content: data?.finalRestructuringDate && data.finalRestructuringDate,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikGrantedContractDetails",
              fieldName: "frequencyOfRestructuring",
            }}
            position="right"
          >
            Frequency of Restructuring
          </DatabaseMetadataPopover>
        ),
        content: data?.frequencyOfRestructuring,
      },
    ];
  }, [data]);
};
