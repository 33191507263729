import { useMemo } from "react";

import { MultiFieldCardProps } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import {
  ClikContractGuarantee,
  ClikIdentification,
} from "../../../../../../graphql/generated.ts";

interface Props {
  data: ClikContractGuarantee[] | null | undefined;
}
export const useCRGuarantorsDetails = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  return useMemo(() => {
    if (!data) {
      return null;
    }
    const getGuarantorIdentities = (identity?: ClikIdentification | null) => {
      if (!identity) {
        return [];
      }

      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikIdentification",
                fieldName: "type",
              }}
              position="right"
            >
              Identity Type
            </DatabaseMetadataPopover>
          ),
          content: identity.type,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikIdentification",
                fieldName: "number",
              }}
              position="right"
            >
              Identity Number
            </DatabaseMetadataPopover>
          ),
          content: identity.number,
        },
      ];
    };

    return data.map((item, index) => {
      return {
        label: `Detail of Guarantor ${index + 1}: ${item.guarantorType}`,
        items: [
          {
            label: (
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikContractGuarantee",
                  fieldName: "guarantorNameAsID",
                }}
                position="right"
              >
                Guarantor Name as ID
              </DatabaseMetadataPopover>
            ),
            content: item.guarantorNameAsID,
          },
          {
            label: (
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikContractGuarantee",
                  fieldName: "guarantorFullName",
                }}
                position="right"
              >
                Guarantor Full Name
              </DatabaseMetadataPopover>
            ),
            content: item?.guarantorFullName,
          },
          {
            label: (
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikContractGuarantee",
                  fieldName: "percentageGuaranteed",
                }}
                position="right"
              >
                Percentage Guaranteed
              </DatabaseMetadataPopover>
            ),
            content: item?.percentageGuaranteed,
          },
          {
            label: (
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikContractGuarantee",
                  fieldName: "information",
                }}
                position="right"
              >
                Information
              </DatabaseMetadataPopover>
            ),
            content: item?.information,
          },
          {
            label: (
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikContractGuarantee",
                  fieldName: "address",
                }}
                position="right"
              >
                Address
              </DatabaseMetadataPopover>
            ),
            content: item?.address,
          },
          {
            label: (
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikContractGuarantee",
                  fieldName: "providerType",
                }}
                position="right"
              >
                Provider Type
              </DatabaseMetadataPopover>
            ),
            content: item?.providerType,
          },
          {
            label: (
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikContractGuarantee",
                  fieldName: "providerCode",
                }}
                position="right"
              >
                Provider
              </DatabaseMetadataPopover>
            ),
            content: item?.providerCode,
          },
          ...getGuarantorIdentities(item?.identity),
        ],
      };
    });
  }, [data]);
};
