import { isEmpty, pickBy } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";

import { LateFeeOrderBy } from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";

export const getLateFeeApiSort = (
  tableSort: DataTableSortStatus
): LateFeeOrderBy => {
  const { invoiceNumber, paymentType, paymentMethod, createdBy, ...apiSort } =
    getApiOrderBy(tableSort);
  const invoiceSort = pickBy({
    invoiceNumber,
    paymentType,
    paymentMethod,
  });
  return {
    ...apiSort,
    invoice: isEmpty(invoiceSort) ? undefined : invoiceSort,
    createdBy: createdBy ? { name: createdBy } : undefined,
  };
};
