import { useMemo } from "react";

import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikCompanyShareholder } from "../../../../../graphql/generated.ts";
import { getCRDisplayAddress } from "../../utils.ts";

interface Props {
  data: ClikCompanyShareholder[] | null | undefined;
}

export const useCRCompanyShareholderCardsInfo = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  return useMemo(() => {
    if (!data) {
      return null;
    }

    return data?.map((item, index) => ({
      label: `ShareHolder Data  ${index + 1}: ${item.name.toUpperCase()}`,
      variant: "bronze",
      items: [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikCompanyShareholder",
                fieldName: "gender",
              }}
              position="right"
            >
              Gender
            </DatabaseMetadataPopover>
          ),
          content: item.gender,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikCompanyShareholder",
                fieldName: "share",
              }}
              position="right"
            >
              Share of Ownership
            </DatabaseMetadataPopover>
          ),
          content: item.share,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikCompanyShareholder",
                fieldName: "position",
              }}
              position="right"
            >
              Position
            </DatabaseMetadataPopover>
          ),
          content: item.position,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikCompanyShareholder",
                fieldName: "mandateStatus",
              }}
              position="right"
            >
              Mandate Status
            </DatabaseMetadataPopover>
          ),
          content: item.mandateStatus,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikIdentification",
                fieldName: "type",
              }}
              position="right"
            >
              Identity Type
            </DatabaseMetadataPopover>
          ),
          content: item.indetification?.type,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikIdentification",
                fieldName: "number",
              }}
              position="right"
            >
              Identity Number
            </DatabaseMetadataPopover>
          ),
          content: item.indetification?.number,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikCompanyShareholder",
                fieldName: "address",
              }}
              position="right"
            >
              Address
            </DatabaseMetadataPopover>
          ),
          content: getCRDisplayAddress(item.address),
        },
      ],
    }));
  }, [data]);
};
