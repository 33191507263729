import { SimpleGrid, Skeleton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { sortBy } from "lodash";
import { useState } from "react";

import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import AddPengurusButton from "../../../components/Pengurus/AddPengurusButton/AddPengurusButton.tsx";
import PengurusFormModal from "../../../components/Pengurus/PengurusFormModal/PengurusFormModal.tsx";
import {
  ClikCity,
  ClikCountry,
  PengurusStatus,
  RolePermission,
} from "../../../graphql/generated.ts";
import { useCreatePengurus } from "../../../hooks/api/pengurus/useCreatePengurus.ts";
import { usePenguruses } from "../../../hooks/api/pengurus/usePenguruses.ts";
import { useUpdatePengurus } from "../../../hooks/api/pengurus/useUpdatePengurus.ts";
import { useUpdatePengurusStatus } from "../../../hooks/api/pengurus/useUpdatePengurusStatus.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import { RawPengurus } from "../../../types/pengurus.ts";
import { hasPermission } from "../../../utils/user.ts";
import { usePengurusesCardInfo } from "./hooks/usePengurusCardInfo.tsx";
import {
  formatPengurusToAddPengurusInput,
  formatPengurusToUpdatePengurusInput,
} from "./utils.ts";

interface PengurusInfoProps {
  limitRequestId: number | null | undefined;
  loading: boolean;
  cities: ClikCity[] | null | undefined;
  countries: ClikCountry[] | null | undefined;
}

const PengurusInfo = ({
  loading,
  cities,
  countries,
  limitRequestId,
}: PengurusInfoProps) => {
  const [selectedPengurus, setSelectedPengurus] = useState<{
    index: number;
    data: RawPengurus;
  } | null>(null);

  const [
    { data: pengurusesData, fetching: pengurusesFetching },
    refreshPenguruses,
  ] = usePenguruses({ limitRequestId });

  const { user: currentUser } = useCurrentUserContext();

  const [, addPengurusToLimitRequest] = useMutationNotificationWrapper(
    useCreatePengurus(),
    {
      success: {
        message: "Pengurus has been added successfully.",
      },
    }
  );

  const [, updatePengurus] = useMutationNotificationWrapper(
    useUpdatePengurus(),
    {
      success: {
        message: "Pengurus has been updated successfully.",
      },
    }
  );

  const [, updatePengurusStatus] = useMutationNotificationWrapper(
    useUpdatePengurusStatus(),
    {
      success: {
        message: "Pengurus Status has been updated successfully.",
      },
    }
  );

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditBuyerInfoPengurusInfo
  );

  const penguruses = sortBy(pengurusesData?.penguruses, "id");

  const [
    pengurusModalOpened,
    { open: pengurusModalOpen, close: pengurusModalClose },
  ] = useDisclosure(false);

  const handleStartEditPengurus = (value: RawPengurus, index: number) => {
    setSelectedPengurus({ index, data: value });
    pengurusModalOpen();
  };

  const handleChangeStatus = (id: number, value: PengurusStatus) => {
    updatePengurusStatus({ id, input: { status: value } }).then(() => {
      refreshPenguruses();
    });
  };

  const handleAddPengurus = (value: RawPengurus) => {
    if (!value) {
      return;
    }

    const input = formatPengurusToAddPengurusInput(
      Number(limitRequestId),
      value
    );
    if (!input) {
      return;
    }

    addPengurusToLimitRequest({ input }).then(() => refreshPenguruses());
  };

  const handlePengurusModalClose = () => {
    setSelectedPengurus(null);
    pengurusModalClose();
  };

  const handleEditPengurus = (value: RawPengurus) => {
    if (!selectedPengurus || !value || !value.id) {
      return;
    }

    const input = formatPengurusToUpdatePengurusInput(value);

    if (!input) {
      return;
    }

    updatePengurus({ id: Number(value.id), input }).then(() =>
      refreshPenguruses()
    );
  };

  const handleSavePengurus = (value: RawPengurus) => {
    if (selectedPengurus) {
      handleEditPengurus(value);
    } else {
      handleAddPengurus(value);
    }
  };

  const pengurusesInfoCards = usePengurusesCardInfo({
    penguruses: penguruses,
    onEdit: handleStartEditPengurus,
    onStatusChange: handleChangeStatus,
  });

  return (
    <>
      <SimpleGrid
        cols={1}
        verticalSpacing={16}
        breakpoints={[
          { minWidth: "sm", cols: 2, verticalSpacing: 20 },
          { minWidth: "lg", cols: 3, verticalSpacing: 20 },
        ]}
        spacing={20}
      >
        {hasEditAccess && <AddPengurusButton onClick={pengurusModalOpen} />}
        {pengurusesInfoCards?.map((item, index) => (
          <MultiFieldCard
            key={index}
            {...item}
            loading={loading || pengurusesFetching}
          />
        ))}
        {pengurusesFetching && (
          <>
            <Skeleton w="100%" h="100%" />
            <Skeleton w="100%" h="100%" />
          </>
        )}
      </SimpleGrid>
      {pengurusModalOpened && (
        <PengurusFormModal
          value={selectedPengurus?.data ?? null}
          disabledFields={selectedPengurus && ["name"]}
          cities={cities}
          countries={countries}
          opened={true}
          onClose={handlePengurusModalClose}
          onSubmit={handleSavePengurus}
        />
      )}
    </>
  );
};

export default PengurusInfo;
