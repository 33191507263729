import { Flex } from "@mantine/core";
import { useMemo } from "react";

import MultiFieldCard from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SingleFieldCard from "../../../../../../components/Cards/SingleFieldCard/SingleFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import Title from "../../../../../../components/Title/Title.tsx";
import { ClikContractCollateral } from "../../../../../../graphql/generated.ts";
import { useCRCollateral } from "./hooks/useCRCollateral.tsx";

interface ClikResultCollateralProps {
  data: ClikContractCollateral;
  number: number;
  loading?: boolean;
}
const ClikResultCollateral = ({
  data,
  number,
  loading,
}: ClikResultCollateralProps) => {
  const { grantedCollateral, mainCollateralValue } = useCRCollateral({ data });

  const collacteralAddress = useMemo(() => {
    if (data.address && data.city) {
      return `${data.address}, ${data.city}`;
    }
    return data.address;
  }, [data.address, data.city]);

  return (
    <Flex direction="column" gap={20}>
      <Title size="h2">{`Detail of Collaterals ${number}: ${data.collateralType}`}</Title>
      <MultiFieldCard
        items={grantedCollateral}
        variant="blue"
        cols={2}
        label="Granted Collateral"
        loading={loading}
      />
      <MultiFieldCard
        items={mainCollateralValue}
        variant="blue"
        cols={2}
        label="Collateral Value"
        loading={loading}
      />
      <SingleFieldCard
        variant="blue"
        label={
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikContractCollateral",
              fieldName: "address",
            }}
            position="right"
          >
            Collateral Address
          </DatabaseMetadataPopover>
        }
        loading={loading}
        value={collacteralAddress}
      />
    </Flex>
  );
};

export default ClikResultCollateral;
