import {
  Button,
  Flex,
  MediaQuery,
  MultiSelect,
  SimpleGrid,
} from "@mantine/core";
import { SelectItem } from "@mantine/core/lib/Select/types";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useCallback, useMemo } from "react";

import SvgCalendar from "../../../components/Icons/Calendar.tsx";
import SvgUnfold from "../../../components/Icons/Unfold.tsx";
import Title from "../../../components/Title/Title.tsx";
import { CustomerOption } from "../../../types/customer.ts";
import {
  dpdBucketOptions,
  isRestructuredOptions,
  paymentMethodOptions,
  paymentStatusOptions,
  paymentTypeOptions,
} from "../../../types/invoice/invoice.ts";
import { UserOption } from "../../../types/user.ts";
import { getFiltersCount } from "../../../utils/filter.ts";
import { getUserOptions } from "../../../utils/user.ts";
import { useStyles } from "./ActivityManagementToolbar.styles.ts";
import { FiltersValue } from "./types.ts";

interface ActivityManagementToolbarProps {
  collectors: UserOption[] | null | undefined;
  customers: CustomerOption[] | null | undefined;
  onValueChange: (value: Partial<FiltersValue>) => void;
}

const ActivityManagementToolbar = ({
  collectors,
  customers,
  onValueChange,
}: ActivityManagementToolbarProps) => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      dpdBucket: [],
      companyName: [],
      paymentStatus: [],
      paymentMethod: [],
      paymentType: [],
      isRestructured: [],
      collector: [],
      invoiceDueDate: undefined,
      lastPaymentDate: undefined,
    },
  });

  const customersOptions: SelectItem[] = useMemo(() => {
    return (
      customers?.map((customer) => ({
        value: String(customer.id),
        label: customer.name,
      })) ?? []
    );
  }, [customers]);

  const collectorsOptions: SelectItem[] = useMemo(() => {
    return getUserOptions(collectors);
  }, [collectors]);

  const isFilterApplied = useMemo(
    () => !!getFiltersCount(form.values),
    [form.values]
  );

  const handleValueChange = useCallback(
    (value: Record<string, unknown>) => {
      form.setValues(value);
      onValueChange({ ...form.values, ...value });
    },
    [form, onValueChange]
  );

  const handleCleanFilters = useCallback(() => {
    form.reset();
    onValueChange({});
  }, [form, onValueChange]);

  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Flex direction="column" gap={16} className={classes.wrapper}>
          <Flex justify="space-between">
            <Title>Activity Management</Title>
            {isFilterApplied && (
              <Button
                variant="outlineGolden"
                uppercase
                onClick={handleCleanFilters}
              >
                clear filters
              </Button>
            )}
          </Flex>
          <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
            <MultiSelect
              size="m"
              data={dpdBucketOptions}
              label="DPD Bucket"
              placeholder="Select DPD Bucket"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("dpdBucket")}
              onChange={(dpdBucket) => handleValueChange({ dpdBucket })}
            />
            <MultiSelect
              size="m"
              data={customersOptions}
              label="Company Name"
              placeholder="Select Company Name"
              searchable
              nothingFound="No results found"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("companyName")}
              onChange={(companyName) => handleValueChange({ companyName })}
            />
            <MultiSelect
              size="m"
              data={collectorsOptions}
              label="Collector"
              placeholder="Select Collector"
              searchable
              nothingFound="No results found"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("collector")}
              onChange={(collector) => handleValueChange({ collector })}
            />
            <MultiSelect
              size="m"
              data={paymentStatusOptions}
              label="Payment Status"
              placeholder="Select Payment Status"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("paymentStatus")}
              onChange={(paymentStatus) => handleValueChange({ paymentStatus })}
            />
            <MultiSelect
              size="m"
              data={paymentMethodOptions}
              label="Payment Method"
              placeholder="Select Payment Method"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("paymentMethod")}
              onChange={(paymentMethod) => handleValueChange({ paymentMethod })}
            />
            <MultiSelect
              size="m"
              data={paymentTypeOptions}
              label="Payment Type"
              placeholder="Select Payment Type"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("paymentType")}
              onChange={(paymentType) => handleValueChange({ paymentType })}
            />
            <DatePickerInput
              label="Invoice Due Date"
              placeholder="Select Invoice Due Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              type="range"
              size="m"
              clearable
              {...form.getInputProps("invoiceDueDate")}
              onChange={(invoiceDueDate) =>
                handleValueChange({ invoiceDueDate })
              }
            />
            <DatePickerInput
              label="Last Payment Date"
              placeholder="Select Last Payment Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              type="range"
              size="m"
              clearable
              {...form.getInputProps("lastPaymentDate")}
              onChange={(lastPaymentDate) =>
                handleValueChange({ lastPaymentDate })
              }
            />
            <MultiSelect
              size="m"
              data={isRestructuredOptions}
              label="Is Restructured"
              placeholder="Select Is Restructured"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("isRestructured")}
              onChange={(isRestructured) =>
                handleValueChange({ isRestructured })
              }
            />
          </SimpleGrid>
        </Flex>
      </MediaQuery>
    </>
  );
};

export default ActivityManagementToolbar;
