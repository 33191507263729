import { Flex, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import React, { useCallback, useMemo } from "react";

import { RestructureApprovalStatus } from "../../../graphql/generated";
import { Restructure } from "../../../types/restructure";
import { toLocalDate } from "../../../utils/date.ts";
import { toHumanReadable } from "../../../utils/string";
import { getUserDisplayName } from "../../../utils/user.ts";
import ActionIcon from "../../ActionIcon/ActionIcon";
import { DatabaseMetadataPopover } from "../../DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgEdit from "../../Icons/Edit";
import InfoTooltip from "../../InfoTooltip/InfoTooltip.tsx";
import DisableButton from "../../Table/DisableButton.tsx";
import { TableData, TableRow } from "../../Table/types.ts";
import TableFilesDisplay from "../../TableFilesDisplay/TableFilesDisplay.tsx";
import { useStyles } from "../TableRestructure.styles.ts";

interface Props {
  data: Restructure[] | null | undefined;
  canEdit?: boolean;
  onEdit?: (id: number) => void;
}

export function useTableRestructureData({
  data,
  canEdit,
  onEdit,
}: Props): TableData {
  const { classes } = useStyles();

  const handleEdit = useCallback(
    (event: React.MouseEvent<HTMLElement>, id: number) => {
      event.stopPropagation();
      onEdit && onEdit(id);
    },
    [onEdit]
  );

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "createdAt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Restructure",
            fieldName: "createdAt",
          }}
        >
          Created Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "requestNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Restructure",
            fieldName: "requestNumber",
          }}
        >
          Restructure Number
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "approvalStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Restructure",
            fieldName: "approvalStatus",
          }}
        >
          Approval Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "approvalDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Restructure",
            fieldName: "approvalDate",
          }}
        >
          Approval Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "collectorAssigned",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Restructure",
            fieldName: "collectorAssigned",
          }}
        >
          Collector Assigned
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
    {
      accessor: "restructureDraftDocument",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Restructure",
            fieldName: "restructureDraftDocument",
          }}
        >
          <Flex align="center">
            <Text>Restructure Draft Document</Text>
            <InfoTooltip tooltipText="Restructure draft document will be generated on Restructure approval" />
          </Flex>
        </DatabaseMetadataPopover>
      ),
      width: 250,
    },
    {
      accessor: "signedDocument",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Restructure",
            fieldName: "signedDocument",
          }}
        >
          Signed Document
        </DatabaseMetadataPopover>
      ),
    },
    {
      accessor: "actions",
      title: "Actions",
      hidden: !canEdit,
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      const row: TableRow = {
        id: item.id,
        createdAt: toLocalDate(item.createdAt),
        requestNumber: item.requestNumber,
        approvalStatus: toHumanReadable(item.approvalStatus),
        approvalDate: toLocalDate(item.approvalDate),
        collectorAssigned: item.collectorAssigned && (
          <Text className={classes.collectorAssignedWrapper}>
            {getUserDisplayName(item.collectorAssigned)}
          </Text>
        ),
        restructureDraftDocument: item.restructureDraftDocument && (
          <TableFilesDisplay files={[item.restructureDraftDocument]} />
        ),
        signedDocument: item.signedDocument && (
          <TableFilesDisplay files={[item.signedDocument]} />
        ),
        actions:
          item.approvalStatus !== RestructureApprovalStatus.Pending ? (
            <DisableButton
              text={`Editing is not allowed, restructure status is ${toHumanReadable(
                item.approvalStatus
              )}`}
            />
          ) : (
            <div
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                handleEdit(event, item.id)
              }
            >
              <ActionIcon>
                <SvgEdit />
              </ActionIcon>
            </div>
          ),
      };
      return row;
    });
  }, [data, classes, handleEdit]);

  return [columns, rows];
}
