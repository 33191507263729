import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikFootprintStatistic } from "../../../../../types/clikResult.ts";

interface Props {
  data: ClikFootprintStatistic | null | undefined;
}

export const useCRFootprintStatisticCardsInfo = ({
  data,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikResult",
              fieldName: "footprintsCountOneMonth",
            }}
            position="right"
          >
            1 Month
          </DatabaseMetadataPopover>
        ),
        content: data?.footprintsCountOneMonth,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikResult",
              fieldName: "footprintsCountThreeMonth",
            }}
            position="right"
          >
            3 Months
          </DatabaseMetadataPopover>
        ),
        content: data?.footprintsCountThreeMonth,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikResult",
              fieldName: "footprintsCountSixMonth",
            }}
            position="right"
          >
            6 Months
          </DatabaseMetadataPopover>
        ),
        content: data?.footprintsCountSixMonth,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "ClikResult",
              fieldName: "footprintsCountTwelveMonth",
            }}
            position="right"
          >
            12 Months
          </DatabaseMetadataPopover>
        ),
        content: data?.footprintsCountTwelveMonth,
      },
    ];
  }, [data]);
};
