import { Divider, Flex, ScrollArea } from "@mantine/core";

import SingleFieldCard from "../../../../components/Cards/SingleFieldCard/SingleFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import NoDataMessage from "../../../../components/NoDataMessage/NoDataMessage.tsx";
import RiskGradeItem from "../../../../components/RiskGradeItem/RiskGradeItem.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import { ClikResult } from "../../../../types/clikResult.ts";
import { getCbScoreRisk } from "../utils.ts";
import { useStyles } from "./ClikResultCbScore.styles.ts";
import { cbScoreItems } from "./constants.ts";

interface CbScopeProps {
  clikResult: ClikResult;
}

const ClikResultCbScore = ({ clikResult }: CbScopeProps) => {
  const { classes } = useStyles({
    bgColor: cbScoreItems.find((item) => item.value === clikResult.cbScoreRisk)
      ?.color,
  });

  if (clikResult.cbScoreExclusion) {
    return (
      <Flex direction="column" gap={16}>
        <SectionTitle variant="bronze">CB Score</SectionTitle>
        <Flex gap={20} align="center">
          <Flex>
            It has not been possible to calculate the score.{" "}
            {clikResult.cbScoreExclusion}.
          </Flex>
        </Flex>
      </Flex>
    );
  }

  if (!clikResult.cbScore || !clikResult.cbScoreRisk) {
    return (
      <Flex direction="column" gap={16}>
        <SectionTitle variant="bronze">CB Score</SectionTitle>
        <NoDataMessage />
      </Flex>
    );
  }

  const getRiskGrade = () => (
    <ScrollArea type="auto">
      <Flex gap={20} align="center">
        <Flex gap={12} align="center">
          <Flex className={classes.risk}>
            {getCbScoreRisk(clikResult.cbScoreRisk)}
          </Flex>
          <Flex className={classes.riskDescription}>
            {clikResult.cbScoreRiskDescription}
          </Flex>
        </Flex>
        <Divider orientation="vertical" />
        <Flex gap={12}>
          {cbScoreItems.map((item, index) => (
            <RiskGradeItem
              key={index}
              color={item.color}
              value={item.value}
              active={clikResult.cbScoreRisk?.includes(item.value)}
            />
          ))}
        </Flex>
      </Flex>
    </ScrollArea>
  );

  return (
    <Flex direction="column" gap={16}>
      <SectionTitle variant="bronze">CB Score</SectionTitle>
      <Flex gap={20} align="center">
        <Flex w="33.3%">
          <SingleFieldCard
            label={
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikResult",
                  fieldName: "cbScore",
                }}
                position="right"
              >
                Score
              </DatabaseMetadataPopover>
            }
            value={clikResult.cbScore}
          />
        </Flex>
        <Flex w="76.7%">
          <SingleFieldCard
            label={
              <DatabaseMetadataPopover
                databaseMetadata={{
                  tableName: "ClikResult",
                  fieldName: "cbScoreRisk",
                }}
                position="right"
              >
                Risk Grade
              </DatabaseMetadataPopover>
            }
            value={getRiskGrade()}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ClikResultCbScore;
