import { Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import {
  TableData,
  TableRow,
} from "../../../../../../components/Table/types.ts";
import { ClikContractProfile } from "../../../../../../graphql/generated.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";
import { useStyles } from "../../../ClikResultDetails.styles.tsx";
import { getReferenceDate } from "../utils.ts";

interface Props {
  data: ClikContractProfile[] | null | undefined;
}

export const useCRHistoricalData = ({ data }: Props): TableData => {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "referenceDate",
      title: "Reference Date",
      sortable: false,
      width: "150px",
    },
    {
      accessor: "daysPastDue",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikContractProfile",
            fieldName: "daysPastDue",
          }}
        >
          Days Past Due
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "overdue",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikContractProfile",
            fieldName: "overdue",
          }}
        >
          Overdue
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "badCreditCause",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikContractProfile",
            fieldName: "badCreditCause",
          }}
        >
          Bad Credit Cause
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "debitBalance",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikContractProfile",
            fieldName: "debitBalance",
          }}
        >
          Debit Balance
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "currentMonthRealization",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikContractProfile",
            fieldName: "currentMonthRealization",
          }}
        >
          Current Month Realization
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikContractProfile",
            fieldName: "contractStatus",
          }}
        >
          Contract Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        referenceDate: (
          <Text className={classes.referenceDateWrapper}>
            {getReferenceDate(item.referenceYear, item.referenceMonth)}
          </Text>
        ),
        daysPastDue: item.daysPastDue,
        overdue: item.overdue && toCurrencyPrice(item.overdue),
        badCreditCause: item.badCreditCause,
        debitBalance: item.debitBalance && toCurrencyPrice(item.debitBalance),
        currentMonthRealization:
          item.currentMonthRealization &&
          toCurrencyPrice(item.currentMonthRealization),
        contractStatus: item.contractStatus,
      };
    });
  }, [data, classes]);

  return [columns, rows];
};
