import { useMemo } from "react";
import { Link } from "react-router-dom";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { DirectorInfo } from "../../../../types/directorInfo.ts";

interface Props {
  data: DirectorInfo | null | undefined;
}

export const useCustomerDirectorInfoCard = ({ data }: Props) => {
  return useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "DirectorInfo",
              fieldName: "directorName",
            }}
            position="right"
          >
            Director Name
          </DatabaseMetadataPopover>
        ),
        content: data?.directorName,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "DirectorInfo",
              fieldName: "directorTitle",
            }}
            position="right"
          >
            Director Title
          </DatabaseMetadataPopover>
        ),
        content: data?.directorTitle,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "DirectorInfo",
              fieldName: "directorNik",
            }}
            position="right"
          >
            Director NIK
          </DatabaseMetadataPopover>
        ),
        content: data?.directorNik,
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "DirectorInfo",
              fieldName: "directorPhone",
            }}
            position="right"
          >
            Director Phone No
          </DatabaseMetadataPopover>
        ),
        content: data?.directorPhone && (
          <Link to={"tel:" + data.directorPhone} target="_blank">
            {data.directorPhone}
          </Link>
        ),
      },
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "DirectorInfo",
              fieldName: "directorEmail",
            }}
            position="right"
          >
            Director Email
          </DatabaseMetadataPopover>
        ),
        content: data?.directorEmail && (
          <Link to={"mailto:" + data.directorEmail} target="_blank">
            {data.directorEmail}
          </Link>
        ),
      },
    ];
  }, [data]);
};
