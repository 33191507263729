import { SimpleGrid } from "@mantine/core";
import { Link } from "react-router-dom";

import ActionIcon from "../../../../components/ActionIcon/ActionIcon.tsx";
import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgDownload from "../../../../components/Icons/Download";
import {
  Invoice,
  toHumanReadablePaymentType,
} from "../../../../types/invoice/invoice.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice, toLocalNumber } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../utils/user.ts";
import { useStyles } from "./InvoiceDetailsCards.styles.ts";

interface InvoiceDetailsCardsProps {
  invoice: Invoice;
}

const InvoiceDetailsCards = ({ invoice }: InvoiceDetailsCardsProps) => {
  const { classes } = useStyles();

  const transactionInformation = [
    {
      label: "BAST Document",
      content: invoice.bastDocument && (
        <Link to={invoice.bastDocument.url} target="_blank">
          <ActionIcon size={16} spaces={false}>
            <SvgDownload />
          </ActionIcon>
        </Link>
      ),
    },
    {
      label: "Inv Document",
      content: invoice.invoiceDocument && (
        <Link to={invoice.invoiceDocument.url} target="_blank">
          <ActionIcon size={16} spaces={false}>
            <SvgDownload />
          </ActionIcon>
        </Link>
      ),
    },
    {
      label: "BAST Date",
      content: invoice.bastDate && toLocalDate(invoice.bastDate),
    },
    {
      label: "Total Inv Amount",
      content: toCurrencyPrice(invoice.totalInvoice),
    },
    {
      label: "Inv Due Date",
      content: invoice.invoiceDueDate && toLocalDate(invoice.invoiceDueDate),
    },
    {
      label: "Payment Type",
      content: toHumanReadablePaymentType(invoice.paymentType),
    },
    {
      label: "Payment Method",
      content: toHumanReadable(invoice.paymentMethod),
    },
  ];

  const paymentInformation = [
    {
      label: "Total Paid Amount",
      content: invoice.totalPaid && toLocalNumber(invoice.totalPaid),
    },
    {
      label: "OSP Amount",
      content: invoice.totalAmountDue && toLocalNumber(invoice.totalAmountDue),
    },
    {
      label: "Last Payment Date",
      content:
        invoice.lastPayment?.paidAt && toLocalDate(invoice.lastPayment.paidAt),
    },
    {
      label: "Last Payment Amount",
      content:
        invoice.lastPayment?.amount &&
        toLocalNumber(invoice.lastPayment.amount),
    },
    {
      label: "Late Charge",
      content: invoice.lateCharge && toLocalNumber(invoice.lateCharge),
    },
    {
      label: "Total Account Receivable",
      content:
        invoice.totalAccountReceivable &&
        toLocalNumber(invoice.totalAccountReceivable),
    },
    {
      label: "Payment Status",
      content: invoice.paymentStatus && toHumanReadable(invoice.paymentStatus),
    },
  ];

  const collectionInformation = [
    {
      label: "DPD",
      content: invoice.dpd && toLocalNumber(invoice.dpd),
    },
    {
      label: "Is Restructured",
      content: invoice.isRestructured ? "YES" : "NO",
    },
    {
      label: "Collector Assigned",
      content: getUserDisplayName(invoice.collectorAssigned),
    },

    {
      label: "Sales Name",
      content: invoice.salesName,
    },
    {
      label: "Sales Team",
      content: invoice.salesTeamName,
    },
  ];
  return (
    <SimpleGrid
      cols={3}
      spacing={20}
      verticalSpacing={16}
      className={classes.columnsWrapper}
    >
      <MultiFieldCard
        label="Transaction information"
        variant="blue"
        items={transactionInformation}
      />
      <MultiFieldCard
        label="Payment information"
        variant="blue"
        items={paymentInformation}
      />
      <MultiFieldCard
        label="Collection information"
        variant="blue"
        items={collectionInformation}
      />
    </SimpleGrid>
  );
};

export default InvoiceDetailsCards;
