import { Select } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import ActionIcon from "../../../../../components/ActionIcon/ActionIcon.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgEdit from "../../../../../components/Icons/Edit.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import { GiroStatus, GiroType } from "../../../../../graphql/generated.ts";
import {
  GiroManagement,
  RawGiroManagementType,
} from "../../../../../types/giroManagement.ts";
import { booleanToString } from "../../../../../utils/boolean.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { priceFormatter } from "../../../../../utils/number.ts";
import { giroStatusOptions, giroTypeOptions } from "../constants.ts";

interface Props {
  data: GiroManagement[] | null | undefined;
  canEdit: boolean;
  olNumbersList: string[];
  soNumbersList: string[];
  onInlineEdit: (
    giroManagementId: number,
    value: RawGiroManagementType
  ) => void;
  onOpenEditModal: (giroManagement: GiroManagement) => void;
}

export function useGiroManagementTableData({
  data,
  canEdit,
  soNumbersList,
  olNumbersList,
  onInlineEdit,
  onOpenEditModal,
}: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "number",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "number",
          }}
        >
          GIRO No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 250,
    },
    {
      accessor: "receivedDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "receivedDate",
          }}
        >
          GIRO Received Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "type",
          }}
        >
          Type
        </DatabaseMetadataPopover>
      ),
      accessor: "type",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "dueDate",
          }}
        >
          GIRO Due Date
        </DatabaseMetadataPopover>
      ),
      accessor: "dueDate",
      sortable: true,
      width: 200,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "buyerNumber",
          }}
        >
          GIRO Buyer No
        </DatabaseMetadataPopover>
      ),
      accessor: "buyerNumber",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "bank",
          }}
        >
          Bank
        </DatabaseMetadataPopover>
      ),
      accessor: "bank",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "bankAccountNo",
          }}
        >
          Bank Account No
        </DatabaseMetadataPopover>
      ),
      accessor: "bankAccountNo",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "bankAccountHolderName",
          }}
        >
          Bank Account Holder Name
        </DatabaseMetadataPopover>
      ),
      accessor: "bankAccountHolderName",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "amount",
          }}
        >
          GIRO Amount
        </DatabaseMetadataPopover>
      ),
      accessor: "amount",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "olNo",
          }}
        >
          OL No
        </DatabaseMetadataPopover>
      ),
      accessor: "olNo",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "soNo",
          }}
        >
          SO No
        </DatabaseMetadataPopover>
      ),
      accessor: "soNo",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "status",
          }}
        >
          GIRO Status
        </DatabaseMetadataPopover>
      ),
      accessor: "status",
      sortable: true,
      width: 230,
    },
    {
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "GiroManagement",
            fieldName: "accountNoMatched",
          }}
        >
          GIRO Account Number Matched
        </DatabaseMetadataPopover>
      ),
      accessor: "accountNoMatched",
      sortable: false,
      width: 230,
    },
    {
      accessor: "actions",
      title: "Actions",
      width: 150,
      hidden: !canEdit,
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        number: item.number,
        receivedDate: toLocalDate(item.receivedDate),
        type: (
          <Select
            data={giroTypeOptions}
            value={item.type}
            withinPortal
            zIndex={100}
            size="s"
            disabled={!canEdit}
            onChange={(value) =>
              onInlineEdit(item.id, { ...item, type: value as GiroType })
            }
          />
        ),
        dueDate: toLocalDate(item.dueDate),
        buyerNumber: item.buyerNumber,
        bank: item.bank,
        bankAccountNo: item.bankAccountNo,
        bankAccountHolderName: item.bankAccountHolderName,
        amount: priceFormatter(item.amount),
        olNo: (
          <Select
            data={olNumbersList}
            value={item.olNo}
            withinPortal
            zIndex={100}
            size="s"
            disabled={!canEdit}
            onChange={(value) => {
              onInlineEdit(item.id, { ...item, olNo: value as string });
            }}
          />
        ),
        soNo: (
          <Select
            data={soNumbersList}
            value={item.soNo}
            withinPortal
            zIndex={100}
            size="s"
            disabled={!canEdit}
            onChange={(value) => {
              onInlineEdit(item.id, { ...item, soNo: value as string });
            }}
          />
        ),
        status: (
          <Select
            data={giroStatusOptions}
            value={item.status}
            withinPortal
            zIndex={100}
            size="s"
            disabled={!canEdit}
            onChange={(value) =>
              onInlineEdit(item.id, { ...item, status: value as GiroStatus })
            }
          />
        ),
        accountNoMatched: booleanToString(item.accountNoMatched),
        actions: (
          <ActionIcon onClick={() => onOpenEditModal(item)}>
            <SvgEdit />
          </ActionIcon>
        ),
      };
    });
  }, [
    data,
    canEdit,
    onInlineEdit,
    onOpenEditModal,
    olNumbersList,
    soNumbersList,
  ]);

  return [columns, rows];
}
