import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ContractType } from "../../../../../../graphql/generated.ts";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import {
  toCurrencyPrice,
  toLocalNumber,
} from "../../../../../../utils/number.ts";
import { getCRContractListing } from "../../../utils.ts";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
  type: ContractType;
}

export const useCRGrantedDetailsOfContract = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "codeCharacteristicCredit",
              }}
              position="right"
            >
              Characteristic Credit
            </DatabaseMetadataPopover>
          ),
          content: data?.codeCharacteristicCredit,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "codeAgreementCredit",
              }}
              position="right"
            >
              Agreement Credit
            </DatabaseMetadataPopover>
          ),
          content: data?.codeAgreementCredit,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "originalAgreementNumber",
              }}
              position="right"
            >
              Original Agreement Number
            </DatabaseMetadataPopover>
          ),
          content: data?.originalAgreementNumber,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "originalAgreementDate",
              }}
              position="right"
            >
              Original Agreement Date
            </DatabaseMetadataPopover>
          ),
          content:
            data?.originalAgreementDate &&
            toLocalDate(data.originalAgreementDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "finalAgreementNumber",
              }}
              position="right"
            >
              Final Agreement Number
            </DatabaseMetadataPopover>
          ),
          content: data?.finalAgreementNumber,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "finalAgreementDate",
              }}
              position="right"
            >
              Final Agreement Date
            </DatabaseMetadataPopover>
          ),
          content:
            data?.finalAgreementDate && toLocalDate(data.finalAgreementDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "frequencyOfCredit",
              }}
              position="right"
            >
              Credit Frequency
            </DatabaseMetadataPopover>
          ),
          content: data?.frequencyOfCredit,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "startDateOfCredit",
              }}
              position="right"
            >
              Credit Start Date
            </DatabaseMetadataPopover>
          ),
          content:
            data?.startDateOfCredit && toLocalDate(data.startDateOfCredit),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "debtorCategory",
              }}
              position="right"
            >
              Debtor Category
            </DatabaseMetadataPopover>
          ),
          content: data?.debtorCategory,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "usageType",
              }}
              position="right"
            >
              Usage Type
            </DatabaseMetadataPopover>
          ),
          content: data?.usageType,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "usageOrientation",
              }}
              position="right"
            >
              Usage Orientation
            </DatabaseMetadataPopover>
          ),
          content: data?.usageOrientation,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "economicSector",
              }}
              position="right"
            >
              Economic Sector
            </DatabaseMetadataPopover>
          ),
          content: data?.economicSector,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "projectValue",
              }}
              position="right"
            >
              Project Value
            </DatabaseMetadataPopover>
          ),
          content: data?.projectValue && toCurrencyPrice(data.projectValue),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "typeOfInterestRate",
              }}
              position="right"
            >
              Type of Interest Rate
            </DatabaseMetadataPopover>
          ),
          content: data?.typeOfInterestRate,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "interestRate",
              }}
              position="right"
            >
              Interest Rate
            </DatabaseMetadataPopover>
          ),
          content: data?.interestRate && `${toLocalNumber(data.interestRate)}%`,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "financigProgram",
              }}
              position="right"
            >
              Financing Program
            </DatabaseMetadataPopover>
          ),
          content: data?.financigProgram,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "city",
              }}
              position="right"
            >
              City
            </DatabaseMetadataPopover>
          ),
          content: data?.city,
        },
      ];
    }

    if (type === ContractType.Bond) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "sovereignRate",
              }}
              position="right"
            >
              Sovereign Rating
            </DatabaseMetadataPopover>
          ),
          content: data?.sovereignRate,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "listing",
              }}
              position="right"
            >
              Listing
            </DatabaseMetadataPopover>
          ),
          content: data?.listing && getCRContractListing(data.listing),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "bondRating",
              }}
              position="right"
            >
              Bond Rating
            </DatabaseMetadataPopover>
          ),
          content: data?.bondRating,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "ownershipPurpose",
              }}
              position="right"
            >
              Ownership Purpose
            </DatabaseMetadataPopover>
          ),
          content: data?.ownershipPurpose,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "purchaseDate",
              }}
              position="right"
            >
              Purchase date
            </DatabaseMetadataPopover>
          ),
          content: data?.purchaseDate && toLocalDate(data.purchaseDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "interestRate",
              }}
              position="right"
            >
              Interest Rate
            </DatabaseMetadataPopover>
          ),
          content: data?.interestRate && `${toLocalNumber(data.interestRate)}%`,
        },
      ];
    }

    if (type === ContractType.LetterOfCredit) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "destination",
              }}
              position="right"
            >
              Destination
            </DatabaseMetadataPopover>
          ),
          content: data?.destination,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "originalAgreementNumber",
              }}
              position="right"
            >
              Original Agreement Number
            </DatabaseMetadataPopover>
          ),
          content: data?.originalAgreementNumber,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "originalAgreementDate",
              }}
              position="right"
            >
              Original Agreement Date
            </DatabaseMetadataPopover>
          ),
          content:
            data?.originalAgreementDate &&
            toLocalDate(data.originalAgreementDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "finalAgreementNumber",
              }}
              position="right"
            >
              Final Agreement Number
            </DatabaseMetadataPopover>
          ),
          content: data?.finalAgreementNumber,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "finalAgreementDate",
              }}
              position="right"
            >
              Final Agreement Date
            </DatabaseMetadataPopover>
          ),
          content:
            data?.finalAgreementDate && toLocalDate(data.finalAgreementDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "bankBeneficiery",
              }}
              position="right"
            >
              Bank Beneficiary
            </DatabaseMetadataPopover>
          ),
          content: data?.bankBeneficiery,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "defaultDate",
              }}
              position="right"
            >
              Default Date
            </DatabaseMetadataPopover>
          ),
          content: data?.defaultDate && toLocalDate(data.defaultDate),
        },
      ];
    }

    if (type === ContractType.Guarantee) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "guaranteeObjective",
              }}
              position="right"
            >
              Guarantee Objective
            </DatabaseMetadataPopover>
          ),
          content: data?.guaranteeObjective,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "originalAgreementNumber",
              }}
              position="right"
            >
              Original Agreement Number
            </DatabaseMetadataPopover>
          ),
          content: data?.originalAgreementNumber,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "originalAgreementDate",
              }}
              position="right"
            >
              Original Agreement Date
            </DatabaseMetadataPopover>
          ),
          content:
            data?.originalAgreementDate &&
            toLocalDate(data.originalAgreementDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "finalAgreementNumber",
              }}
              position="right"
            >
              Final Agreement Number
            </DatabaseMetadataPopover>
          ),
          content: data?.finalAgreementNumber,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "finalAgreementDate",
              }}
              position="right"
            >
              Final Agreement Date
            </DatabaseMetadataPopover>
          ),
          content:
            data?.finalAgreementDate && toLocalDate(data.finalAgreementDate),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "guarantorName",
              }}
              position="right"
            >
              Guarantor Name
            </DatabaseMetadataPopover>
          ),
          content: data?.guarantorName,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "defaultDate",
              }}
              position="right"
            >
              Default Date
            </DatabaseMetadataPopover>
          ),
          content: data?.defaultDate,
        },
      ];
    }

    if (type === ContractType.OtherFacilities) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "interestRate",
              }}
              position="right"
            >
              Interest Rate
            </DatabaseMetadataPopover>
          ),
          content: data?.interestRate && `${toLocalNumber(data.interestRate)}%`,
        },
      ];
    }
    return [];
  }, [data, type]);
};
