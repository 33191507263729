import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ContractType } from "../../../../../../graphql/generated.ts";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
  type: ContractType;
}

export const useCROverdueDetailsOfContract = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "principalOverdueAmount",
              }}
              position="right"
            >
              Principal Overdue Amount
            </DatabaseMetadataPopover>
          ),
          content:
            data?.principalOverdueAmount &&
            toCurrencyPrice(data.principalOverdueAmount),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "interestOverdueAmount",
              }}
              position="right"
            >
              Interest Overdue Amount
            </DatabaseMetadataPopover>
          ),
          content:
            data?.interestOverdueAmount &&
            toCurrencyPrice(data.interestOverdueAmount),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "daysPastDue",
              }}
              position="right"
            >
              Days Past Due
            </DatabaseMetadataPopover>
          ),
          content: data?.daysPastDue,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "overduePaymentsNumber",
              }}
              position="right"
            >
              Overdue Payments Number
            </DatabaseMetadataPopover>
          ),
          content: data?.overduePaymentsNumber,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "badCreditCause",
              }}
              position="right"
            >
              Bad Credit Cause
            </DatabaseMetadataPopover>
          ),
          content: data?.badCreditCause,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "badCreditDate",
              }}
              position="right"
            >
              Bad Credit Date
            </DatabaseMetadataPopover>
          ),
          content: data?.badCreditDate,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "penalty",
              }}
              position="right"
            >
              Penalty
            </DatabaseMetadataPopover>
          ),
          content: data?.penalty && toCurrencyPrice(data.penalty),
        },
      ];
    }

    if (type === ContractType.Bond || type === ContractType.OtherFacilities) {
      return [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "overdueAmount",
              }}
              position="right"
            >
              Overdue Amount
            </DatabaseMetadataPopover>
          ),
          content: data?.overdueAmount && toCurrencyPrice(data.overdueAmount),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "daysPastDue",
              }}
              position="right"
            >
              Days Past Due
            </DatabaseMetadataPopover>
          ),
          content: data?.daysPastDue,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "badCreditCause",
              }}
              position="right"
            >
              Bad Credit Cause
            </DatabaseMetadataPopover>
          ),
          content: data?.badCreditCause,
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikGrantedContractDetails",
                fieldName: "badCreditDate",
              }}
              position="right"
            >
              Bad Credit Date
            </DatabaseMetadataPopover>
          ),
          content: data?.badCreditDate,
        },
      ];
    }

    return [];
  }, [data, type]);
};
