import { useQuery } from "urql";

import {
  DateRangeInput,
  GetInvoicesDocument,
  InvoiceFilter,
  InvoiceOrderBy,
} from "../../../graphql/generated";
import { getApiPaginationData } from "../../../utils/api";
import { ApiHookProps } from "../types.ts";

interface Props extends ApiHookProps<InvoiceFilter, InvoiceOrderBy> {
  lastPaymentDate?: DateRangeInput;
}

export const useInvoices = ({
  filter,
  orderBy,
  pause,
  page,
  lastPaymentDate,
}: Props) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
    lastPaymentDate,
  };
  return useQuery({ query: GetInvoicesDocument, variables, pause });
};
