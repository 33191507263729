import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ClikSubjectAdditionalInfo } from "../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../utils/date.ts";

interface Props {
  data: ClikSubjectAdditionalInfo | undefined | null;
}

export const useCRAdditionalInformation = ({
  data,
}: Props): MultiFieldCardItem[] | null => {
  if (!data) {
    return null;
  }

  return [
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikSubjectAdditionalInfo",
            fieldName: "additionalInformationNo",
          }}
          position="right"
        >
          Additional Information No
        </DatabaseMetadataPopover>
      ),
      content: data.additionalInformationNo,
    },
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikSubjectAdditionalInfo",
            fieldName: "providerType",
          }}
          position="right"
        >
          Provider Type
        </DatabaseMetadataPopover>
      ),
      content: data.providerType,
    },
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikSubjectAdditionalInfo",
            fieldName: "providerCode",
          }}
          position="right"
        >
          Provider
        </DatabaseMetadataPopover>
      ),
      content: data.providerCode,
    },
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikSubjectAdditionalInfo",
            fieldName: "additionalInformationTypeDesc",
          }}
          position="right"
        >
          Information Type
        </DatabaseMetadataPopover>
      ),
      content: data.additionalInformationTypeDesc,
    },
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikSubjectAdditionalInfo",
            fieldName: "additionalInformationValue",
          }}
          position="right"
        >
          Details
        </DatabaseMetadataPopover>
      ),
      content: data.additionalInformationValue,
    },
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikSubjectAdditionalInfo",
            fieldName: "status",
          }}
          position="right"
        >
          Status
        </DatabaseMetadataPopover>
      ),
      content: data.status,
    },
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikSubjectAdditionalInfo",
            fieldName: "statusDate",
          }}
          position="right"
        >
          Status Date
        </DatabaseMetadataPopover>
      ),
      content: data.statusDate && toLocalDate(data.statusDate),
    },
    {
      label: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikSubjectAdditionalInfo",
            fieldName: "referenceDate",
          }}
          position="right"
        >
          Last Reference Date
        </DatabaseMetadataPopover>
      ),
      content: data.referenceDate && toLocalDate(data.referenceDate),
    },
  ];
};
