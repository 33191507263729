import { Select } from "@mantine/core";
import { DatePickerInput, DateValue } from "@mantine/dates";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import { DatabaseMetadataPopover } from "../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import Calendar from "../../../components/Icons/Calendar.tsx";
import { TableData, TableRow } from "../../../components/Table/types.ts";
import TableFilesDisplay from "../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { AppRoute } from "../../../constants.ts";
import {
  EmployeeCandidateHiringStatus,
  EmployeeCandidateRequest,
  EmployeeCandidateResultCategory,
  UpdateEmployeeCandidateRequestInput,
} from "../../../graphql/generated.ts";
import { toLocalDateTime } from "../../../utils/date.ts";
import { toHumanReadable } from "../../../utils/string.ts";
import {
  clikResultCategoryOptions,
  hiringStatusOptions,
} from "../constants.ts";
import { useStyles } from "../EmployeeCandidate.styles.ts";

interface Props {
  data: EmployeeCandidateRequest[] | null | undefined;
  hasEditAccess?: boolean;
  hasClikResultViewAccess?: boolean;
  onEditEmployeeCandidate: (
    id: number,
    value: UpdateEmployeeCandidateRequestInput
  ) => void;
}

export function useEmployeeCandidateRequestTableData({
  data,
  hasEditAccess,
  hasClikResultViewAccess,
  onEditEmployeeCandidate,
}: Props): TableData {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "createdAt",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "EmployeeCandidateRequest",
            fieldName: "createdAt",
          }}
        >
          Request Timestamp
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "230px",
    },
    {
      accessor: "candidateName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "EmployeeCandidateRequest",
            fieldName: "candidateName",
          }}
        >
          Candidate Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "230px",
    },
    {
      accessor: "position",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "EmployeeCandidateRequest",
            fieldName: "position",
          }}
        >
          Position Applied
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "230px",
    },
    {
      accessor: "consentForm",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "EmployeeCandidateRequest",
            fieldName: "consentForm",
          }}
        >
          Consent Form
        </DatabaseMetadataPopover>
      ),
      sortable: false,
    },
    {
      accessor: "clikResultId",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "EmployeeCandidateRequest",
            fieldName: "clikResultId",
          }}
        >
          CLIK Result
        </DatabaseMetadataPopover>
      ),
      sortable: false,
      hidden: !hasClikResultViewAccess,
    },
    {
      accessor: "clikResultCategory",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "EmployeeCandidateRequest",
            fieldName: "clikResultCategory",
          }}
        >
          CLIK Result Category
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "230px",
    },
    {
      accessor: "hiringStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "EmployeeCandidateRequest",
            fieldName: "hiringStatus",
          }}
        >
          Hiring Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
    {
      accessor: "joinDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "EmployeeCandidateRequest",
            fieldName: "joinDate",
          }}
        >
          Join Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
  ];

  const handleEdit = useCallback(
    (id: number, value: UpdateEmployeeCandidateRequestInput) => {
      onEditEmployeeCandidate(id, value);
    },
    [onEditEmployeeCandidate]
  );

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        createdAt: toLocalDateTime(item.createdAt),
        candidateName: item.candidateName,
        position: item.position,
        consentForm: <TableFilesDisplay files={[item.consentForm]} />,
        clikResultId: (
          <Link
            to={`${AppRoute.ClikResult}/${item.clikResultId}`}
            onClick={(e) => e.stopPropagation()}
            className={classes.clikResult}
          >
            View
          </Link>
        ),
        clikResultCategory: hasEditAccess ? (
          <Select
            data={clikResultCategoryOptions}
            value={item.clikResultCategory}
            withinPortal
            zIndex={100}
            size="s"
            placeholder="Select Result Category"
            onChange={(value: EmployeeCandidateResultCategory) =>
              handleEdit(item.id, { clikResultCategory: value })
            }
          />
        ) : (
          item.clikResultCategory && toHumanReadable(item.clikResultCategory)
        ),
        hiringStatus: hasEditAccess ? (
          <Select
            data={hiringStatusOptions}
            value={item.hiringStatus}
            withinPortal
            zIndex={100}
            size="s"
            placeholder="Select Hiring Status"
            onChange={(value: EmployeeCandidateHiringStatus) =>
              handleEdit(item.id, { hiringStatus: value })
            }
          />
        ) : (
          item.hiringStatus && toHumanReadable(item.hiringStatus)
        ),
        joinDate: hasEditAccess ? (
          <DatePickerInput
            icon={<Calendar />}
            firstDayOfWeek={0}
            valueFormat="YYYY MMM DD"
            maxDate={new Date()}
            clearable
            popoverProps={{ withinPortal: true }}
            value={item.joinDate ? new Date(item.joinDate) : null}
            size="s"
            placeholder="Select Join Date"
            onChange={(value: DateValue | null) =>
              handleEdit(item.id, {
                joinDate: value,
              })
            }
          />
        ) : (
          toLocalDateTime(item.joinDate)
        ),
      };
    });
  }, [data, hasEditAccess, handleEdit, classes]);

  return [columns, rows];
}
