import { Flex, Select, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import ActionIcon from "../../../../../components/ActionIcon/ActionIcon.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import SvgEdit from "../../../../../components/Icons/Edit.tsx";
import InfoTooltip from "../../../../../components/InfoTooltip/InfoTooltip.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import TableFilesDisplay from "../../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { PksStatus, UpdatePksInput } from "../../../../../graphql/generated.ts";
import { Pks } from "../../../../../types/pks.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { getPksStatusOptions } from "../utils.ts";

interface Props {
  data: Pks[] | null | undefined;
  canEdit: boolean;
  onEdit: (pksId: number, value: UpdatePksInput) => void;
  onOpenEditModal: (pks: Pks) => void;
}

export function usePksTableData({
  data,
  canEdit,
  onEdit,
  onOpenEditModal,
}: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "pksDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Pks",
            fieldName: "pksDate",
          }}
        >
          PKS Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "pksNo",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Pks",
            fieldName: "pksNo",
          }}
        >
          PKS No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "pksFile",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Pks",
            fieldName: "pksFile",
          }}
        >
          PKS File
        </DatabaseMetadataPopover>
      ),
      width: 200,
    },
    {
      accessor: "expiryDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Pks",
            fieldName: "expiryDate",
          }}
        >
          PKS Expiry Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "signedPksFile",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Pks",
            fieldName: "signedPksFile",
          }}
        >
          Signed PKS File
        </DatabaseMetadataPopover>
      ),
      width: 200,
    },
    {
      accessor: "pksStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Pks",
            fieldName: "pksStatus",
          }}
        >
          <Flex align="center">
            <Text>PKS Status</Text>
            <InfoTooltip tooltipText="Active PKS status will be unavailable until Signed PKS File is uploaded." />
          </Flex>
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 200,
    },
    {
      accessor: "actions",
      title: "Actions",
      width: 200,
      hidden: !canEdit,
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      const row: TableRow = {
        id: item.id,
        pksDate: toLocalDate(item.pksDate),
        pksNo: item.pksNo,
        pksFile: <TableFilesDisplay files={[item.pksFile]} />,
        expiryDate: toLocalDate(item.expiryDate),
        signedPksFile: item?.signedPksFile && (
          <TableFilesDisplay files={[item.signedPksFile]} />
        ),
        pksStatus: (
          <Select
            data={getPksStatusOptions(item?.signedPksFile)}
            value={item.pksStatus}
            withinPortal
            zIndex={100}
            size="s"
            placeholder="Select PKS Status"
            disabled={!canEdit || !item.signedPksFile}
            onChange={(value) =>
              onEdit(item.id, { pksStatus: value as PksStatus })
            }
          />
        ),
        actions: (
          <ActionIcon onClick={() => onOpenEditModal(item)}>
            <SvgEdit />
          </ActionIcon>
        ),
      };
      return row;
    });
  }, [data, canEdit, onEdit, onOpenEditModal]);

  return [columns, rows];
}
