import { Text } from "@mantine/core";
import { useMemo } from "react";

import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { DatabaseMetadataPopover } from "../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { ContractType } from "../../../../../graphql/generated.ts";
import { ClikContractSummary } from "../../../../../types/clikResult.ts";
import { toCurrencyPrice } from "../../../../../utils/number.ts";
import { useStyles } from "../../ClikResultDetails.styles.tsx";
import { getContractSummaryByType } from "../../utils.ts";

interface Props {
  data: ClikContractSummary[] | null | undefined;
  currency: string | null | undefined;
}

interface CRFinanialSummaryCardsInfo {
  credit: MultiFieldCardProps;
  bond: MultiFieldCardProps;
  guarantee: MultiFieldCardProps;
  irrevocable: MultiFieldCardProps;
  other: MultiFieldCardProps;
}

export const useCRFinancialSummaryCardsInfo = ({
  data,
  currency,
}: Props): CRFinanialSummaryCardsInfo => {
  const { classes } = useStyles();

  const credit: MultiFieldCardProps = useMemo(() => {
    const creditSummary = getContractSummaryByType(data, ContractType.Credit);

    return {
      label: "Credit / Financing",
      variant: Number(creditSummary?.active) > 0 ? "green" : "bronze",
      cardControl: (
        <Text className={classes.cardSubtitle}>
          Active Credits / Financing: {creditSummary?.active ?? 0}
        </Text>
      ),
      items: [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "creditLimit",
              }}
              position="right"
            >
              Credit Limit
            </DatabaseMetadataPopover>
          ),
          content:
            creditSummary?.creditLimit &&
            toCurrencyPrice(creditSummary.creditLimit, currency),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "debitBalance",
              }}
              position="right"
            >
              Debit Balance
            </DatabaseMetadataPopover>
          ),
          content:
            creditSummary?.debitBalance &&
            toCurrencyPrice(creditSummary.debitBalance, currency),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "overdue",
              }}
              position="right"
            >
              Overdue
            </DatabaseMetadataPopover>
          ),
          content:
            creditSummary?.overdue &&
            toCurrencyPrice(creditSummary.overdue, currency),
        },
      ],
    };
  }, [data, currency, classes]);

  const bond: MultiFieldCardProps = useMemo(() => {
    const bondSummary = getContractSummaryByType(data, ContractType.Bond);

    return {
      label: "Bond / Securities",
      variant: Number(bondSummary?.active) > 0 ? "green" : "bronze",
      cardControl: (
        <Text className={classes.cardSubtitle}>
          Active Bond / Securities: {bondSummary?.active ?? 0}
        </Text>
      ),
      items: [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "debitBalance",
              }}
              position="right"
            >
              Debit Balance
            </DatabaseMetadataPopover>
          ),
          content:
            bondSummary?.debitBalance &&
            toCurrencyPrice(bondSummary.debitBalance, currency),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "overdue",
              }}
              position="right"
            >
              Overdue
            </DatabaseMetadataPopover>
          ),
          content:
            bondSummary?.overdue &&
            toCurrencyPrice(bondSummary.overdue, currency),
        },
      ],
    };
  }, [data, currency, classes]);

  const guarantee: MultiFieldCardProps = useMemo(() => {
    const guaranteeSummary = getContractSummaryByType(
      data,
      ContractType.Guarantee
    );

    return {
      label: "Bank Guarantee",
      variant: Number(guaranteeSummary?.active) > 0 ? "green" : "bronze",
      cardControl: (
        <Text className={classes.cardSubtitle}>
          Active Bank Guarantee: {guaranteeSummary?.active ?? 0}
        </Text>
      ),
      items: [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "creditLimit",
              }}
              position="right"
            >
              Credit Limit
            </DatabaseMetadataPopover>
          ),
          content:
            guaranteeSummary?.creditLimit &&
            toCurrencyPrice(guaranteeSummary.creditLimit, currency),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "debitBalance",
              }}
              position="right"
            >
              Debit Balance
            </DatabaseMetadataPopover>
          ),
          content:
            guaranteeSummary?.debitBalance &&
            toCurrencyPrice(guaranteeSummary.debitBalance, currency),
        },
      ],
    };
  }, [data, currency, classes]);

  const irrevocable: MultiFieldCardProps = useMemo(() => {
    const irrevocableSummary = getContractSummaryByType(
      data,
      ContractType.LetterOfCredit
    );

    return {
      label: "Irrevocable LC",
      variant: Number(irrevocableSummary?.active) > 0 ? "green" : "bronze",
      cardControl: (
        <Text className={classes.cardSubtitle}>
          Irrevocable LC: {irrevocableSummary?.active ?? 0}
        </Text>
      ),
      items: [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "creditLimit",
              }}
              position="right"
            >
              Credit Limit
            </DatabaseMetadataPopover>
          ),
          content:
            irrevocableSummary?.creditLimit &&
            toCurrencyPrice(irrevocableSummary.creditLimit, currency),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "debitBalance",
              }}
              position="right"
            >
              Debit Balance
            </DatabaseMetadataPopover>
          ),
          content:
            irrevocableSummary?.debitBalance &&
            toCurrencyPrice(irrevocableSummary.debitBalance, currency),
        },
      ],
    };
  }, [data, currency, classes]);

  const other: MultiFieldCardProps = useMemo(() => {
    const otherSummary = getContractSummaryByType(
      data,
      ContractType.OtherFacilities
    );

    return {
      label: "Other Facilities",
      variant: Number(otherSummary?.active) > 0 ? "green" : "bronze",
      cardControl: (
        <Text className={classes.cardSubtitle}>
          Other Facilities: {otherSummary?.active ?? 0}
        </Text>
      ),
      items: [
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "debitBalance",
              }}
              position="right"
            >
              Debit Balance
            </DatabaseMetadataPopover>
          ),
          content:
            otherSummary?.debitBalance &&
            toCurrencyPrice(otherSummary.debitBalance, currency),
        },
        {
          label: (
            <DatabaseMetadataPopover
              databaseMetadata={{
                tableName: "ClikContractSummary",
                fieldName: "overdue",
              }}
              position="right"
            >
              Overdue
            </DatabaseMetadataPopover>
          ),
          content:
            otherSummary?.overdue &&
            toCurrencyPrice(otherSummary.overdue, currency),
        },
      ],
    };
  }, [data, currency, classes]);

  return { credit, bond, guarantee, irrevocable, other };
};
