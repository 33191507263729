import { Flex, HoverCard, Text } from "@mantine/core";
import { FloatingPosition } from "@mantine/core/lib/Floating";
import get from "lodash/get";
import isNil from "lodash/isNil";
import startCase from "lodash/startCase";
import React, { ReactNode } from "react";

import { useXRayContext } from "../../providers/XRayProvider.tsx";
import Preloader from "../Preloader/Preloader.tsx";
import { useStyles } from "./DatabaseMetadataPopover.styles.tsx";

interface Props {
  databaseMetadata: { tableName: string; fieldName: string } | null | undefined;
  children: ReactNode;
  position?: FloatingPosition;
}

export const DatabaseMetadataPopover = ({
  databaseMetadata,
  children,
  position = "bottom",
}: Props) => {
  const { classes } = useStyles();

  const { xrayModeEnabled, metadataLoading, metadata, hasXrayModeAccess } =
    useXRayContext();

  if (!xrayModeEnabled || !hasXrayModeAccess || !databaseMetadata) {
    return children;
  }

  const tableMetadata = metadata?.models.find(
    (model) => model.name === databaseMetadata.tableName
  );
  const fieldMetadata = tableMetadata?.fields.find(
    (field) => field.name === databaseMetadata.fieldName
  );
  const enumMetadata =
    fieldMetadata &&
    metadata?.enums.find((enumm) => enumm.name === fieldMetadata.type);
  if (!fieldMetadata) {
    return children;
  }

  const keys = Object.keys(fieldMetadata).filter(
    (key) => !isNil(get(fieldMetadata, key))
  );
  return (
    <HoverCard
      withArrow
      arrowPosition="side"
      shadow="xs"
      withinPortal
      position={position}
      zIndex={10000}
    >
      <HoverCard.Target>
        {React.isValidElement(children) ? children : <Text>{children}</Text>}
      </HoverCard.Target>
      <HoverCard.Dropdown>
        {metadataLoading ? (
          <Preloader loading={metadataLoading} />
        ) : (
          <Flex direction="column" gap={12}>
            <Flex justify="space-between" gap={12}>
              <Text weight="bolder" className={classes.itemLabel}>
                Table:
              </Text>
              <Text className={classes.itemContent}>
                {databaseMetadata.tableName}
              </Text>
            </Flex>
            {keys.map((key) => (
              <Flex key={key} justify="space-between" gap={12}>
                <Text weight="bolder" className={classes.itemLabel}>
                  {startCase(key)}:
                </Text>
                <Text className={classes.itemContent}>
                  {String(get(fieldMetadata, key)) ?? "-"}
                </Text>
              </Flex>
            ))}
            {enumMetadata && (
              <Flex justify="space-between" gap={12}>
                <Text weight="bolder" className={classes.itemLabel}>
                  Values:
                </Text>
                <Flex direction="column" align="flex-end">
                  {enumMetadata.values.map((value) => (
                    <Text key={value.name} className={classes.itemContent}>
                      {value.name}
                    </Text>
                  ))}
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
