import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import CPExceptionTableCard from "../../../../components/CPExceptionTableCard/CPExceptionTableCard.tsx";
import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import Spoiler from "../../../../components/Spoiler/Spoiler.tsx";
import { TableEmptyValue } from "../../../../components/Table/constants.tsx";
import { TableData, TableRow } from "../../../../components/Table/types";
import { LimitRequest } from "../../../../types/limitRequest.ts";
import { toLocalDate } from "../../../../utils/date";
import { toLocalNumber, toPercent, toPrice } from "../../../../utils/number";
import { toHumanReadable } from "../../../../utils/string";

interface Props {
  data: LimitRequest[] | null | undefined;
}

export const useOngoingOLTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "committeeDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditApproval",
            fieldName: "date",
          }}
        >
          Committee Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitName",
          }}
        >
          Limit Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "companyName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "name",
          }}
        >
          Company Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 300,
    },
    {
      accessor: "salesName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesName",
          }}
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "salesTeamName",
          }}
        >
          Sales Team
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "committeeResult",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditApproval",
            fieldName: "result",
          }}
        >
          Committee Result
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "limitApproved",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "limitApproved",
          }}
        >
          Limit Approved
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },

    {
      accessor: "tenorApproved",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "tenorApproved",
          }}
        >
          Tenor Approved
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "pricingApproved",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "pricingApproved",
          }}
        >
          Pricing Approved
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "conditionPrecedents",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "conditionPrecedents",
          }}
        >
          Condition Precedents
        </DatabaseMetadataPopover>
      ),
      sortable: false,
      width: 250,
    },
    {
      accessor: "exceptions",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "exceptions",
          }}
        >
          Exceptions
        </DatabaseMetadataPopover>
      ),
      sortable: false,
      width: 250,
    },
    {
      accessor: "committeeNotes",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "CreditApproval",
            fieldName: "notes",
          }}
        >
          Notes
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 400,
    },
    {
      accessor: "requestStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "requestStatus",
          }}
        >
          Request Status
        </DatabaseMetadataPopover>
      ),
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        committeeDate: toLocalDate(item?.creditApproval?.date),
        limitName: item.limitName,
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeamName: item.company.salesTeamName,
        limitApproved: toPrice(item.limitApproved),
        tenorApproved: toLocalNumber(item.tenorApproved),
        pricingApproved: toPercent(item.pricingApproved, "percentage"),
        committeeResult:
          item?.creditApproval?.result &&
          toHumanReadable(item.creditApproval.result),
        conditionPrecedents: item?.conditionPrecedents?.length ? (
          <CPExceptionTableCard data={item?.conditionPrecedents} />
        ) : (
          TableEmptyValue
        ),
        exceptions: item?.exceptions?.length ? (
          <CPExceptionTableCard data={item.exceptions} />
        ) : (
          TableEmptyValue
        ),
        committeeNotes: item?.creditApproval?.notes ? (
          <Spoiler text={item.creditApproval.notes} />
        ) : null,
        requestStatus: toHumanReadable(item.requestStatus),
      };
    });
  }, [data]);

  return [columns, rows];
};
