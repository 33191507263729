import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { CustomerFreezeStatus } from "../../../../graphql/generated";
import { Customer } from "../../../../types/customer.ts";

interface Props {
  customer: Partial<Customer>;
}

export const useCustomerStatusInfo = ({ customer }: Props) => {
  return useMemo(() => {
    return [
      {
        label: (
          <DatabaseMetadataPopover
            databaseMetadata={{
              tableName: "Customer",
              fieldName: "freezeStatus",
            }}
            position="right"
          >
            is freezed
          </DatabaseMetadataPopover>
        ),
        content:
          customer.freezeStatus === CustomerFreezeStatus.Active ? "YES" : "NO",
      },
      { label: "is FPD", content: customer.isFpd ? "YES" : "NO" },
      { label: "is DPD", content: customer.isDpd ? "YES" : "NO" },
      { label: "longest DPD", content: customer.longestDpd },
    ];
  }, [customer]);
};
