import { isEmpty } from "lodash";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../../components/Table/types";
import { Invoice } from "../../../../types/invoice/invoice.ts";
import { getDisplayedAddress } from "../../../../utils/address.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { getDpdBucketDisplayName } from "../../../../utils/invoice.ts";
import { toCurrencyPrice, toLocalNumber } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface Props {
  data: Invoice[] | null | undefined;
}

export const useCustomerPayPastDue = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "customerName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Customer", fieldName: "name" }}
        >
          Company Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "350px",
    },
    {
      accessor: "invoiceNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceNumber",
          }}
        >
          Inv No
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "250px",
    },
    {
      accessor: "invoiceDueDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "invoiceDueDate",
          }}
        >
          Inv Due Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "totalInvoice",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "totalInvoice" }}
        >
          Total Inv Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "totalPaid",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "totalPaid" }}
        >
          Total Paid Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "totalAmountDue",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "totalAmountDue",
          }}
        >
          OSP Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "dpd",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "dpd" }}
        >
          DPD
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "125px",
    },
    {
      accessor: "dpdBucket",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "dpdBucket" }}
        >
          DPD Bucket
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "125px",
    },
    {
      accessor: "isRestructured",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "isRestructured",
          }}
        >
          Is Restructured
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "lastPaymentDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "InvoicePayment",
            fieldName: "paidAt",
          }}
        >
          Last Payment Date
        </DatabaseMetadataPopover>
      ),
    },

    {
      accessor: "lastPaymentAmount",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "InvoicePayment",
            fieldName: "amount",
          }}
        >
          Last Paid Amount
        </DatabaseMetadataPopover>
      ),
      width: "200px",
    },
    {
      accessor: "paymentStatus",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "paymentStatus",
          }}
        >
          Payment Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "salesName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Invoice", fieldName: "salesName" }}
        >
          Sales Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "180px",
    },
    {
      accessor: "customerPhone",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{ tableName: "Customer", fieldName: "phone" }}
        >
          Customer Phone Number
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Invoice",
            fieldName: "salesTeamName",
          }}
        >
          Sales Team Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
    },
    {
      accessor: "companyAddress",
      title: "Company Address",
      sortable: true,
      width: "350px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        invoiceNumber: item.invoiceNumber,
        customerName: item.customer.name,
        totalInvoice: toCurrencyPrice(item.totalInvoice),
        invoiceDueDate: toLocalDate(item.invoiceDueDate),
        totalPaid: toCurrencyPrice(item.totalPaid),
        totalAmountDue: toCurrencyPrice(item.totalAmountDue),
        dpd: toLocalNumber(item.dpd),
        dpdBucket: item.dpdBucket && getDpdBucketDisplayName(item.dpdBucket),
        isRestructured: item.isRestructured ? "Yes" : "No",
        lastPaymentDate:
          item.lastPayment?.paidAt && toLocalDate(item.lastPayment.paidAt),
        lastPaymentAmount: toCurrencyPrice(item.lastPayment?.amount),
        paymentStatus: toHumanReadable(item.paymentStatus),
        salesName: item.salesName,
        customerPhone: !isEmpty(item.customer.phone)
          ? item.customer.phone
          : null,
        salesTeamName: item.salesTeamName,
        companyAddress: getDisplayedAddress(item.customer),
      };
    });
  }, [data]);

  return [columns, rows];
};
