import {
  Button,
  Flex,
  Input,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  SimpleGrid,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import noop from "lodash/noop";
import { useCallback, useMemo } from "react";

import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal.tsx";
import Dropzone from "../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../components/Icons/Calendar.tsx";
import { yesNoOptions } from "../../../constants.ts";
import {
  PaymentMethod,
  UpdateCollateralInput,
} from "../../../graphql/generated.ts";
import {
  Collateral,
  collateralStatusOptions,
} from "../../../types/transactionHistory/collateral.ts";
import { booleanToString, stringToBoolean } from "../../../utils/boolean.ts";
import { parseStringToNumber, priceFormatter } from "../../../utils/number.ts";

interface Props {
  paymentMethod: PaymentMethod;
  value: Collateral;
  opened?: boolean;
  onClose: () => void;
  onSubmit: (value: UpdateCollateralInput) => void;
}

const CollateralModal = ({
  paymentMethod,
  value,
  opened = false,
  onClose,
  onSubmit,
}: Props) => {
  const [
    confirmationModalOpened,
    { open: openConfirmationModal, close: closeConfirmationModal },
  ] = useDisclosure(false);

  const getValidateRules = () => {
    const basicRules = {
      collateralNumber: isNotEmpty("Collateral Number is required"),
      accountHolderName: isNotEmpty("Account Holder Name is required"),
      bankName: isNotEmpty("Bank Name is required"),
      bankAccountNo: isNotEmpty("Bank Account Number is required"),
      amount: isNotEmpty("Amount is required"),
      receiveDate: isNotEmpty("Receive Date is required"),
      dueDate: isNotEmpty("Due Date is required"),
      status: isNotEmpty("Status is required"),
      collateralFile: isNotEmpty("Collateral File is required"),
    };
    if (paymentMethod === PaymentMethod.Skbdn) {
      return {
        ...basicRules,
        skbdnCreditNo: isNotEmpty("SKBDN Credit Number is required"),
        placeOfReceipt: isNotEmpty(
          "Place of Taking in Charge/Dispatch From …/Place of Receipt is required"
        ),
        placeOfDelivery: isNotEmpty(
          "Place of Final Destination/For Transportation To .../Place Of Delivery is required"
        ),
        applicantDetails: isNotEmpty(
          "Rincian Nama dan Alamat Applicant is required"
        ),
      };
    }
    return {
      ...basicRules,
      collateralBuyerNumber: isNotEmpty("Collateral Buyer Number is required"),
      giroAccountNoMatched: isNotEmpty(
        "GIRO Account Number Matched is required"
      ),
    };
  };
  const form = useForm({
    initialValues: value
      ? {
          collateralNumber: value.collateralNumber,
          accountHolderName: value.accountHolderName,
          bankName: value.bankName,
          bankAccountNo: value.bankAccountNo,
          amount: value.amount ? Number(value.amount) : undefined,
          receiveDate: value.receiveDate
            ? new Date(value.receiveDate)
            : undefined,
          dueDate: value.dueDate ? new Date(value.dueDate) : undefined,
          collateralFile: value.collateralFile,
          notes: value.notes,
          collateralBuyerNumber: value.collateralBuyerNumber,
          status: value.status,
          giroAccountNoMatched: booleanToString(
            value.giroAccountNoMatched,
            "formValue"
          ),
          skbdnCreditNo: value.skbdnCreditNo,
          placeOfReceipt: value.placeOfReceipt,
          placeOfDelivery: value.placeOfDelivery,
          applicantDetails: value.applicantDetails,
        }
      : {},
    validate: getValidateRules(),
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }

    onSubmit({
      status: form.values.status,
      giroAccountNoMatched: stringToBoolean(
        form.values.giroAccountNoMatched,
        true
      ),
    } as UpdateCollateralInput);
    handleClose();
  }, [form, handleClose, onSubmit]);

  const additionalInfoForm = useMemo(() => {
    if (paymentMethod === PaymentMethod.Skbdn) {
      return (
        <>
          <TextInput
            required
            disabled
            label="SKBDN Credit Number"
            placeholder="Enter SKBDN Credit Number"
            size="m"
            {...form.getInputProps("skbdnCreditNo")}
          />
          <TextInput
            required
            disabled
            label="Place of Taking in Charge/Dispatch From …/Place of Receipt"
            placeholder="Enter Place of Receipt"
            size="m"
            {...form.getInputProps("placeOfReceipt")}
          />
          <TextInput
            required
            disabled
            label="Place of Final Destination/For Transportation To .../Place Of Delivery"
            placeholder="Enter Place Of Delivery"
            size="m"
            {...form.getInputProps("placeOfDelivery")}
          />
          <TextInput
            required
            disabled
            label="Rincian Nama dan Alamat Applicant"
            placeholder="Enter Rincian Nama dan Alamat Applicant"
            size="m"
            {...form.getInputProps("applicantDetails")}
          />
          <Input.Wrapper required label="Collateral File" size="m" w="100%">
            <Dropzone
              allow={false}
              maxFiles={1}
              maxWidth="unset"
              value={
                form?.values?.collateralFile
                  ? [form.values.collateralFile]
                  : null
              }
              onUploadSuccess={noop}
            />
          </Input.Wrapper>
          <Select
            withinPortal
            dropdownPosition="top"
            data={collateralStatusOptions}
            label="Status"
            placeholder="Select Status"
            size="m"
            {...form.getInputProps("status")}
          />
        </>
      );
    }
    return (
      <>
        <TextInput
          required
          disabled
          label="Collateral Buyer Number"
          placeholder="Enter Collateral Buyer Number"
          size="m"
          {...form.getInputProps("collateralBuyerNumber")}
        />
        <Input.Wrapper required label="Collateral File" size="m" w="100%">
          <Dropzone
            allow={false}
            maxFiles={1}
            maxWidth="unset"
            value={
              form?.values?.collateralFile ? [form.values.collateralFile] : null
            }
            onUploadSuccess={noop}
          />
        </Input.Wrapper>
        <Select
          withinPortal
          dropdownPosition="top"
          data={yesNoOptions}
          label="GIRO Account Number Matched?"
          placeholder="Select GIRO Account Number Matched"
          size="m"
          {...form.getInputProps("giroAccountNoMatched")}
        />
        <Select
          withinPortal
          dropdownPosition="top"
          data={collateralStatusOptions}
          label="Status"
          placeholder="Select Status"
          size="m"
          {...form.getInputProps("status")}
        />
      </>
    );
  }, [form, paymentMethod]);

  if (paymentMethod === PaymentMethod.Transfer) {
    return null;
  }

  return (
    <>
      <Modal
        opened={opened}
        title={value ? "Edit Collateral" : "Add Collateral"}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="xl"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <SimpleGrid
            cols={2}
            breakpoints={[{ maxWidth: "lg", cols: 1 }]}
            spacing={20}
            verticalSpacing={12}
          >
            <Flex direction="column" gap={12}>
              <TextInput
                required
                disabled
                label="Collateral Number"
                placeholder="Enter Collateral Number"
                size="m"
                {...form.getInputProps("collateralNumber")}
              />
              <TextInput
                required
                disabled
                label="Account Holder Name"
                placeholder="Enter Account Holder Name"
                size="m"
                {...form.getInputProps("accountHolderName")}
              />
              <TextInput
                required
                disabled
                label="Bank Name"
                placeholder="Enter Bank Name"
                size="m"
                {...form.getInputProps("bankName")}
              />
              <TextInput
                required
                disabled
                label="Bank Account No"
                placeholder="Enter Bank Account No"
                size="m"
                {...form.getInputProps("bankAccountNo")}
              />
              <NumberInput
                required
                disabled
                hideControls
                label="Amount"
                placeholder="Enter Amount"
                size="m"
                precision={2}
                parser={parseStringToNumber}
                formatter={priceFormatter}
                min={0}
                {...form.getInputProps("amount")}
              />
              <DatePickerInput
                disabled
                label="Receive Date"
                placeholder="Select Receive Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                popoverProps={{ withinPortal: true }}
                clearable
                required
                {...form.getInputProps("receiveDate")}
              />
              <DatePickerInput
                disabled
                label="Due Date"
                placeholder="Select Due Date"
                icon={<SvgCalendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                popoverProps={{ withinPortal: true }}
                clearable
                required
                {...form.getInputProps("dueDate")}
              />
              <Input.Wrapper label="Notes">
                <Textarea
                  disabled
                  minRows={3}
                  maxRows={3}
                  size="md"
                  placeholder="Enter Notes"
                  {...form.getInputProps("notes")}
                />
              </Input.Wrapper>
            </Flex>
            <Flex direction="column" gap={12}>
              {additionalInfoForm}
            </Flex>
          </SimpleGrid>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={openConfirmationModal}
            >
              {value ? "save" : "add"}
            </Button>
          </Flex>
        </Flex>
      </Modal>
      {confirmationModalOpened && (
        <ConfirmationModal
          opened={true}
          message={"Are you sure you want to confirm this collateral document?"}
          onConfirm={handleSubmit}
          onClose={closeConfirmationModal}
        />
      )}
    </>
  );
};

export default CollateralModal;
