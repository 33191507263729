import { Flex, Select } from "@mantine/core";
import { isEmpty } from "lodash";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";

import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import {
  DateTimeFilter,
  InvoiceOrderBy,
  OrderBy,
} from "../../../graphql/generated";
import { useInvoices } from "../../../hooks/api/invoice/useInvoices.ts";
import { nextDueOptions } from "./constants.ts";
import { useStyles } from "./CustomersNextDue.styles.ts";
import { useCustomersNextDueTableData } from "./hooks/useCustomersNextDueTableData.tsx";

import DurationConstructor = moment.unitOfTime.DurationConstructor;
import { DataTableSortStatus } from "mantine-datatable";
import { useNavigate } from "react-router-dom";

import { getInvoiceApiSort } from "../../../components/Invoice/InvoicesTable/utils.ts";
import { SortDirection, TableRow } from "../../../components/Table/types.ts";
import { AppRoute } from "../../../constants.ts";
import { Invoice } from "../../../types/invoice/invoice.ts";
import { getPagesCount } from "../../../utils/pagination.ts";

interface CustomersNextDueProps {
  salesTeamName: string[] | null;
}

const CustomersNextDue = ({ salesTeamName }: CustomersNextDueProps) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const [orderBy, setOrderBy] = useState<InvoiceOrderBy>({
    invoiceDueDate: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);

  const [dueDate, setDueDate] = useState<string | null>();

  const dueDateFilter = useMemo((): DateTimeFilter | null => {
    const today = moment.utc().startOf("day");

    if (!dueDate) {
      return { gte: today.toDate() };
    }

    const [amount, unit] = dueDate.split("-");
    const date = moment
      .utc()
      .startOf("day")
      .add(amount, unit as DurationConstructor);
    return { lte: date.toDate(), gte: today.toDate() };
  }, [dueDate]);

  const filter = isEmpty(salesTeamName)
    ? { invoiceDueDate: dueDateFilter }
    : { invoiceDueDate: dueDateFilter, salesTeamName: { in: salesTeamName } };

  const [{ data: invoicesData, fetching }] = useInvoices({
    filter,
    orderBy,
    page,
  });
  const invoices = invoicesData?.invoices?.data;
  const pageCount = getPagesCount(invoicesData?.invoices?.total);

  const [columns, rows] = useCustomersNextDueTableData({
    data: invoices,
  });

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getInvoiceApiSort(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleInvoiceClick = (record: TableRow) => {
    if (!invoices) return;
    const invoice = invoices.find((item: Invoice) => item.id === record.id);
    if (!invoice) return;
    navigate(`${AppRoute.ActivityManagement}/${invoice.customer.id}`);
  };

  return (
    <Flex direction="column" gap={20}>
      <SectionTitle>List of Customers Next Due</SectionTitle>
      <Flex maw="584px">
        <Select
          label="Due in the next"
          placeholder="Select Value"
          data={nextDueOptions}
          value={dueDate}
          className={classes.nextDueFilter}
          size="m"
          clearable
          onChange={setDueDate}
        />
      </Flex>
      <Table
        loading={fetching}
        minWidth="100%"
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "invoiceDueDate",
          direction: SortDirection.desc,
        }}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onSortChange={handleSortChange}
        onRowClick={handleInvoiceClick}
      />
    </Flex>
  );
};

export default CustomersNextDue;
