import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import Preloader from "../components/Preloader/Preloader.tsx";
import { AppRoute } from "../constants.ts";
import { useCurrentUserContext } from "./CurrentUserProvider.tsx";

interface Props {
  children: ReactElement;
}

export const AuthGuard = ({ children }: Props) => {
  const { fetching, error } = useCurrentUserContext();

  const navigate = useNavigate();

  if (fetching) {
    return <Preloader loading={true} />;
  }

  if (error) {
    navigate(AppRoute.Login);
  }

  return children;
};
