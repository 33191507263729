import { Button, Flex, MediaQuery, SimpleGrid, TextInput } from "@mantine/core";
import { isNotEmpty, matches, useForm } from "@mantine/form";
import { useCallback } from "react";

import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import { UpdateCustomerDirectorInfoInput } from "../../../graphql/generated.ts";
import { DirectorInfo } from "../../../types/directorInfo.ts";
import { phoneMatcher, toClikPhoneFormat } from "../../../utils/phone.ts";
import { useStyles } from "./CompanyInfo.styles.ts";

interface Props {
  value: DirectorInfo | null | undefined;
  onCancel: () => void;
  onSubmit: (value: UpdateCustomerDirectorInfoInput) => void;
}

const DirectorInfoForm = ({ value, onCancel, onSubmit }: Props) => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      directorName: value?.directorName,
      directorTitle: value?.directorTitle,
      directorNik: value?.directorNik,
      directorPhone: value?.directorPhone,
      directorEmail: value?.directorEmail,
    },
    validate: {
      directorName: matches(
        /^[A-Za-z\s]+$/,
        "Director Name must contain only letters"
      ),
      directorTitle: isNotEmpty("Director Title is required"),
      directorNik: isNotEmpty("Director Nik is required"),
      directorEmail: isNotEmpty("Director Email is required"),
      directorPhone: phoneMatcher,
    },
    validateInputOnBlur: true,
  });

  const handleCancel = useCallback(() => {
    form.reset();
    onCancel();
  }, [form, onCancel]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid() || !form.values.directorPhone) {
      return;
    }
    onSubmit({
      ...form.values,
      directorPhone: toClikPhoneFormat(form.values.directorPhone),
    } as UpdateCustomerDirectorInfoInput);
    handleCancel();
  }, [form, handleCancel, onSubmit]);

  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Flex gap={20}>
          <Button
            variant="outlineGolden"
            type="reset"
            uppercase
            onClick={handleCancel}
          >
            cancel
          </Button>
          <Button
            variant="filledGolden"
            size="m"
            type="submit"
            uppercase
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Flex>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Flex gap={20} className={classes.buttonsWrapper}>
          <Button
            variant="outlineGolden"
            type="reset"
            uppercase
            w="100%"
            onClick={handleCancel}
          >
            cancel
          </Button>
          <Button
            variant="filledGolden"
            size="m"
            type="submit"
            uppercase
            w="100%"
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Flex>
      </MediaQuery>
      <SimpleGrid
        cols={3}
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        spacing={20}
        verticalSpacing={16}
      >
        <Flex direction="column" gap={12}>
          <SectionTitle flex={0} isSmall>
            Director In Charge Info
          </SectionTitle>
          <TextInput
            required
            label="Enter Director Name"
            placeholder="Director Name"
            size="m"
            type="text"
            {...form.getInputProps("directorName")}
          />
          <TextInput
            required
            label="Director Title"
            placeholder="Enter Director Title"
            size="m"
            {...form.getInputProps("directorTitle")}
          />
          <TextInput
            required
            label="Director NIK"
            placeholder="Enter Director NIK"
            size="m"
            {...form.getInputProps("directorNik")}
          />
          <TextInput
            required
            label="Director Phone No"
            placeholder="Enter Director Phone No"
            size="m"
            {...form.getInputProps("directorPhone")}
          />
          <TextInput
            required
            label="Director Email"
            placeholder="Enter Director Email"
            size="m"
            {...form.getInputProps("directorEmail")}
          />
        </Flex>
      </SimpleGrid>
    </>
  );
};

export default DirectorInfoForm;
