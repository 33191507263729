import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import {
  TableData,
  TableRow,
} from "../../../../../../components/Table/types.ts";
import { ContractTypeFilterOptions } from "../../../../../../graphql/generated.ts";
import { ClikNotGrantedContract } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../../utils/string.ts";
import ClikResultContractLinkedSubjects from "../../ClikResultContractLinkedSubjects.tsx";

interface Props {
  data: ClikNotGrantedContract[] | null | undefined;
  type: ContractTypeFilterOptions;
}

export function useCRNotGrantedContracts({ data, type }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "referenceNo",
      title: "No.",
      width: "100px",
    },
    {
      accessor: "cbContractCode",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "cbContractCode",
          }}
        >
          CB Contract Code
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerContractNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "providerContractNumber",
          }}
        >
          Provider Contract Number
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "contractType",
          }}
        >
          Contract Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractPhase",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "contractPhase",
          }}
        >
          Contract Phase
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "role",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "role",
          }}
        >
          Role
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "providerType",
          }}
        >
          Provider Type
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerCode",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "providerCode",
          }}
        >
          Provider
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractRequestDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "contractRequestDate",
          }}
        >
          Contract Request Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "lastUpdateDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "lastUpdateDate",
          }}
        >
          Last Update Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "applicationAmount",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "applicationAmount",
          }}
        >
          Application Amount
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
      hidden: type === ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "dueDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "dueDate",
          }}
        >
          Due Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
      hidden: type === ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "originalAgreementNumber",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "originalAgreementNumber",
          }}
        >
          Original Agreement Number
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
      hidden: type === ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "originalAgreementDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "originalAgreementDate",
          }}
        >
          Original Agreement Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
      hidden: type === ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "linkedSubject",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "linkedSubjects",
          }}
        >
          Linked Subjects List
        </DatabaseMetadataPopover>
      ),
      sortable: false,
      width: "350px",
      hidden: type !== ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "note",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikNotGrantedContract",
            fieldName: "note",
          }}
        >
          Note
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "200px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item, index) => {
      return {
        id: item.id,
        referenceNo: index + 1,
        cbContractCode: item.cbContractCode,
        providerContractNumber: item.providerContractNumber,
        contractType: toHumanReadable(item.contractType),
        contractPhase: item.contractPhase,
        role: item.role,
        providerType: item.providerType,
        providerCode: item.providerCode,
        contractRequestDate:
          item.contractRequestDate && toLocalDate(item.contractRequestDate),
        lastUpdateDate: item.lastUpdateDate && toLocalDate(item.lastUpdateDate),
        applicationAmount: toCurrencyPrice(item.applicationAmount),
        dueDate: item.dueDate && toLocalDate(item.dueDate),
        originalAgreementNumber: item.originalAgreementNumber,
        originalAgreementDate:
          item.originalAgreementDate && toLocalDate(item.originalAgreementDate),
        linkedSubject: (
          <ClikResultContractLinkedSubjects data={item.linkedSubjects} />
        ),
        note: item.note,
      };
    });
  }, [data]);

  return [columns, rows];
}
