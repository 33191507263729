import { Button, Flex, Tooltip } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import React, { useCallback, useMemo } from "react";

import { DatabaseMetadataPopover } from "../../../components/DatabaseMetadataPopover/DatabaseMetadataPopover.tsx";
import { TableData, TableRow } from "../../../components/Table/types.ts";
import { ClikDataStatus, RolePermission } from "../../../graphql/generated.ts";
import { useCurrentUserContext } from "../../../providers/CurrentUserProvider.tsx";
import { ClikData } from "../../../types/clikData.ts";
import { toLocalDate } from "../../../utils/date.ts";
import { toHumanReadable } from "../../../utils/string.ts";
import { hasPermission } from "../../../utils/user.ts";

interface Props {
  data: ClikData[] | null | undefined;
  onMeRequest: (id: number) => void;
}

export function useClikDataTable({ data, onMeRequest }: Props): TableData {
  const { user: currentUser } = useCurrentUserContext();

  const hasSendMERequestAccess = hasPermission(
    currentUser,
    RolePermission.CanSendClikDataMeRequest
  );

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "naeDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikData",
            fieldName: "naeDate",
          }}
        >
          NAE Request Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "210px",
    },
    {
      accessor: "enquiryType",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikData",
            fieldName: "enquiryType",
          }}
        >
          Type of Enquiry
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "170px",
    },
    {
      accessor: "category",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "LimitRequest",
            fieldName: "category",
          }}
        >
          Category
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: 160,
    },
    {
      accessor: "name",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikData",
            fieldName: "name",
          }}
        >
          Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "320px",
    },
    {
      accessor: "companyName",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "Customer",
            fieldName: "name",
          }}
        >
          Company Name
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "320px",
    },
    {
      accessor: "status",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikData",
            fieldName: "status",
          }}
        >
          Status
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "120px",
    },
    {
      accessor: "meDate",
      title: (
        <DatabaseMetadataPopover
          databaseMetadata={{
            tableName: "ClikData",
            fieldName: "meDate",
          }}
        >
          Last ME Date
        </DatabaseMetadataPopover>
      ),
      sortable: true,
      width: "150px",
    },
    {
      accessor: "meRequest",
      title: "ME Request",
    },
  ];

  const handleMERequest = useCallback(
    (event: React.MouseEvent<HTMLElement>, id: number) => {
      //  stop event propagation (won't trigger parent's onClick)
      event.stopPropagation();
      hasSendMERequestAccess && onMeRequest(id);
    },
    [hasSendMERequestAccess, onMeRequest]
  );

  const getMERequestButtonTooltipText = useCallback(
    (item: ClikData) => {
      if (!hasSendMERequestAccess) {
        return "You don't have permission to perform this action.";
      }
      if (item.status === ClikDataStatus.Pending) {
        return "Getting Clik result takes some time. Please try refreshing the page in a few minutes.";
      }
      if (item.status === ClikDataStatus.Inactive) {
        return "ME Clik data can't be requested for a record in status Inactive.";
      }
      return null;
    },
    [hasSendMERequestAccess]
  );

  const renderMeRequestButton = useCallback(
    (item: ClikData) => {
      if (
        !hasSendMERequestAccess ||
        item.status === ClikDataStatus.Pending ||
        item.status === ClikDataStatus.Inactive
      ) {
        const tooltipText = getMERequestButtonTooltipText(item);
        return (
          <Tooltip
            label={tooltipText}
            multiline
            transitionProps={{ duration: 200 }}
            withinPortal
            zIndex={1000}
          >
            <Flex>
              <Button variant="filledBlue" size="s" uppercase disabled>
                ME Request
              </Button>
            </Flex>
          </Tooltip>
        );
      }
      return (
        <Button
          variant="filledBlue"
          size="s"
          uppercase
          onClick={(e) => handleMERequest(e, item.id)}
        >
          ME Request
        </Button>
      );
    },
    [getMERequestButtonTooltipText, handleMERequest, hasSendMERequestAccess]
  );

  const rows = useMemo(() => {
    return data?.map((item) => ({
      id: item.id,
      naeDate: item.naeDate && toLocalDate(item.naeDate),
      enquiryType: item.enquiryType && toHumanReadable(item.enquiryType),
      category: toHumanReadable(item.limitRequest.category),
      name: item.name,
      companyName: item.limitRequest.company?.name,
      status: toHumanReadable(item.status),
      meDate: item.meDate && toLocalDate(item.meDate),
      meRequest: renderMeRequestButton(item),
    }));
  }, [data, renderMeRequestButton]);

  return [columns, rows];
}
