import { SelectItem } from "@mantine/core/lib/Select/types";

import {
  CollateralStatus,
  GetCollateralQuery,
} from "../../graphql/generated.ts";
import { toHumanReadable } from "../../utils/string.ts";

export type Collateral = GetCollateralQuery["collateral"];

export const collateralStatusOptions: SelectItem[] = Object.values(
  CollateralStatus
).map((value) => ({
  value,
  label: toHumanReadable(value),
}));
